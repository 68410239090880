const CouponTypologies = Object.freeze({
    RECALL: "RECALL",
    APP: "APP",
    SCONTO: "SCONTO",
    DECREMENTO: "DECREMENTO",
    OMAGGIO_BIBITE: "OMAGGIO BIBITE",
    OMAGGIO_PIZZE: "OMAGGIO PIZZE"
})

export default CouponTypologies
