import React, { useEffect, useState, useRef } from 'react'

import Services from '../services.js'


const Tasks = (props) => {

    const [tasks, setTasks] = useState([])
    const [task, setTask] = useState("")
    const [currentIndex, setCurrentIndex] = useState(null)
    const [currentTask, setCurrentTask] = useState("")
    const [newTask, setNewTask] = useState("")
    const [employees, setEmployees] = useState([])
    const [show, setShow] = useState(false)
    const [body, setBody] = useState("")

    const onChangeTask = (e) => {
        const task = e.target.value
        setTask(task)
    }
    const onChangeNewTask = (e) => {
        const newTask = e.target.value
        setNewTask(newTask)
    }

    async function retrieveTasks() {
        if (props.user?.username) {
            return Services.getSettings(props.user.username)
                .then((response) => {
                    setTasks(response.data.settings?.taskList ?? [])
                    setEmployees(response.data.settings?.employees ?? [])
                })
                .catch((error) => {
                    console.log(error)
                    setTasks([])
                    setEmployees([])
                })
        } else {
            setTasks([])
            setEmployees([])
        }
    }

    useEffect(() => {
        retrieveTasks().then(() => {
            console.log("compiti recuperati")
        }, (err) => console.log(err))
    }, [])

    const insertTasks = () => {
        if(props.user?.username && task) {
            Services.insertTasks(props.user.username, task)
                .then((response) => {
                    setTask("")
                    retrieveTasks().then(() => {
                        console.log("compiti recuperati")
                    }, (err) => console.log(err))
                    setShow(false)
                })
                .catch((error) => {
                    setBody(error.response ? error.response.data.error : "Non è stato possibile creare il record.")
                    setShow(true)
                })
        } else {
            setBody("Inserire una descrizione.")
            setShow(true)
        }
    }
    const updateTasks = (task, newTask = null) => {
        if(props.user?.username) {
            Services.updateTasks(props.user.username, task, newTask)
                .then((response) => {
                    setNewTask("")
                    retrieveTasks().then(() => {
                        console.log("compiti recuperati")
                    }, (err) => console.log(err))
                    setShow(false)
                })
                .catch((error) => {
                    setBody(error.response ? error.response.data.error : "Non è stato possibile aggiornare/eliminare il record.")
                    setShow(true)
                })
        }
    }

    //ASSEGNAZIONE
    const [target, setTarget] = useState(null)
    const saveRef = useRef(null)

    const renderEmployeesList = () => {
        let render = []
        for(let e in employees) {
            render.push(
                <div key={`${employees[e].name}-${tasks[currentIndex]}`} className="row form-group justify-content-start">
                    <div className="col-4"></div>
                    <div className="col-8">
                        <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                name={`${employees[e].name}`}
                                id={`${employees[e].name}`}
                                // calcolo defaultChecked guardando se al dipendente è assegnato il compito
                                defaultChecked={(employees[e]?.tasks && employees[e].tasks.includes(tasks[currentIndex])) ? true : false}
                            ></input>
                            <label className="custom-control-label" htmlFor={`${employees[e].name}`}>{employees[e].name}</label>
                        </div>
                    </div>
                </div>
            )
        }
        return render
    }

    useEffect(() => {
        async function updateTaskInEmployee(nome, task, operation) {
            if (props.user?.username) {
                return Services.updateTaskInEmployee(props.user.username, nome, task, operation)
                    .then((response) => {
                        console.log(nome)
                    })
                    .catch((error) => {
                        console.log(error.response?.data?.error)
                        console.log(nome)
                    })
            }
        }

        if (target && tasks[currentIndex]) {
            const formData = new FormData(target)
            const checked = [...formData.entries()].map((e) => { return e[0] })  // array di [nome, 'on'] mappato in array di nome

            // ciclo sui dipendenti per vedere tutti i cambiamenti (anche 'on' che diventa 'off')
            for (let e in employees) {
                if (checked.includes(employees[e].name) && !(employees[e]?.tasks && employees[e].tasks.includes(tasks[currentIndex]))) {
                    // CASO PUSH: tasks vuoto oppure task not in tasks
                    updateTaskInEmployee(employees[e].name, tasks[currentIndex], "push").then(() => {
                        retrieveTasks().then(() => {
                            console.log("compiti recuperati")
                        }, (err) => console.log(err))
                    }, (err) => console.log(err))
                } else if (!checked.includes(employees[e].name) && (employees[e]?.tasks && employees[e].tasks.includes(tasks[currentIndex]))) {
                    // CASO PULL: tasks non vuoto e task in tasks
                    updateTaskInEmployee(employees[e].name, tasks[currentIndex], "pull").then(() => {
                        retrieveTasks().then(() => {
                            console.log("compiti recuperati")
                        }, (err) => console.log(err))
                    }, (err) => console.log(err))
                }
            }

            setTarget(null)
            setCurrentIndex(null)  // triggero renderEmployeesList
            saveRef.current.click()
        }
    }, [target])
    
    return (
        <div className="container-fluid">

            <div className="card shadow mb-4">

                {/* <!-- Card Header --> */}
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Elenco Compiti</h6>
                </div>

                {/* <!-- Card Body --> */}
                <div className="card-body">
                    {show ? (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>Attenzione!</strong> {body}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShow(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    ) : (
                        <div></div>
                    )}

                    <div className="form-group row">
                        <div className="col-12 input-group">
                            <textarea
                                id="descrizione"
                                placeholder="Inserire la descrizione di un compito"
                                className={(show && !task) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                value={task}
                                onChange={onChangeTask}
                            ></textarea>
                            <div className="input-group-append">
                                <button
                                    type="button"
                                    className="btn btn-success btn-font-sm"
                                    onClick={insertTasks}
                                >
                                    <i className="fa fa-plus"></i>
                                    <span className="ml-2 d-none d-sm-inline">Aggiungi</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table text-dark">
                            <thead>
                                <tr>
                                    <th>Descrizione</th>
                                    <th style={{ width: "20%" }}>Assegnazione</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((t, index) => {
                                    return (
                                        <tr key={`task-${index}`}>
                                            <td style={{ whiteSpace: "pre-line" }}>{t}</td>
                                            <td>
                                                <button
                                                    className="btn btn-primary"
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#viewAssegna"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    taskindex={index}
                                                    onClick={(e) => {
                                                        setCurrentIndex(e.currentTarget.getAttribute('taskindex'))
                                                    }}
                                                >
                                                    Assegna
                                                </button>
                                                &nbsp;
                                                <button
                                                    className="btn btn-warning btn-icon"
                                                    type="button"
                                                    taskindex={index}
                                                    data-toggle="modal"
                                                    data-target="#modalCancellaRecord"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    onClick={(e) => {
                                                        setCurrentIndex(e.currentTarget.getAttribute('taskindex'))
                                                        setCurrentTask(tasks[e.currentTarget.getAttribute('taskindex')])
                                                        setNewTask(tasks[e.currentTarget.getAttribute('taskindex')])
                                                    }}
                                                    title="Modifica"
                                                >
                                                    <i className="fas fa-edit"></i>
                                                </button>
                                                &nbsp;
                                                <button
                                                    className="btn btn-danger btn-icon"
                                                    type="button"
                                                    taskindex={index}
                                                    data-toggle="modal"
                                                    data-target="#modalCancellaRecord"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    onClick={(e) => {
                                                        setCurrentIndex(e.currentTarget.getAttribute('taskindex'))
                                                    }}
                                                    title="Cancella"
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* <!-- Modale per assegnamento compito ai dipendenti --> */}
            <div className="modal fade" id="viewAssegna" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Assegna il compito ai dipendenti</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setCurrentIndex(null)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={(event) => { event.preventDefault(); setTarget(event.currentTarget) }}>
                            <div className="modal-body container-fluid text-dark">
                                <span style={{ whiteSpace: "pre-line" }}><b>Compito:</b> {tasks[currentIndex]}</span><br /><br />
                                {renderEmployeesList()}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    ref={saveRef}
                                    onClick={() => setCurrentIndex(null)}
                                >
                                    Chiudi
                                </button>
                                <button type="submit" className="btn btn-primary">Salva</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* <!-- INIZIO MODAL MODIFICA/CANCELLA RECORD --> */}
            <div className="modal fade" id="modalCancellaRecord" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Modifica / cancella Compito</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {setCurrentIndex(null); setCurrentTask("")}}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            {currentTask ?
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label htmlFor="nuovaDescrizione">Modifica la descrizione del compito:</label>
                                        <textarea
                                            id="nuovaDescrizione"
                                            placeholder="Inserire la descrizione di un compito"
                                            className="form-control form-control-user"
                                            value={newTask}
                                            onChange={onChangeNewTask}
                                        ></textarea>
                                    </div>
                                </div>
                            :
                                <p>Cancellando il compito selezionato eliminerai anche tutte le sue assegnazioni. Sei sicuro di voler procedere?</p>
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {setCurrentIndex(null); setCurrentTask("")}}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => {
                                    if (currentTask) {
                                        updateTasks(tasks[currentIndex], newTask)
                                    } else {
                                        updateTasks(tasks[currentIndex])
                                    }

                                    setCurrentIndex(null)
                                    setCurrentTask("")
                                }}
                            >
                                Conferma
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Tasks
