import React, { useEffect, useState } from 'react'
import usePlacesAutocomplete, { getGeocode, getZipCode } from 'use-places-autocomplete'

import Services from '../services.js'


const SettingsGeneral = (props) => {

    const [settings, setSettings] = useState(null)
    const [general, setGeneral] = useState(null)
    const [address, setAddress] = useState("")
    const [zipCode, setZipCode] = useState(null)
    const [hidePassword, setHidePassword] = useState(true)
    const [showSaveButton, setShowSaveButton] = useState(false)

    const onChangeAddress = (e) => {
        const address = e.target.value
        setAddress(address)
        setValue(address)
    }

    const handleHidePassword = () => {
        setHidePassword(!hidePassword)
    }

    async function retrieveSettings() {
        setShowSaveButton(false)
        if (props.user?.username) {
            return Services.getSettings(props.user.username)
                .then((response) => {
                    // versione salvata (contiene dati calcolati automaticamente come le coordinate e il cap)
                    setSettings(response.data.settings)

                    // versione per la form (stringa vuota viene correttamente messa, se non c'è general viene invece messo il valore di default)
                    setGeneral({
                        name: response.data.settings?.general?.name ?? "",
                        address: response.data.settings?.general?.address ?? "",
                        ragSoc: response.data.settings?.general?.ragSoc ?? "",
                        fiscalCode: response.data.settings?.general?.fiscalCode ?? "",
                        phone: response.data.settings?.general?.phone ?? "",
                        businessPhone: response.data.settings?.general?.businessPhone ?? "",
                        googleLink: response.data.settings?.general?.googleLink ?? "",
                        taLink: response.data.settings?.general?.taLink ?? "",
                        androidLink: response.data.settings?.general?.androidLink ?? "",
                        iosLink: response.data.settings?.general?.iosLink ?? "",
                        dest: response.data.settings?.general?.dest ?? "intermittenti@pec.lavoro.gov.it",
                        advisor: response.data.settings?.general?.advisor ?? "",
                        mail: response.data.settings?.general?.mail ?? "",
                        password: response.data.settings?.general?.password ?? ""
                    })

                    setShowSaveButton(true)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    useEffect(() => {
        retrieveSettings().then(() => {
            console.log("impostazioni recuperate")
        }, (err) => console.log(err))
    }, [])

    useEffect(() => {
        async function calculateZipCode() {
            let cap = ''
            if (address) {
                const result = await getGeocode({ address: address })
                // By default we use the "long_name" value from API response, you can tell the utility to use "short_name"
                // by setting the second parameter to "true"
                .then((results) => getZipCode(results[0], false))
                .then((zipCode) => {
                    return zipCode
                })
                .catch((error) => {
                    console.log(error)
                    return ''
                })
                return result
            }
            return cap
        }

        if (address && address !== settings?.general?.address) {
            calculateZipCode().then((zipCode) => {
                console.log("ZIP Code: ", zipCode)
                setZipCode(zipCode)
            }).catch(error => console.error(error))
        }
    }, [address])

    // gestione evento onSubmit
    const [target, setTarget] = useState(null)

    useEffect(() => {
        if (target) {
            const formData = new FormData(target)
            const general = Object.fromEntries(formData)
            setTarget(null)
            if (!general.businessPhone) {
                general.businessPhone = ""  // finché il campo è disabilitato
            }
            updateGeneral(general)
        }
    }, [target])

    const updateGeneral = (general) => {
        if (props.user?.username) {
            // i dati calcolati automaticamente devono essere mantenuti
            if (settings?.general?.coordinates) {
                general.coordinates = settings.general.coordinates
            }
            if (zipCode !== null && settings?.general?.cap !== zipCode) {  // ho cambiato il cap ed è diverso da quello salvato
                general.cap = zipCode
            } else {  // non ho cambiato il cap, non è diverso da quello salvato, non è ancora stato salvato
                general.cap = settings?.general?.cap ?? ""
            }

            Services.updateGeneral(props.user.username, general)
                    .then((response) => {
                        retrieveSettings().then(() => {
                            window.location.reload()
                        }, (err) => console.log(err))
                    })
                    .catch((error) => {
                        console.log(error)
                        alert("Non è stato possibile aggiornare!")
                    })
        } else {
            alert("Non è stato possibile aggiornare!")
        }
    }

    //AUTOCOMPLETE GOOGLE MAPS
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
            types: ["address"]
        },
        debounce: 500
    })
    const handleSelect = (description) => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false)
    }

    return (

        <div className="container-fluid">

            <div className="card shadow mb-4">
                <form onSubmit={(event) => { event.preventDefault(); setTarget(event.currentTarget) }}>
                    <div className="card-header py-3">
                        <div className="row">
                            <div className="col-6 d-flex justify-content-left">
                                <h6 className="m-0 font-weight-bold text-primary">Impostazioni generali</h6>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <button className="btn btn-primary btn-user btn-sm" type="submit" disabled={!showSaveButton}>
                                    Salva
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body text-dark">
                        <div className="form-group row">
                            {/* <!-- NOME --> */}
                            <label htmlFor="impostazioni-generali-nome" className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Nome struttura</label>
                            <div className="col-xl-3 col-md-3 col-6 mb-3 mb-md-0">
                                <input
                                    className="form-control"
                                    id="impostazioni-generali-nome"
                                    name='name'
                                    type="text"
                                    placeholder="Inserire un nome"
                                    defaultValue={general?.name}
                                ></input>
                            </div>
                            {/* <!-- INDIRIZZO --> */}
                            <label htmlFor="google-place-autocomplete" className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Indirizzo struttura</label>
                            <div className="col-xl-3 col-md-3 col-6">
                                <input
                                    id="google-place-autocomplete"
                                    name='address'
                                    type="text"
                                    className="form-control form-control-user"
                                    placeholder="Inserire un indirizzo"
                                    defaultValue={general?.address}
                                    onChange={onChangeAddress}
                                    disabled={!ready}
                                    list="listaluoghi"
                                    onSelect={handleSelect}
                                ></input>
                                {status === 'OK' && (
                                    <datalist id="listaluoghi">
                                        {data.map(({ place_id, description }) => {
                                            return (
                                                <option key={place_id} value={description}>{description}</option>
                                            )
                                        })}
                                    </datalist>
                                )}
                            </div>
                        </div>

                        <div className="form-group row">
                            {/* <!-- RAGIONE SOCIALE --> */}
                            <label htmlFor="impostazioni-generali-ragione-sociale" className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Ragione sociale</label>
                            <div className="col-xl-3 col-md-3 col-6 mb-3 mb-md-0">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="impostazioni-generali-ragione-sociale"
                                    name='ragSoc'
                                    placeholder="Inserire la ragione sociale"
                                    defaultValue={general?.ragSoc}
                                ></input>
                            </div>
                            {/* <!-- CODICE FISCALE --> */}
                            <label htmlFor="impostazioni-generali-cf" className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Codice Fiscale</label>
                            <div className="col-xl-3 col-md-3 col-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="impostazioni-generali-cf"
                                    name='fiscalCode'
                                    placeholder="Inserire il codice fiscale"
                                    defaultValue={general?.fiscalCode}
                                ></input>
                            </div>
                        </div>

                        <div className="form-group row">
                            {/* <!-- TELEFONO --> */}
                            <label htmlFor="impostazioni-generali-telefono-struttura" className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Telefono struttura</label>
                            <div className="col-xl-3 col-md-3 col-6 mb-3 mb-md-0">
                                <input
                                    type="text"
                                    id="impostazioni-generali-telefono-struttura"
                                    name='phone'
                                    className="form-control"
                                    placeholder="Inserire un numero di telefono"
                                    defaultValue={general?.phone}
                                ></input>
                            </div>
                            {/* <!-- NUMERO WHATSAPP --> */}
                            <label htmlFor="impostazioni-generali-telefono-whatsapp" className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Numero WhatsApp</label>
                            <div className="col-xl-3 col-md-3 col-6">
                                <input
                                    type="text"
                                    id="impostazioni-generali-telefono-whatsapp"
                                    name='businessPhone'
                                    className="form-control"
                                    placeholder="Inserire un numero di telefono"
                                    defaultValue={general?.businessPhone}
                                    disabled
                                ></input>
                            </div>
                        </div>

                        <hr />

                        {/* <!-- SITI WEB (link recensioni google e ta, link app android e ios) --> */}
                        <div className="form-group row">
                            <label htmlFor="recensioni-google" className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Link recensioni Google</label>
                            <div className="col-xl-3 col-md-3 col-6 mb-3 mb-md-0">
                                <input
                                    type="text"
                                    id="recensioni-google"
                                    className="form-control"
                                    placeholder="Inserire link recensioni Google"
                                    name='googleLink'
                                    defaultValue={general?.googleLink ?? ""}
                                ></input>
                            </div>
                            <label htmlFor="recensioni-ta" className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Link recensioni TripAdvisor</label>
                            <div className="col-xl-3 col-md-3 col-6">
                                <input
                                    type="text"
                                    id="recensioni-ta"
                                    className="form-control"
                                    placeholder="Inserire link recensioni TripAdvisor"
                                    name='taLink'
                                    defaultValue={general?.taLink ?? ""}
                                ></input>
                            </div>                        
                        </div>

                        <div className="form-group row">
                            <label htmlFor="app-android" className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Link app Android</label>
                            <div className="col-xl-3 col-md-3 col-6 mb-3 mb-md-0">
                                <input
                                    type="text"
                                    id="app-android"
                                    className="form-control"
                                    placeholder="Inserire link app Android"
                                    name='androidLink'
                                    defaultValue={general?.androidLink ?? ""}
                                ></input>
                            </div>
                            <label htmlFor="app-ios" className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Link app iOS</label>
                            <div className="col-xl-3 col-md-3 col-6">
                                <input
                                    type="text"
                                    id="app-ios"
                                    className="form-control"
                                    placeholder="Inserire link app iOS"
                                    name='iosLink'
                                    defaultValue={general?.iosLink ?? ""}
                                ></input>
                            </div>                        
                        </div>

                        <hr />

                        <div className="form-group row">
                            {/* <!-- EMAIL PRIMO DESTINATARIO --> */}
                            <label htmlFor="impostazioni-generali-dest" className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Email destinatario</label>
                            <div className="col-xl-3 col-md-3 col-6 mb-3 mb-md-0">
                                <input
                                    type="text"
                                    id="impostazioni-generali-dest"
                                    name='dest'
                                    className="form-control"
                                    placeholder="Email comunicazione intermittenti"
                                    defaultValue={general?.dest}
                                ></input>
                            </div>
                            {/* <!-- COPIA AL CONSULENTE --> */}
                            <label htmlFor="impostazioni-generali-advisor" className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Copia al consulente</label>
                            <div className="col-xl-3 col-md-3 col-6">
                                <input
                                    type="text"
                                    id="impostazioni-generali-advisor"
                                    name='advisor'
                                    className="form-control"
                                    placeholder="Email consulente"
                                    defaultValue={general?.advisor}
                                ></input>
                            </div>
                        </div>

                        <div className="form-group row">
                            {/* <!-- EMAIL --> */}
                            <label htmlFor="impostazioni-generali-mail" className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Email mittente</label>
                            <div className="col-xl-3 col-md-3 col-6 mb-3 mb-md-0">
                                <input
                                    type="text"
                                    id="impostazioni-generali-mail"
                                    name='mail'
                                    className="form-control"
                                    placeholder="Email"
                                    defaultValue={general?.mail}
                                ></input>
                            </div>
                            {/* <!-- PASSWORD --> */}
                            <label htmlFor="impostazioni-generali-password" className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Password mittente</label>
                            <div className="col-xl-3 col-md-3 col-6 input-group">
                                <input
                                    id="impostazioni-generali-password"
                                    name='password'
                                    type={hidePassword ? "password" : "text"}
                                    className="form-control form-control-user border-right-0"
                                    placeholder="Password"
                                    defaultValue={general?.password}
                                ></input>
                                <span className="input-group-append">
                                    <div className="input-group-text bg-transparent">
                                        <i
                                            className={hidePassword ? "fas fa-eye" : "fas fa-eye-slash"}
                                            style={{ cursor: "pointer" }}
                                            onClick={handleHidePassword}
                                        ></i>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SettingsGeneral
