import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import Services from '../services'


const Register = (props) => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [adminPassword, setAdminPassword] = useState("")

    const [show, setShow] = useState(false)
    const [body, setBody] = useState("")
    const [hidePassword, setHidePassword] = useState(true)
    const [hideAdminPassword, setHideAdminPassword] = useState(true)

    const editFieldRef = useRef(null)

    useEffect(() => {
        editFieldRef.current.focus()
    }, [])

    const onChangeUsername = (e) => {
        const username = e.target.value
        setUsername(username)
    }

    const onChangePassword = (e) => {
        const password = e.target.value
        setPassword(password)
    }

    const onChangeAdminPassword = (e) => {
        const password = e.target.value
        setAdminPassword(password)
    }

    const handleClose = () => {
        setUsername("")
        setPassword("")
        setAdminPassword("")
        setShow(false)
        editFieldRef.current.focus()
    }

    const handleShow = (b) => {
        setBody(b)
        setShow(true)
        editFieldRef.current.focus()
    }

    const handleHidePassword = () => {
        setHidePassword(!hidePassword)
    }

    const handleHideAdminPassword = () => {
        setHideAdminPassword(!hideAdminPassword)
    }

    /* const sendOTP = () => {
        if(username) {
            // invia mail e ritorna il codice temporaneo (che varrà per la vita di questo componente)
        } else {
            handleShow("Inserire una email valida.")
        }
    }

    const verifyMail = () => {
        if(false) {  // codice temporaneo generato/inviato uguale a quello inserito
            signup()
        } else {
            handleShow("Il codice temporaneo inserito non corrisponde.")
        }
    } */

    const signup = () => {
        if(username && password && adminPassword) {
            Services.signup(username, password, adminPassword)
                .then((response) => {
                    props.login(response.data.user, response.data.access_token, response.data.refresh_token)
                    props.history.push('/')
                    window.location.href = process.env.REACT_APP_STRIPE_PAYMENT_LINK
                })
                .catch((error) => {
                    handleShow(error.response?.data?.error)
                })
        } else {
            handleShow("Compilare tutti i campi.")
        }
    }

    return(
        <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="p-5">
                            <div className="text-center">
                                <h1 className="h4 text-gray-900 mb-4">Crea un nuovo account!</h1>
                            </div>
                            {show ? (
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    <strong>Attenzione!</strong> {body}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={handleClose}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            ) : (
                                <div></div>
                            )}
                            <form>
                                <div className="form-group">
                                    <label htmlFor="username">Inserisci la tua email (sarà il tuo username):</label>
                                    <input
                                        id="username"
                                        type="email"
                                        className="form-control form-control-user"
                                        placeholder="Username"
                                        value={username}
                                        onChange={onChangeUsername}
                                        ref={editFieldRef}
                                        aria-required="true"
                                    ></input>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Inserisci una password per te e i tuoi dipendenti:</label>
                                    <div className="input-group">
                                        <input
                                            id="password"
                                            type={hidePassword ? "password" : "text"}
                                            className="form-control form-control-user border-right-0"
                                            placeholder="Password"
                                            value={password}
                                            onChange={onChangePassword}
                                            aria-required="true"
                                        ></input>
                                        <span className="input-group-append">
                                            <div className="input-group-text bg-transparent">
                                                <i
                                                    className={hidePassword ? "fas fa-eye" : "fas fa-eye-slash"}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={handleHidePassword}
                                                ></i>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="adminPassword">Inserisci una password per la tua area personale:</label>
                                    <div className="input-group">
                                        <input
                                            id="adminPassword"
                                            type={hideAdminPassword ? "password" : "text"}
                                            className="form-control form-control-user border-right-0"
                                            placeholder="Password area personale"
                                            value={adminPassword}
                                            onChange={onChangeAdminPassword}
                                            aria-required="true"
                                            onKeyDown={(event) => {
                                                if(event.key === "Enter") {
                                                    signup()
                                                }
                                            }}
                                        ></input>
                                        <span className="input-group-append">
                                            <div className="input-group-text bg-transparent">
                                                <i
                                                    className={hideAdminPassword ? "fas fa-eye" : "fas fa-eye-slash"}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={handleHideAdminPassword}
                                                ></i>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <button className="btn btn-primary btn-user btn-block" type="button" onClick={signup}>
                                    Crea account
                                </button>
                            </form>
                            <hr/>
                            <div className="text-center">
                                <a className="small" href="mailto:deliverypro.cloud@gmail.com?subject=Richiesta%20recupero%20password&body=inserire%20tutte%20le%20informazioni%20utili%20alla%20vostra%20identificazione">
                                    Password dimenticata?
                                </a>
                            </div>
                            <div className="text-center">
                                <Link to={"/"} className="small">
                                    <span>Hai già un account? Accedi!</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register
