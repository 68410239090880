import React, { useEffect, useState } from 'react'

import Services from '../services.js'


const SettingsPrinters = (props) => {

    const [printers, setPrinters] = useState([])
    const [connectionStatus, setConnectionStatus] = useState("")

    async function retrievePrinters() {
        if (props.user?.username) {
            return Services.getSettings(props.user.username)
                .then((response) => {
                    setPrinters(response.data.settings?.printers ?? [])
                })
                .catch((error) => {
                    console.log(error)
                    setPrinters([])
                })
        } else {
            setPrinters([])
        }
    }

    useEffect(() => {
        retrievePrinters().then(() => {
            console.log("stampanti recuperate")
        }, (err) => console.log(err))
    }, [])

    useEffect(() => {
        const st = JSON.parse(props.status)
        if (st && "ppp" in st) {
            setConnectionStatus(st["ppp"])
        } else {
            setConnectionStatus("")
        }
    }, [props.status])

    const [printerName, setPrinterName] = useState("")
    const [printerTypology, setPrinterTypology] = useState("COMANDE")
    const [printerIPAddress, setPrinterIPAddress] = useState("")
    const [printerPort, setPrinterPort] = useState("")
    const [currentPrinter, setCurrentPrinter] = useState(null)
    const [show, setShow] = useState(false)
    const [body, setBody] = useState("")

    const onChangePrinterName = (e) => {
        const nome = e.target.value
        setPrinterName(nome)
    }
    const onChangePrinterTypology = (e) => {
        const typology = e.target.value
        setPrinterTypology(typology)
    }
    const onChangePrinterIPAddress = (e) => {
        const ip = e.target.value
        setPrinterIPAddress(ip)
    }
    const onChangePrinterPort = (e) => {
        const port = e.target.value
        setPrinterPort(port)
    }

    useEffect(() => {
        if (currentPrinter) {
            setPrinterName(currentPrinter?.name ?? "")
            setPrinterTypology(currentPrinter?.typology ?? "COMANDE")
            setPrinterIPAddress(currentPrinter?.address ?? "")
            setPrinterPort(currentPrinter?.port ?? "")
        } else {
            setPrinterName("")
            setPrinterTypology("COMANDE")
            setPrinterIPAddress("")
            setPrinterPort("")
        }
    }, [currentPrinter])

    const insertPrinters = () => {
        if (props.user?.username && printerName && printerTypology) {
            const printer = {
                typology: printerTypology,
                name: printerName,
                address: printerIPAddress,
                port: printerPort
            }

            Services.insertPrinters(props.user.username, printer)
                .then((response) => {
                    setPrinterName("")
                    setPrinterTypology("COMANDE")
                    setPrinterIPAddress("")
                    setPrinterPort("")
                    retrievePrinters().then(() => window.location.reload(), (err) => console.log(err))
                    setShow(false)
                })
                .catch((error) => {
                    setBody(error.response ? error.response.data.error : "Non è stato possibile creare/aggiornare il record.")
                    setShow(true)
                })
        } else {
            setBody("Riempire i campi obbligatori.")
            setShow(true)
        }
        setCurrentPrinter(null)
    }
    const updatePrinters = () => {
        if (props.user?.username && currentPrinter.name && printerName && printerTypology) {
            const printer = {
                typology: printerTypology,
                name: printerName,
                address: printerIPAddress,
                port: printerPort
            }

            Services.updatePrinters(props.user.username, currentPrinter.name, printer)
                    .then((response) => {
                        setPrinterName("")
                        setPrinterTypology("COMANDE")
                        setPrinterIPAddress("")
                        setPrinterPort("")
                        retrievePrinters().then(() => window.location.reload(), (err) => console.log(err))
                        setShow(false)
                    })
                    .catch((error) => {
                        setBody(error.response ? error.response.data.error : "Non è stato possibile creare/aggiornare il record.")
                        setShow(true)
                    })
        } else {
            setBody("Riempire i campi obbligatori.")
            setShow(true)
        }
        setCurrentPrinter(null)
    }
    const deletePrinters = () => {
        if (props.user?.username && currentPrinter.name) {
            Services.deletePrinters(props.user.username, currentPrinter.name)
                    .then((response) => {
                        retrievePrinters().then(() => window.location.reload(), (err) => console.log(err))
                        setShow(false)
                    })
                    .catch((error) => {
                        setBody(error.response ? error.response.data.error : "Non è stato possibile eliminare il record.")
                        setShow(true)
                    })
        }
        setCurrentPrinter(null)
    }

    /* const renderStatus = () => {
        let render = []
        const status = JSON.parse(props.status)
        for(let key in status) {
            render.push(
                <tr key={key}>
                    <td>{key}</td>
                    <td>{status[key]}</td>
                </tr>
            )
        }
        return render
    } */

    return (
       <div className="container-fluid">

            <div className="row">
                <div className="col-12 mb-4">
                    <div className={`card ${connectionStatus.includes("connesso") ? "border-left-success" : "border-left-danger"} shadow h-100 py-2`}>
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className={`text-xs font-weight-bold ${connectionStatus.includes("connesso") ? "text-success" : "text-danger"} text-uppercase mb-1`}>
                                        Stato della connessione al server di stampa
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        {connectionStatus}
                                        &nbsp;
                                        <a
                                            className="btn btn-link rounded-circle btn-sm"
                                            type="button"
                                            href="http://localhost:8000"
                                            target="_blank"
                                            rel="noreferrer"
                                            title="Apri PPP"
                                        >
                                            <i className="fa fa-external-link-alt" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <a
                                        className="btn btn-link rounded-circle btn-sm"
                                        type="button"
                                        href="https://github.com/delivery-pro/delivery-pro-wiki/wiki/Guida-a-DeliveryPro-Server-Stampa"
                                        target="_blank"
                                        rel="noreferrer"
                                        title="Guida a PPP"
                                    >
                                        <i className="fas fa-question-circle"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Stampanti connesse</h6>
                </div>

                <div className="card-body text-dark">
                    <div className="table-responsive">
                        <table className="table text-dark">
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Stato</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderStatus()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Elenco Stampanti</h6>
                </div>

                <div className="card-body text-dark">
                    {show ? (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>Attenzione!</strong> {body}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShow(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    ) : (
                        <div></div>
                    )}

                    <div className="form-group row">
                        <div className="col-12">
                            <button
                                type="button"
                                className="btn btn-success btn-font-sm"
                                data-toggle="modal"
                                data-target="#gestione-stampa-modal"
                                data-backdrop="static"  //non si può uscire cliccando fuori
                                data-keyboard="false"   //non si può uscire premendo Esc
                            >
                                <i className="fa fa-plus"></i>
                                <span className="ml-2">Aggiungi</span>
                            </button>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table text-dark" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Tipologia</th>
                                    <th>Indirizzo IP</th>
                                    <th>Porta</th>
                                    <th style={{ width: "10%" }}>Azioni</th>
                                </tr>
                            </thead>
                            <tbody>
                                {printers.map((p, index) => {
                                    return (
                                        <tr key={`printer${index}`}>
                                            <td>{p.name}</td>
                                            <td>{p.typology}</td>
                                            <td>{p.address}</td>
                                            <td>{p.port}</td>
                                            <td>
                                                <button
                                                    className="btn btn-warning btn-icon"
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#gestione-stampa-modal"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    printerindex={index}
                                                    onClick={(e) => setCurrentPrinter(printers[e.currentTarget.getAttribute('printerindex')])}
                                                    title="Modifica"
                                                >
                                                    <i className="fas fa-edit"></i>
                                                </button>
                                                &nbsp;
                                                <button
                                                    className="btn btn-danger btn-icon"
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#gestione-stampa-cancella-modal"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    printerindex={index}
                                                    onClick={(e) => setCurrentPrinter(printers[e.currentTarget.getAttribute('printerindex')])}
                                                    title="Cancella"
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}             
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* <!-- INIZIO MODAL GESTIONE STAMPANTE --> */}
            <div className="modal fade" id="gestione-stampa-modal" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Inserisci / aggiorna Stampante</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setCurrentPrinter(null)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div className="form-group row">
                                <label htmlFor="typology" className="col-form-label col-4 d-flex justify-content-end"><b>Tipologia</b></label>
                                <div className="col-8">
                                <select
                                    id="typology"
                                    className={(show && !printerTypology) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                    value={printerTypology}
                                    onChange={onChangePrinterTypology}
                                    disabled  // TODO in futuro
                                >
                                    <option value="">Seleziona la tipologia</option>
                                    <option value="COMANDE">COMANDE</option>
                                    <option value="FISCALE">FISCALE</option>
                                </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="nome" className="col-form-label col-4 d-flex justify-content-end"><b>Nome</b></label>
                                <div className="col-8">
                                    <input
                                        type="text"
                                        id="nome"
                                        placeholder="Inserire un nome"
                                        className={(show && !printerName) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                        value={printerName}
                                        onChange={onChangePrinterName}
                                    ></input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="address" className="col-form-label col-4 d-flex justify-content-end"><b>Indirizzo IP</b></label>
                                <div className="col-8">
                                    <input
                                        type="text"
                                        id="address"
                                        placeholder="Inserire l'indirizzo IP"
                                        className="form-control"
                                        value={printerIPAddress}
                                        onChange={onChangePrinterIPAddress}
                                    ></input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="port" className="col-form-label col-4 d-flex justify-content-end"><b>Porta</b></label>
                                <div className="col-8">
                                    <input
                                        type="text"
                                        id="port"
                                        placeholder="Inserire la porta"
                                        className="form-control"
                                        value={printerPort}
                                        onChange={onChangePrinterPort}
                                    ></input>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => setCurrentPrinter(null)}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => {
                                    if (currentPrinter) {
                                        updatePrinters()
                                    } else {
                                        insertPrinters()
                                    }
                                }}
                            >
                                Conferma
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- FINE MODAL AGGIUNGI STAMPANTE --> */}

            {/* <!-- INIZIO MODAL CONFERMA CANCELLAZIONE --> */}
            <div className="modal fade" id="gestione-stampa-cancella-modal" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Cancella Stampante</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setCurrentPrinter(null)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <p>Sei sicuro di voler cancellare la Stampante selezionata?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => setCurrentPrinter(null)}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => deletePrinters()}
                            >
                                Cancella
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- FINE MODAL CONFERMA CANCELLAZIONE --> */}

        </div>
    )
}

export default SettingsPrinters
