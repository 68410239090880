import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table'

//react-date-range
import { DateRangePicker, DateRange } from 'react-date-range'
import { subDays } from 'date-fns'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import SummaryContainer from './SummaryContainer'

import Services from '../services.js'
import OrderConstants from '../enums/OrderConstants'

const moment = require('moment')
const sortDates = (a, b) => {
    if (a < b) {
        return 1
    }
    if (a > b) {
        return -1
    }
    return 0
}


const Clients = (props) => {
    const [clients, setClients] = useState([])

    const [dateSelection, setDateSelection] = useState([{
        startDate: subDays(new Date(), 7),
        endDate: subDays(new Date(), 1),
        key: 'selection'
    }])

    const onChangeDateSelection = (item) => {
        const selection = item.selection
        setDateSelection([selection])
    }

    useEffect(() => {
        const start = moment(dateSelection[0].startDate)
        const end = moment(dateSelection[0].endDate).add(1, 'd')

        if (props.user.username && start && end) {
            Services.getRegistryByDateRange(props.user.username, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
                    .then((response) => {
                        // console.log(response.data.clients)
                        setClients(response.data.clients)
                    })
                    .catch((error) => {
                        console.log(error)
                        setClients([])
                        setShow(true)
                        setBody("Data non valida.")
                    })
        } else {
            setClients([])
        }
    }, [dateSelection])

    // TABELLA CON PAGINAZIONE E RICERCA
    const columns = useMemo(
        () => [
            {
                Header: 'Telefono',
                accessor: 'phone',
                Cell: props => <span style={{ whiteSpace: "pre-line" }}>{props.value}</span>
            },
            {
                Header: 'Indirizzo',
                accessor: 'address',
                Cell: props => <span style={{ whiteSpace: "pre-line" }}>{props.value}</span>
            },
            {
                Header: 'Nomi',
                accessor: 'name',
                sortType: (rowA, rowB, id, desc) => {
                    if (rowA.values[id].toString().toUpperCase() < rowB.values[id].toString().toUpperCase())
                        return -1
                    if (rowA.values[id].toString().toUpperCase() > rowB.values[id].toString().toUpperCase())
                        return 1
                    return 0
                },
                Cell: props => <span style={{ whiteSpace: "pre-line" }}>{props.value}</span>
            },
            {
                Header: 'Ordini nel periodo',
                accessor: 'count',
                Cell: props => <span style={{ whiteSpace: "pre-line" }}>{props.value}</span>
            },
            {
                Header: 'Spesa nel periodo',
                accessor: 'total',
                Cell: props => <span style={{ whiteSpace: "pre-line" }}>€ {props.value?.toFixed(2)}</span>
            },
            {
                Header: 'Data ultimo ordine',
                accessor: 'dates',
                sortType: (rowA, rowB, id, desc) => {
                    if (rowA.values[id].sort(sortDates)[0] < rowB.values[id].sort(sortDates)[0])
                        return -1
                    if (rowA.values[id].sort(sortDates)[0] > rowB.values[id].sort(sortDates)[0])
                        return 1
                    return 0
                },
                Cell: props => <span style={{ whiteSpace: "pre-line" }}>{(typeof props.value === "object" && props.value.length > 0) ? moment(props.value.sort(sortDates)[0]).format("DD/MM/YY") : "N/D"}</span>
            }
        ],
        []
    )

    const memoizedClients = useMemo(() => clients, [clients])

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 1000)  // millisecondi dopo cui parte la ricerca

        return (
            <span>
                Cerca:{' '}
                <input
                    className="form-control"
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} risultati...`}
                    // autoFocus
                />
            </span>
        )
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns: columns,
            data: memoizedClients,
            initialState: { pageIndex: 0, pageSize: 50 }
        },
        //useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    )

    // DETTAGLI ANAGRAFICA
    const [phone, setPhone] = useState("")
    // const [nome, setNome] = useState("")
    // const [mail, setMail] = useState("")
    const [address, setAddress] = useState("")
    const [notes, setNotes] = useState("")
    const [show, setShow] = useState(false)
    const [body, setBody] = useState("")

    const [currentClient, setCurrentClient] = useState(null)
    const [ccorders, setCcorders] = useState([])
    const closeRef = useRef(null)

    useEffect(() => {
        if (currentClient) {
            setPhone(currentClient.phone ?? "")
            // setNome(currentClient.name ?? "")
            // setMail(currentClient.mail ?? "")
            setAddress(currentClient.address ?? "")
            if (typeof currentClient.notes === "object" && currentClient.notes) {
                const filtered = currentClient.notes.filter((item) => item && item !== "\n")
                setNotes(filtered)
            } else {
                setNotes(currentClient.notes ?? "")
            }

            if (props.user.username) {
                // recupero tutti gli ordini del cliente
                Services.getClientOrders(props.user.username, currentClient.phone, currentClient.address)
                        .then((response) => {
                            // console.log(response.data.orders)
                            setCcorders(response.data.orders)
                        })
                        .catch((error) => {
                            console.log(error)
                            setCcorders([])
                        })
            } else {
                setCcorders([])
            }
        } else {
            setPhone("")
            // setNome("")
            // setMail("")
            setAddress("")
            setNotes("")
            setCcorders([])
        }
    }, [currentClient])

    // TOOLTIPS
    useEffect(() => {
        window.jQuery('[data-toggle="tooltip"]').tooltip()
    }, [])

    return (
        <div className="container-fluid">
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col-6 d-flex justify-content-left">
                            <h6 className="m-0 font-weight-bold text-primary">Anagrafiche Clienti</h6>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            <div className="btn-group dropdown d-none d-xl-inline">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i className="fas fa-calendar-week mr-2"></i>
                                    <span className="d-none d-xl-inline">Seleziona intervallo di tempo</span>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" onClick={(e) => e.stopPropagation()}>
                                    <DateRangePicker
                                        onChange={onChangeDateSelection}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        ranges={dateSelection}
                                        direction="horizontal"
                                    />
                                </div>
                            </div>
                            <div className="btn-group dropdown d-xl-none">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i className="fas fa-calendar-week mr-2"></i>
                                    <span className="d-none d-md-inline">Seleziona intervallo di tempo</span>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right" onClick={(e) => e.stopPropagation()}>
                                    <DateRange
                                        onChange={onChangeDateSelection}
                                        moveRangeOnFirstSelection={false}
                                        ranges={dateSelection}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body text-dark">
                    {show ? (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>Attenzione!</strong> {body}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShow(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    ) : (
                        <div></div>
                    )}

                    <div className="table-responsive text-dark">
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                        &nbsp;
                        <table className="table text-dark" {...getTableProps()}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                {/* sort direction indicator */}
                                                <span>
                                                    &nbsp;
                                                    {column.isSorted
                                                        ? column.isSortedDesc
                                                            ? <i className="fas fa-sort-down"></i>
                                                            : <i className="fas fa-sort-up"></i>
                                                        : <i className="fas fa-sort"></i>}
                                                </span>
                                            </th>
                                        ))}

                                        {/* AZIONI */}
                                        <th style={{ width: "10%" }}>Dettaglio</th>
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            })}

                                            {/* AZIONI */}
                                            <td>
                                                <button
                                                    type="button"
                                                    className="btn btn-icon"
                                                    data-toggle="modal"
                                                    data-target="#dettaglioAnagrafica"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    onClick={() => setCurrentClient(memoizedClients[row.index])}
                                                    title="Tutti gli ordini"
                                                >
                                                    <i className="fas fa-list-ul"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {/* Pagination */}
                        <ul className="pagination">
                            <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                <button className="page-link">Inizio</button>
                            </li>
                            <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <button className="page-link">{'<'}</button>
                            </li>
                            <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
                                <button className="page-link">{'>'}</button>
                            </li>
                            <li className="page-item" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                <button className="page-link">Fine</button>
                            </li>
                            <li>
                                <span className="page-link">
                                    Pagina{' '}
                                    <strong>
                                        {pageIndex + 1} di {pageOptions.length}
                                    </strong>{' '}
                                </span>
                            </li>
                            <li>
                                <span className="page-link">
                                    <input
                                        className="form-control"
                                        type="number"
                                        value={pageIndex ? pageIndex + 1 : 1}
                                        min={1}
                                        max={pageOptions.length}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                            gotoPage(page)
                                        }}
                                        style={{ width: '100px', height: '20px' }}
                                    />
                                </span>
                            </li>
                            &nbsp;
                            <select
                                className="form-control"
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                                style={{ width: '125px', height: '38px' }}
                            >
                                {[5, 10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Mostra: {pageSize}
                                    </option>
                                ))}
                            </select>
                        </ul>
                    </div>
                </div>
            </div>

            {/* <!-- Modale per dettaglio anagrafica --> */}
            <div className="modal fade" id="dettaglioAnagrafica" tabIndex="-1" role="dialog" aria-labelledby="dettaglioAnagraficaLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="dettaglioAnagraficaLabel">Dettagli Anagrafica</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setCurrentClient(null)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div className="row">
                                <div className="col-12">
                                    <p><b>Telefono: </b>{phone}</p>
                                    <p><b>Indirizzo: </b>{address}</p>
                                    {typeof notes === "object" && notes.length > 1 ? (
                                        <>
                                            <b>Note: </b>
                                            <ul>
                                                {notes.map((item, index) => {
                                                    if (item && item !== "\n") {
                                                        return (
                                                            <li key={`notes-${index}`}>{item}</li>
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })}
                                            </ul>
                                        </>
                                    ) : (
                                        <p><b>Note: </b>{notes}</p>
                                    )}
                                </div>
                            </div>
                            <hr />
                            <h5><b><i>Tutti gli ordini ({ccorders?.length ?? 0})</i></b></h5>
                            <div className="table-responsive">
                                <table className="table text-dark" id="dataTable" width="100%">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Clicca su un ID per espandere l'ordine"
                                                    style={{ textDecoration: "underline 1px dotted" }}
                                                >
                                                    ID
                                                </span>
                                            </th>
                                            <th>Tipologia</th>
                                            <th>Data</th>
                                            <th>Orario</th>
                                            <th>Nome</th>
                                            <th>Importo</th>
                                            <th>Pagamento</th>
                                        </tr>
                                    </thead>
                                    {ccorders.map((o, index) => {
                                        return (
                                            <React.Fragment key={`tabella-ordini-${index}`}>
                                                <tbody>
                                                    <tr>
                                                        <td><a href={`#collapse-${o.seq_value}`} data-toggle="collapse">#{o.seq_value}</a></td>
                                                        <td>{o.mode}</td>
                                                        <td>{moment(o.hour.split('T')[0]).format("DD/MM/YY")}</td>
                                                        <td>{o.hour.split('T')[1].substr(0, 5)}</td>
                                                        <td>{o.reg.name} {o.platform !== OrderConstants.NO_PLATFORM ? <span className="badge rounded-pill bg-info text-white">{o.platform}</span> : ""}</td>
                                                        <td>€ {o.total.toFixed(2)}</td>
                                                        <td>{o.payment}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody className="collapse" id={`collapse-${o.seq_value}`}>
                                                    <tr>
                                                        <td className="table-active"></td>
                                                        <td className="table-active" colSpan={6}><SummaryContainer currentOrder={o} user={props.user}></SummaryContainer></td>
                                                    </tr>
                                                </tbody>
                                            </React.Fragment>
                                        )
                                    })}
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setCurrentClient(null)} ref={closeRef}>Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Clients
