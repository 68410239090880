import React, { useEffect, useState, useRef } from 'react'

import Services from '../services.js'
import ItemCategories from '../enums/ItemCategories'

// const moment = require('moment')
const days = ['Domenica','Lunedì','Martedì','Mercoledì','Giovedì','Venerdì','Sabato']
const subcategoriesDefault = Object.fromEntries(Object.values(ItemCategories).map((c) => [c, []]))


const SettingsDelivery = (props) => {

    const [consegna, setConsegna] = useState("")
    const [azzeramento, setAzzeramento] = useState("")
    const [minimo, setMinimo] = useState("")
    const [distanza, setDistanza] = useState("")
    const [startHour, setStartHour] = useState("01:00")
    const [endHour, setEndHour] = useState("23:55")
    const [step, setStep] = useState("15")
    const [platforms, setPlatforms] = useState([])
    const [currentPlatform, setCurrentPlatform] = useState("")
    const [subcategories, setSubcategories] = useState(subcategoriesDefault)
    const [abilitaApp, setAbilitaApp] = useState(false)

    const [showSaveButton, setShowSaveButton] = useState(false)

    const onChangeConsegna = (e) => {
        const consegna = e.target.value
        setConsegna(consegna)
    }
    const onChangeAzzeramento = (e) => {
        const azzeramento = e.target.value
        setAzzeramento(azzeramento)
    }
    const onChangeMinimmo = (e) => {
        const minimo = e.target.value
        setMinimo(minimo)
    }
    const onChangeDistanza = (e) => {
        const distanza = e.target.value
        setDistanza(distanza)
    }
    const onChangeStartHour = (e) => {
        const start = e.target.value
        setStartHour(start)
    }
    const onChangeEndHour = (e) => {
        const end = e.target.value
        setEndHour(end)
    }
    const onChangeStep = (e) => {
        const step = e.target.value
        setStep(step)
    }
    const onChangeCurrentPlatform = (e) => {
        const nome = e.target.value
        setCurrentPlatform(nome)
    }

    async function retrieveSettings() {
        setShowSaveButton(false)
        if (props.user?.username) {
            return Services.getSettings(props.user.username)
                .then((response) => {
                    // console.log(response.data.settings?.delivery)
                    const delivery = response.data.settings?.delivery
                    if (delivery) {
                        setConsegna(delivery.cons >= 0 ? String(delivery.cons) : "")
                        setAzzeramento(delivery.azz >= 0 ? String(delivery.azz) : "")
                        setMinimo(delivery.minimo >= 0 ? String(delivery.minimo) : "")
                        setDistanza(delivery.dist >= 0 ? String(delivery.dist) : "")
                        setStartHour(delivery.startHour ?? "01:00")
                        setEndHour(delivery.endHour ?? "23:55")
                        setStep(delivery.step ? String(delivery.step) : "15")
                        setPlatforms(delivery.platforms ?? [])
                        setSubcategories(delivery.subcategories ?? subcategoriesDefault)
                        setAbilitaApp(delivery.app ?? false)
                    }
                    setShowSaveButton(true)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    useEffect(() => {
        retrieveSettings().then(() => {
            console.log("impostazioni recuperate")
        }, (err) => console.log(err))
    }, [])

    // DRIVERS PER FASCIA ORARIA
    const [drivers, setDrivers] = useState([])
    const [driversOriginal, setDriversOriginal] = useState([])
    const [show, setShow] = useState(false)
    // const [hiddenDay, setHiddenDay] = useState(moment().day())
    // const [day, setDay] = useState(moment().day())
    const [currentDay, setCurrentDay] = useState({})
    const [target, setTarget] = useState(null)
    const saveRef = useRef(null)

    useEffect(() => {
        async function retrieveDrivers() {
            if (props.user?.username) {
                await Services.getSettings(props.user.username)
                    .then((response) => {
                        console.log(response.data.settings.slots)
                        setDrivers(response.data.settings.slots)
                        setDriversOriginal(response.data.settings.slots)
                    })
                    .catch((error) => {
                        console.log(error)
                        setDrivers([])
                        setDriversOriginal([])
                    })
            } else {
                setDrivers([])
                setDriversOriginal([])
            }
            setShow(true)
        }

        retrieveDrivers().then(() => {
            console.log("fasce orarie recuperate")
        }, (err) => console.log(err))
    }, [])

    // quando cambia un orario genero un nuovo array drivers basato sulle nuove fasce
    useEffect(() => {
        let hours = []
        if (startHour && endHour) {
            const sh = [Number(startHour.split(':')[0]), Number(startHour.split(':')[1])]
            const eh = [Number(endHour.split(':')[0]), Number(endHour.split(':')[1])]

            for (let h = sh[0]; h <= eh[0]; h++) {  // per ogni ora compresa tra inizio e fine
                let startMinute = 0
                let endMinute = 60
                if (h === sh[0]) {
                    startMinute = sh[1]
                } else if (h === eh[0]) {
                    endMinute = eh[1] + 1
                }
                for (let m = startMinute; m < endMinute; m += Number(step)) {  // ogni step minuti
                    const hour = String(h).padStart(2, '0')
                    const minute = String(m).padStart(2, '0')
                    hours.push(`${hour}:${minute}`)
                }
            }

            let newDrivers = []
            for (let d in days) {
                let slots = {}
                for (let h in hours) {
                    // prendo il valore originale di drivers[d][hours[h]] se esiste
                    if (driversOriginal && driversOriginal[d] && driversOriginal[d][hours[h]]) {
                        slots[hours[h]] = driversOriginal[d][hours[h]]
                    } else {
                        slots[hours[h]] = 0
                    }
                }
                newDrivers.push(slots)
            }
            setDrivers(newDrivers)
        }
    }, [startHour, endHour, step, driversOriginal])

    /* const handleIncrease = () => {
        const newDay = moment().day(day+1)
        setHiddenDay(newDay.day())
    }
    const handleDecrease = () => {
        const newDay = moment().day(day-1)
        setHiddenDay(newDay.day())
    } */

    // gestione evento onSubmit
    useEffect(() => {
        if (target) {
            const formData = new FormData(target)
            for (let [key, value] of formData.entries()) {
                if (key !== 'day') {
                    const i = Number(key.split('-')[0])
                    const j = key.split('-')[1]
                    drivers[i][j] = Number(value)
                }
            }
            console.log(drivers)

            setTarget(null)

            /* // triggero renderHours cambiando il giorno
            setDay(hiddenDay)
            setCurrentDay(drivers[hiddenDay])

            // se hiddenDay è uguale a day chiudo la modale (tramite un ref sul pulsante chiudi) e salvo nel db
            if (hiddenDay === day) {
                saveRef.current.click()
                updateDrivers()
                updateDelivery()
            } */

            saveRef.current.click()
            updateDrivers()
            updateDelivery()
        }
    }, [target])

    // rendering: viene effettuato ogni volta che l'attributo key (ovvero il giorno) cambia
    const renderHours = () => {
        let render = []
        for (let h in currentDay[0]) {  // ore
            render.push(
                <tr key={h}>
                    <th>{h}</th>
                    {days.map((d, i) => {  // giorni
                        return (
                            <td key={`${d}-${h}`}>
                                <input
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    defaultValue={currentDay[i][h]}
                                    name={`${i}-${h}`}
                                    id={`${i}-${h}`}
                                ></input>
                            </td>
                        )
                    })}
                </tr>
            )
        }
        return render
    }

    // SALVATAGGIO DELLE MODIFICHE
    const updateDelivery = () => {
        if (props.user?.username) {
            const delivery = {
                cons: Number(consegna.replace(',', '.')),
                azz: Number(azzeramento.replace(',', '.')),
                minimo: Number(minimo.replace(',', '.')),
                dist: Number(distanza.replace(',', '.')),
                startHour: startHour,
                endHour: endHour,
                step: Number(step),
                platforms: platforms,
                subcategories: subcategories,
                app: abilitaApp
            }

            Services.updateDelivery(props.user.username, delivery)
                    .then((response) => {
                        retrieveSettings().then(() => {
                            window.location.reload()
                        }, (err) => console.log(err))
                    })
                    .catch((error) => {
                        console.log(error)
                        alert("Non è stato possibile aggiornare!")
                    })
        }
    }

    const updateDrivers = () => {
        if (props.user?.username && drivers) {
            Services.updateDrivers(props.user.username, drivers)
                .then((response) => {
                    console.log("fasce orarie aggiornate")
                })
                .catch((error) => {
                    console.log(error)
                    alert("Non è stato possibile aggiornare le fasce orarie!")
                })
        }
    }

    return (
        <div className="container-fluid">

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col-6 d-flex justify-content-left">
                            <h6 className="m-0 font-weight-bold text-primary">Impostazioni delivery</h6>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            <button className="btn btn-primary btn-user btn-sm" type="button" onClick={() => { updateDrivers() ; updateDelivery() }} disabled={!showSaveButton}>
                                Salva
                            </button>
                        </div>
                    </div>
                </div>

                <div className="card-body text-dark">

                    <div className="row form-group">
                        <label className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end" htmlFor="consegna">Spese di consegna</label>
                        <div className="col-xl-3 col-md-3 col-6 mb-3 mb-md-0">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">€</span>
                                </div>
                                <input
                                    className="form-control"
                                    id="consegna"
                                    type="text"
                                    placeholder="Inserire un importo"
                                    value={consegna}
                                    onChange={onChangeConsegna}
                                ></input>
                            </div>
                        </div>

                        <label className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end" htmlFor="azzeramento">Azzeramento spese di consegna</label>
                        <div className="col-xl-3 col-md-3 col-6">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">€</span>
                                </div>
                                <input
                                    className="form-control"
                                    id="azzeramento"
                                    type="text"
                                    placeholder="Inserire un importo"
                                    value={azzeramento}
                                    onChange={onChangeAzzeramento}
                                ></input>
                            </div>
                            <span className="form-text text-muted">Indicare 0 per nessun limite.</span>
                        </div>
                    </div>

                    <div className="row form-group">
                        <label className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end" htmlFor="minimo">Ordine minimo</label>
                        <div className="col-xl-3 col-md-3 col-6 mb-3 mb-md-0">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">€</span>
                                </div>
                                <input
                                    className="form-control"
                                    id="minimo"
                                    type="text"
                                    placeholder="Inserire un importo"
                                    value={minimo}
                                    onChange={onChangeMinimmo}
                                ></input>
                            </div>
                            <span className="form-text text-muted">Indicare 0 per nessun limite.</span>
                        </div>

                        <label className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end" htmlFor="distanza">Distanza massima di consegna</label>
                        <div className="col-xl-3 col-md-3 col-6">
                            <input
                                className="form-control"
                                id="distanza"
                                type="text"
                                placeholder="Inserire la distanza in km"
                                value={distanza}
                                onChange={onChangeDistanza}
                            ></input>
                            <span className="form-text text-muted">Indicare 0 per nessun limite.</span>
                        </div>
                    </div>

                    <div className="row form-group">
                        <label className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end" htmlFor="inizio">Orario di inizio consegne</label>
                        <div className="col-xl-3 col-md-3 col-6 mb-3 mb-md-0">
                            <input
                                className="form-control"
                                id="inizio"
                                type="time"
                                value={startHour}
                                onChange={onChangeStartHour}
                            ></input>
                        </div>

                        <label className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end" htmlFor="fine">Orario di fine consegne</label>
                        <div className="col-xl-3 col-md-3 col-6">
                            <input
                                className="form-control"
                                id="fine"
                                type="time"
                                value={endHour}
                                onChange={onChangeEndHour}
                            ></input>
                        </div>
                    </div>

                    <div className="row form-group">
                        <label className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end" htmlFor="passo">Minuti per slot orario</label>
                        <div className="col-xl-3 col-md-3 col-6 mb-3 mb-md-0">
                            <input
                                className="form-control"
                                id="passo"
                                type="number"
                                min={5}
                                step={5}
                                value={step}
                                onChange={onChangeStep}
                            ></input>
                        </div>

                        <span className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Disponibilità per fascia oraria</span>
                        <div className="col-xl-3 col-md-3 col-6">
                            <button
                                className="btn btn-primary"
                                type="button"
                                // onClick={() => { setHiddenDay(moment().day()); setDay(moment().day()); setCurrentDay(drivers[moment().day()]) }}
                                onClick={() => { setCurrentDay(drivers) }}
                                disabled={!show}
                                data-toggle="modal"
                                data-target="#setDisponibilitaOrari"
                                data-backdrop="static"  //non si può uscire cliccando fuori
                                data-keyboard="false"   //non si può uscire premendo Esc
                            >
                                Configura
                            </button>
                        </div>
                    </div>

                    <div className="row form-group">
                        <span className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Piattaforme</span>
                        <div className="col-xl-3 col-md-3 col-6 mb-3 mb-md-0">
                            <button
                                className="btn btn-primary"
                                type="button"
                                data-toggle="modal"
                                data-target="#setPlatforms"
                                data-backdrop="static"  //non si può uscire cliccando fuori
                                data-keyboard="false"   //non si può uscire premendo Esc
                            >
                                Configura
                            </button>
                        </div>

                        <span className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end">Sotto-categorie</span>
                        <div className="col-xl-3 col-md-3 col-6">
                            <button
                                className="btn btn-primary"
                                type="button"
                                data-toggle="modal"
                                data-target="#setSubcategories"
                                data-backdrop="static"  //non si può uscire cliccando fuori
                                data-keyboard="false"   //non si può uscire premendo Esc
                            >
                                Configura
                            </button>
                        </div>
                    </div>

                    <div className="row form-group">
                        <label className="col-form-label col-xl-3 col-md-3 col-6 d-flex justify-content-end" htmlFor="app">Abilita ordini dalla App</label>
                        <div className="col-xl-3 col-md-3 col-6 d-flex align-items-center">
                            <div className="custom-control custom-switch">
                                <input
                                    id="app"
                                    type="checkbox"
                                    className="custom-control-input"
                                    checked={abilitaApp}
                                    onChange={(e) => setAbilitaApp(e.target.checked)}
                                ></input>
                                <label className="custom-control-label" htmlFor="app"></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modale per configurazione disponibilità orarie --> */}
            <div className="modal fade" id="setDisponibilitaOrari" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Imposta numero Drivers per fascia oraria</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {/* Form HTML per interpretare i dati come chiave-valore */}
                        {/* https://stackoverflow.com/questions/23427384/get-form-data-in-reactjs */}
                        <form onSubmit={(event) => { event.preventDefault(); setTarget(event.currentTarget) }}>
                            <div className="modal-body container-fluid text-dark">
                                {/* <div className="row form-group justify-content-center">
                                    <div className="col-sm-6 input-group">
                                        <div className="input-group-prepend">
                                            <button type="submit" className="btn btn-primary btn-circle" onClick={handleDecrease}>
                                                <i className="fas fa-arrow-left"></i>
                                            </button>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control form-control-user"
                                            value={days[day]}
                                            disabled
                                        ></input>
                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-primary btn-circle" onClick={handleIncrease}>
                                                <i className="fas fa-arrow-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="table-responsive">
                                    <table className="table table-striped text-dark">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                {days.map((d, i) => {
                                                    return (
                                                        <th key={d}>{d}</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderHours()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={saveRef}>Chiudi</button>
                                <button type="submit" className="btn btn-primary">Salva</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* <!-- Modale per configurazione piattaforme --> */}
            <div className="modal fade" id="setPlatforms" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Configura le Piattaforme</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div className="table-responsive">
                                <table className="table text-dark" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>Nome Piattaforma</th>
                                            <th style={{ width: "10%" }}>Azioni</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {platforms.map((p, index) => {
                                            return (
                                                <tr key={`platform${index}`}>
                                                    <td>{p}</td>
                                                    <td>
                                                        <button
                                                            className="btn btn-danger btn-icon"
                                                            type="button"
                                                            platformindex={index}
                                                            onClick={(e) => {
                                                                let pl = [...platforms]
                                                                pl.splice(e.currentTarget.getAttribute('platformindex'), 1)
                                                                setPlatforms(pl)
                                                            }}
                                                            title="Cancella"
                                                        >
                                                            <i className="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div className="form-group row">
                                <div className="col-12 input-group">
                                    <input
                                        type="text"
                                        id="nome"
                                        placeholder="Inserire un nome"
                                        className="form-control"
                                        value={currentPlatform}
                                        onChange={onChangeCurrentPlatform}
                                    ></input>
                                    <div className="input-group-append">
                                        <button
                                            type="button"
                                            className="btn btn-success btn-font-sm"
                                            onClick={() => {
                                                setPlatforms([...platforms, currentPlatform])
                                                setCurrentPlatform("")
                                            }}
                                        >
                                            <i className="fa fa-plus"></i>
                                            <span className="ml-2">Aggiungi</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => { updateDrivers() ; updateDelivery() }}>Salva</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modale per configurazione sotto-categorie --> */}
            <div className="modal fade" id="setSubcategories" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Configura le Sotto-categorie</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            {Object.values(ItemCategories).map((c, i) => {
                                return (
                                    <div key={`categoria${i}`}>
                                        <span><b>Categoria:</b> {c}</span>
                                        <div className="table-responsive">
                                            <table className="table text-dark" style={{ width: "100%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Sotto-categoria</th>
                                                        <th style={{ width: "10%" }}>Azioni</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {subcategories[c]?.map((sc, index) => {
                                                        return (
                                                            <tr key={`sottocategoria${index}`}>
                                                                <td>{sc}</td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-danger btn-icon"
                                                                        type="button"
                                                                        scindex={index}
                                                                        onClick={(e) => {
                                                                            let newSc = JSON.parse(JSON.stringify(subcategories))
                                                                            newSc[c].splice(e.currentTarget.getAttribute('scindex'), 1)
                                                                            setSubcategories(newSc)
                                                                        }}
                                                                        title="Cancella"
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <form id={`form-sottocategoria${i}`} onSubmit={(event) => {
                                            event.preventDefault()
                                            const formData = new FormData(event.currentTarget)
                                            const value = Array.from(formData.entries())[0]
                                            if (value && value[1]) {
                                                let newSc = JSON.parse(JSON.stringify(subcategories))
                                                newSc[c].push(value[1])
                                                setSubcategories(newSc)
                                            }
                                            document.getElementById(`form-sottocategoria${i}`).reset()
                                        }}>
                                            <div className="form-group row">
                                                <div className="col-12 input-group">
                                                    <input
                                                        type="text"
                                                        id={`input-sottocategoria${i}`}
                                                        name={`input-sottocategoria${i}`}
                                                        defaultValue=""
                                                        placeholder="Inserire una nuova sotto-categoria"
                                                        className="form-control"
                                                    ></input>
                                                    <div className="input-group-append">
                                                        <button
                                                            type="submit"
                                                            className="btn btn-success btn-font-sm"
                                                        >
                                                            <i className="fa fa-plus"></i>
                                                            <span className="ml-2">Aggiungi</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => { updateDrivers() ; updateDelivery() }}>Salva</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SettingsDelivery
