import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table'
import Plotly from 'plotly.js-dist-min'

//react-date-range
import { DateRangePicker, DateRange } from 'react-date-range'
import { subDays } from 'date-fns'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import MapContainer from './MapContainer'
import Services from '../services.js'
import OrderConstants from '../enums/OrderConstants'
import Storage from '../useStorage.js'
const moment = require('moment')

const plotlyOptions = { displaylogo: false, responsive: true }
const labelsMap = { count: 'Ordini', avg_total: 'Media prezzo ordine', avg_plates: 'Media numero pizze per ordine',
                    avg_price: 'Media prezzo per pizza', avg_time: 'Media minuti consegna' }
const mesi = {"1": "Gennaio", "2": "Febbraio", "3": "Marzo", "4": "Aprile", "5": "Maggio", "6": "Giugno",
              "7": "Luglio", "8": "Agosto", "9": "Settembre", "10": "Ottobre", "11": "Novembre", "12": "Dicembre"}
const days = ['Domenica','Lunedì','Martedì','Mercoledì','Giovedì','Venerdì','Sabato']


const Dashboard = (props) => {
    const [dateSelection, setDateSelection] = useState([{
        startDate: subDays(new Date(), 7),
        endDate: subDays(new Date(), 1),
        key: 'selection'
    }])

    const onChangeDateSelection = (item) => {
        const selection = item.selection
        setDateSelection([selection])
    }

    useEffect(() => {
        const scoreData = Storage.getDashboardData("scoresData")
        if (scoreData) {
            Plotly.newPlot("scores", scoreData[0], { margin: { t: 25, l: 25, r: 25 }, font: { size: 10 }, annotations: scoreData[1] }, plotlyOptions)
        }

        const platformsData = Storage.getDashboardData("platformsData")
        if (platformsData) {
            Plotly.newPlot("platforms", platformsData, { barmode: 'stack', margin: { t: 30, l: 30, r: 30 }, font: { size: 10 }, legend: { orientation: "h", y: 1.1 } }, plotlyOptions)
        }

        const orderSlots = Storage.getDashboardData("orderSlots")
        if (orderSlots) {
            Plotly.newPlot("orderSlots", orderSlots[0], { barmode: 'stack', margin: { t: 30, l: 30, r: 30 }, font: { size: 10 }, legend: { orientation: "h", y: 1.1 }, annotations: orderSlots[1] }, plotlyOptions)
        }
    }, [])

    // DATI COMPARATI COL PERIODO PRECEDENTE
    const [ordersData, setOrdersData] = useState(Storage.getDashboardData("ordersData") ?? null)
    const [zipsData, setZipsData] = useState(Storage.getDashboardData("zipsData") ?? [])
    const [favoritesData, setFavoritesData] = useState(Storage.getDashboardData("favoritesData") ?? [])
    const [diffs, setDiffs] = useState(Storage.getDashboardData("diffs") ?? null)
    const [appData, setAppData] = useState(Storage.getDashboardData("appData") ?? {})

    useEffect(() => {
        let mounted = true
        async function retrieveOrdersDataCompare() {
            const start = moment(dateSelection[0].startDate)
            const end = moment(dateSelection[0].endDate).add(1, 'd')

            if (props.user?.username && start && end) {
                return Services.getOrdersDataCompare(props.user.username, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
                    .then((response) => {
                        // console.log("dati comparati", response.data)

                        if (mounted) {
                            setOrdersData(response.data.data)
                            setDiffs(response.data.diffs)
                            setAppData(response.data.appData)

                            // dati sul cap
                            let newZipsData = []
                            for (let i in response.data.zips) {
                                if (i === '') {
                                    continue
                                }
                                const v = response.data.zips[i]
                                const d = response.data.zipDiffs[i] ?? [null, null, null]
                                newZipsData.push({
                                    zip: i,
                                    revenue: [v[0], d[0]],
                                    orders: [v[1], d[1]],
                                    mean: [v[2], d[2]]
                                })
                            }
                            setZipsData(newZipsData)

                            // pizze più vendute
                            let sortedFavoritesData = [...response.data.favorites]
                            sortedFavoritesData.sort((a, b) => {
                                // ordino secondo count decrescente
                                if (b.count < a.count) {
                                    return -1
                                }
                                if (b.count > a.count) {
                                    return 1
                                }
                                // ordino secondo name crescente
                                if (a['_id'] < b['_id']) {
                                    return -1
                                }
                                if (a['_id'] > b['_id']) {
                                    return 1
                                }
                                return 0
                            })
                            setFavoritesData(sortedFavoritesData)

                            // salvataggio in memoria
                            Storage.saveDashboardData({
                                "ordersData": response.data.data,
                                "diffs": response.data.diffs,
                                "appData": response.data.appData,
                                "zipsData": newZipsData,
                                "favoritesData": sortedFavoritesData
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }

        retrieveOrdersDataCompare()
        return () => { mounted = false }
    }, [dateSelection])

    // DATI DIPENDENTI DAL PERIODO
    const [revenuesLevel, setRevenuesLevel] = useState('d')
    // const [modalitiesData, setModalitiesData] = useState({ labels: [], datasets: [] })
    const [averagesData, setAveragesData] = useState(Storage.getDashboardData("averagesData") ?? [])
    const [driversData, setDriversData] = useState(Storage.getDashboardData("driversData") ?? {})
    const [meanScore, setMeanScore] = useState(Storage.getDashboardData("meanScore") ?? 0)

    useEffect(() => {
        const revenuesData = Storage.getDashboardData("revenuesData")
        if (revenuesData) {
            Plotly.newPlot("dailyRevenues", revenuesData[revenuesLevel][0], { margin: { t: 25, l: 25, r: 25 }, font: { size: 10 }, legend: { orientation: "h", y: 1.1 }, annotations: revenuesData[revenuesLevel][1] }, plotlyOptions)
        }
    }, [revenuesLevel])

    useEffect(() => {
        let mounted = true
        async function retrieveRevenuesData() {
            const start = moment(dateSelection[0].startDate)
            const end = moment(dateSelection[0].endDate).add(1, 'd')

            if (props.user?.username && start && end) {
                const flagForecast = Storage.getDashboardData("forecastData")?.forecast ? !(Storage.getDashboardData("forecastData").forecast[moment().format("DD/MM/YYYY")]) : true
                return Services.getRevenuesData(props.user.username, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'), flagForecast)
                    .then((response) => {
                        // console.log("fatturati recuperati", response.data)

                        if (mounted) {
                            // fatturati giornalieri
                            let dailyRevenues = [{ x: [], y: [], type: 'scatter', name: 'Fatturato' }]
                            let dailyRevenuesAnnotations = []
                            for (let d = start; d.isBefore(end); d=d.add(1, 'd')) {
                                const key = d.format('DD/MM/YYYY')
                                dailyRevenues[0].x.push(key)
                                if (response.data.dailyRevenues[key]) {
                                    const value = response.data.dailyRevenues[key].toFixed(2)
                                    dailyRevenues[0].y.push(value)
                                    dailyRevenuesAnnotations.push({
                                        x: key,
                                        y: value,
                                        text: value,
                                        showarrow: false,
                                        font: { color: '#ffffff' },
                                        bgcolor: 'rgb(31, 119, 180)'
                                    })
                                } else {
                                    dailyRevenues[0].y.push(0)
                                }
                            }

                            // forecasting dai fatturati giornalieri
                            let forecastRevenues = {}
                            if (flagForecast) {
                                console.log("previsione eseguita")
                                forecastRevenues = response.data.forecastRevenues
                                Storage.saveDashboardData({
                                    "forecastData": response.data.forecastRevenues
                                })
                            } else {
                                forecastRevenues = Storage.getDashboardData("forecastData")
                            }

                            const forecastStart = Object.keys(forecastRevenues.forecast)?.sort((a, b) => moment(a, 'DD/MM/YYYY') - moment(b, 'DD/MM/YYYY'))[0]
                            if (forecastStart && moment(forecastStart, 'DD/MM/YYYY').isSameOrBefore(end)) {
                                dailyRevenues.push({
                                    x: [],
                                    y: [],
                                    fill: "toself",
                                    fillcolor: "rgba(0,176,246,0.2)",
                                    line: {color: "transparent"},
                                    name: "Previsione",
                                    showlegend: false,
                                    type: "scatter"
                                }, {
                                    x: [],
                                    y: [],
                                    line: {color: "rgb(0,176,246)"},
                                    name: "Previsione",
                                    type: "scatter"
                                })
    
                                const revenuesStart = moment(dateSelection[0].startDate)
                                for (let key of Object.keys(forecastRevenues.lower)?.sort((a, b) => moment(a, 'DD/MM/YYYY') - moment(b, 'DD/MM/YYYY'))) {
                                    if (revenuesStart.isSameOrBefore(moment(key, 'DD/MM/YYYY'))) {
                                        dailyRevenues[1].x.push(key)
    
                                        const value = forecastRevenues.lower[key].toFixed(2)
                                        dailyRevenues[1].y.push(value)
                                    }
                                }
    
                                for (let key of Object.keys(forecastRevenues.forecast)?.sort((a, b) => moment(a, 'DD/MM/YYYY') - moment(b, 'DD/MM/YYYY'))) {
                                    if (revenuesStart.isSameOrBefore(moment(key, 'DD/MM/YYYY'))) {
                                        dailyRevenues[2].x.push(key)
    
                                        const value = forecastRevenues.forecast[key].toFixed(2)
                                        dailyRevenues[2].y.push(value)
                                    }
                                }
    
                                const upperKeys = Object.keys(forecastRevenues.upper)?.sort((a, b) => moment(a, 'DD/MM/YYYY') - moment(b, 'DD/MM/YYYY'))
                                for (let i = upperKeys.length - 1; i >= 0; i--) {
                                    if (revenuesStart.isSameOrBefore(moment(upperKeys[i], 'DD/MM/YYYY'))) {
                                        dailyRevenues[1].x.push(upperKeys[i])
    
                                        const value = forecastRevenues.upper[upperKeys[i]].toFixed(2)
                                        dailyRevenues[1].y.push(value)
                                    }
                                }
                            }

                            // fatturati settimanali
                            let weeklyRevenues = [{ x: [], y: [], type: 'scatter' }]
                            let weeklyRevenuesAnnotations = []
                            for (let key of Object.keys(response.data.weeklyRevenues)?.sort((a, b) => {
                                if (a.split(" ")[1] !== b.split(" ")[1]) {
                                    return a.split(" ")[1] - b.split(" ")[1]
                                } else {
                                    return a.split(" ")[0] - b.split(" ")[0]
                                }
                            })) {
                                const k = moment(key, "WW GGGG").format("DD/MM/YYYY")
                                const value = response.data.weeklyRevenues[key].toFixed(2)

                                weeklyRevenues[0].x.push(k)
                                weeklyRevenues[0].y.push(value)
                                weeklyRevenuesAnnotations.push({
                                    x: k,
                                    y: value,
                                    text: value,
                                    showarrow: false,
                                    font: { color: '#ffffff' },
                                    bgcolor: 'rgb(31, 119, 180)'
                                })
                            }

                            // fatturati mensili
                            let monthlyRevenues = [{ x: [], y: [], type: 'scatter' }]
                            let monthlyRevenuesAnnotations = []
                            for (let key of Object.keys(response.data.monthlyRevenues)?.sort((a, b) => {
                                if (a.split("/")[1] !== b.split("/")[1]) {
                                    return a.split("/")[1] - b.split("/")[1]
                                } else {
                                    return a.split("/")[0] - b.split("/")[0]
                                }
                            })) {
                                const k = mesi[String(moment(key, "MM/YYYY").month()+1)] + " " + key.split("/")[1]
                                const value = response.data.monthlyRevenues[key].toFixed(2)

                                monthlyRevenues[0].x.push(k)
                                monthlyRevenues[0].y.push(value)
                                monthlyRevenuesAnnotations.push({
                                    x: k,
                                    y: value,
                                    text: value,
                                    showarrow: false,
                                    font: { color: '#ffffff' },
                                    bgcolor: 'rgb(31, 119, 180)'
                                })
                            }

                            switch (revenuesLevel) {
                                case "d":
                                    Plotly.newPlot("dailyRevenues", dailyRevenues, { margin: { t: 25, l: 25, r: 25 }, font: { size: 10 }, legend: { orientation: "h", y: 1.1 }, annotations: dailyRevenuesAnnotations }, plotlyOptions)
                                    break
                                case "w":
                                    Plotly.newPlot("weeklyRevenues", weeklyRevenues, { margin: { t: 25, l: 25, r: 25 }, font: { size: 10 }, legend: { orientation: "h", y: 1.1 }, annotations: weeklyRevenuesAnnotations }, plotlyOptions)
                                    break
                                case "m":
                                    Plotly.newPlot("monthlyRevenues", monthlyRevenues, { margin: { t: 25, l: 25, r: 25 }, font: { size: 10 }, legend: { orientation: "h", y: 1.1 }, annotations: monthlyRevenuesAnnotations }, plotlyOptions)
                                    break
                                default:
                                    break
                            }

                            // salvataggio in memoria
                            Storage.saveDashboardData({
                                "revenuesData": {d: [dailyRevenues, dailyRevenuesAnnotations], w: [weeklyRevenues, weeklyRevenuesAnnotations], m: [monthlyRevenues, monthlyRevenuesAnnotations]}
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }

        retrieveRevenuesData()
        return () => { mounted = false }
    }, [dateSelection])

    useEffect(() => {
        let mounted = true
        async function retrieveOrdersData() {
            const start = moment(dateSelection[0].startDate)
            const end = moment(dateSelection[0].endDate).add(1, 'd')

            if (props.user?.username && start && end) {
                return Services.getOrdersData(props.user.username, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
                    .then((response) => {
                        // console.log("dati sugli ordini recuperati", response.data)

                        if (mounted) {
                            // dati sulla piattaforma
                            let platformsData = [{ x: [], y: [], text: [], type: 'bar', hoverinfo: "y+text", hovertext: "CONSEGNE" },
                                                { x: [], y: [], text: [], type: 'bar', hoverinfo: "y+text", hovertext: "RITIRI" }]
                            for (let i in response.data.platforms) {
                                if (i === '') {
                                    continue
                                }

                                const cons = response.data.platforms[i][OrderConstants.MODE_CONSEGNA] ?? 0
                                const rit = response.data.platforms[i][OrderConstants.MODE_RITIRO] ?? 0
                                const label = i + " (" + ((cons+rit)/ordersData.count*100).toFixed(2) + " %)"

                                platformsData[0].x.push(label)
                                platformsData[0].y.push(cons)
                                platformsData[0].text.push(cons)

                                platformsData[1].x.push(label)
                                platformsData[1].y.push(rit)
                                platformsData[1].text.push(rit)
                            }
                            const cons = platformsData[0].y.reduce((total, value) => total + value, 0)
                            const rit = platformsData[1].y.reduce((total, value) => total + value, 0)
                            platformsData[0].name = cons + " CONSEGNE (" + (cons/ordersData.count*100).toFixed(2) + " %)"
                            platformsData[1].name = rit + " RITIRI (" + (rit/ordersData.count*100).toFixed(2) + " %)"
                            Plotly.newPlot("platforms", platformsData, { barmode: 'stack', margin: { t: 30, l: 30, r: 30 }, font: { size: 10 }, legend: { orientation: "h", y: 1.1 } }, plotlyOptions)

                            // dati sulla modalità
                            /* let newModalitiesData = { labels: [], datasets: [{ data: [], backgroundColor: [] }] }
                            for (let i in response.data.modalities) {
                                newModalitiesData.labels.push(`${labelsMap.count} (${response.data.modalities[i].mode})`)
                                newModalitiesData.datasets[0].data.push(response.data.modalities[i].count)
                            }
                            newModalitiesData.datasets[0].backgroundColor = Utils.colors({
                                color: Utils.color(0),
                                count: 2
                            })
                            setModalitiesData(newModalitiesData)
                            document.getElementById("collapseCardModalities")?.classList.add("show") */
                            setAveragesData(response.data.modalities)

                            // dati su andamento consegne
                            let dailyScores = [{ x: [], y: [], type: 'scatter' }]
                            let dailyScoresAnnotations = []
                            let sum = 0
                            let count = 0
                            for (let d = start; d.isBefore(end); d=d.add(1, 'd')) {
                                const key = d.format('DD/MM/YYYY')
                                dailyScores[0].x.push(key)
                                if (response.data.dailyScores[key]) {
                                    const value = response.data.dailyScores[key].toFixed(0)
                                    dailyScores[0].y.push(value)
                                    dailyScoresAnnotations.push({
                                        x: key,
                                        y: value,
                                        text: value + "%",
                                        showarrow: false,
                                        font: { color: '#ffffff' },
                                        bgcolor: value <= 10 ? '#e74a3b' : value <= 60 ? '#f6c23e' : '#1cc88a'
                                    })
                                    sum += response.data.dailyScores[key]
                                    count += 1
                                } else {
                                    dailyScores[0].y.push(0)
                                }
                            }
                            setMeanScore(count > 0 ? sum/count : 0)
                            Plotly.newPlot("scores", dailyScores, { margin: { t: 25, l: 25, r: 25 }, font: { size: 10 }, annotations: dailyScoresAnnotations }, plotlyOptions)

                            // dati sulle fasce orarie
                            let orderSlots = [{ x: [], y: [], text: [], type: 'bar', hoverinfo: "none", name: "Numero medio consegne" },
                                            { x: [], y: [], mode: 'lines', hoverinfo: "none", name: "Capacità media settimanale" }]
                            let orderSlotsAnnotations = []
                            for (let i in response.data.orderSlots) {
                                orderSlots[0].x.push(i + ":00")
                                orderSlots[1].x.push(i + ":00")
                                orderSlots[0].y.push(response.data.orderSlots[i][0] ?? 0)
                                orderSlots[1].y.push(response.data.orderSlots[i][1] ?? 0)
                                orderSlots[0].text.push(response.data.orderSlots[i][0] ?? 0)
                                orderSlotsAnnotations.push({
                                    x: i + ":00",
                                    y: response.data.orderSlots[i][1] ?? 0,
                                    text: response.data.orderSlots[i][1] ?? 0,
                                    arrowhead: 0,
                                    arrowcolor: 'rgb(255, 127, 14)',
                                    ax: -25,
                                    ay: -25,
                                    font: { color: '#ffffff' },
                                    bgcolor: 'rgb(255, 127, 14)'
                                })
                            }
                            Plotly.newPlot("orderSlots", orderSlots, { barmode: 'stack', margin: { t: 30, l: 30, r: 30 }, font: { size: 10 }, legend: { orientation: "h", y: 1.1 }, annotations: orderSlotsAnnotations }, plotlyOptions)

                            // salvataggio in memoria
                            Storage.saveDashboardData({
                                "platformsData": platformsData,
                                "averagesData": response.data.modalities,
                                "scoresData": [dailyScores, dailyScoresAnnotations],
                                "meanScore": count > 0 ? sum/count : 0,
                                "orderSlots": [orderSlots, orderSlotsAnnotations]
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }

        if (ordersData?.count) {
            retrieveOrdersData()
        }
        return () => { mounted = false }
    }, [dateSelection, ordersData?.count])

    useEffect(() => {
        let mounted = true
        async function retrieveDriversData() {
            const start = moment(dateSelection[0].startDate)
            const end = moment(dateSelection[0].endDate).add(1, 'd')

            if (props.user?.username && start && end) {
                return Services.getDriversData(props.user.username, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
                    .then((response) => {
                        // console.log("fatturati recuperati", response.data.drivers)
                        if (mounted) {
                            setDriversData(response.data.drivers)

                            // salvataggio in memoria
                            Storage.saveDashboardData({
                                "driversData": response.data.drivers
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }

        retrieveDriversData()
        return () => { mounted = false }
    }, [dateSelection])

    const renderDriversData = () => {
        let render = []
        for (let key in driversData) {
            const cleanedKey = key.replaceAll(" ", "")
            render.push(
                <React.Fragment key={key}>
                    <tbody>
                        <tr className="table-active text-dark">
                            <th><a href={`#collapse-${cleanedKey}`} data-toggle="collapse">{key}</a></th>
                            <th>{driversData[key].count} consegne</th>
                            <th>{driversData[key].avg_time} mins</th>
                            <th>{driversData[key].avg_delay} mins</th>
                        </tr>
                    </tbody>
                    <tbody className="collapse" id={`collapse-${cleanedKey}`}>
                        {driversData[key].hours.map((h, i) => {
                            return (
                                <tr key={`${key}-${i}`}>
                                    <td>{h[0]}:00</td>
                                    <td>{(h[1].count * 100).toFixed(0)} %</td>
                                    <td>{h[1].avg_time} mins</td>
                                    <td>{h[1].avg_delay} mins</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </React.Fragment>
            )
        }
        return render
    }

    // ORDINI DEL PERIODO
    const [orders, setOrders] = useState([])

    useEffect(() => {
        let mounted = true
        async function retrieveOrders() {
            const start = moment(dateSelection[0].startDate)
            const end = moment(dateSelection[0].endDate).add(1, 'd')

            if (props.user?.username && start && end) {
                return Services.getOrdersByDateRange(props.user.username, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
                    .then((response) => {
                        // console.log("ordini recuperati", response.data)
                        if (mounted) {
                            setOrders(response.data.orders)
                            document.getElementById("collapseCardMap")?.classList.add("show")
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }

        retrieveOrders()
        return () => { mounted = false }
    }, [dateSelection])

    // ALTRI DATI (INDIPENDENTI DAL PERIODO)
    const [reorderData, setReorderData] = useState(Storage.getDashboardData("reorderData") ?? [])
    const [costData, setCostData] = useState(Storage.getDashboardData("costData") ?? [])
    const [costDataMean, setCostDataMean] = useState(Storage.getDashboardData("costDataMean") ?? 0.0)

    useEffect(() => {
        let mounted = true
        async function retrieveOtherOrdersData() {
            if (props.user?.username) {
                return Services.getOtherOrdersData(props.user.username)
                    .then((response) => {
                        // console.log("altri dati recuperati", response.data)
                        if (mounted) {
                            setReorderData(response.data.reorder)

                            // salvataggio in memoria
                            Storage.saveDashboardData({
                                "reorderData": response.data.reorder
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }

        retrieveOtherOrdersData()
        return () => { mounted = false }
    }, [])

    useEffect(() => {
        let mounted = true
        async function retrieveOtherDriversData() {
            if (props.user?.username) {
                return Services.getOtherDriversData(props.user.username)
                    .then((response) => {
                        // console.log("altri dati drivers recuperati", response.data)
                        if (mounted) {
                            setCostData(response.data.cost)
                            setCostDataMean(response.data.mean)

                            // salvataggio in memoria
                            Storage.saveDashboardData({
                                "costData": response.data.cost,
                                "costDataMean": response.data.mean
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }

        retrieveOtherDriversData()
        return () => { mounted = false }
    }, [])

    // TABELLA CON PAGINAZIONE E RICERCA (Pizze più vendute del periodo)
    const columns = useMemo(
        () => [
            {
                Header: 'Nome pizza',
                accessor: '_id',
                Cell: props => <span style={{ whiteSpace: "pre-line" }}>{props.value}</span>
            },
            {
                Header: 'Numero di ordinazioni',
                accessor: 'count'
            }
        ],
        []
    )

    const memoizedFavorites = useMemo(() => favoritesData, [favoritesData])
    const searchRef = useRef(null)

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
            searchRef.current.focus()
        }, 1000)  // millisecondi dopo cui parte la ricerca

        return (
            <span>
                Cerca:{' '}
                <input
                    className="form-control"
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} risultati...`}
                    ref={searchRef}
                />
            </span>
        )
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns: columns,
            data: memoizedFavorites,
            initialState: { pageIndex: 0, pageSize: 10 }
        },
        //useFilters,
        useGlobalFilter,
        usePagination
    )

    // TABELLA CON ORDINAMENTO COLONNE (Specifiche ordini per CAP)
    const zipColumns = useMemo(
        () => [
            {
                Header: "CAP",
                accessor: "zip",
                Cell: props => <a href={`https://www.google.it/maps/place/${props.value}`} target="_blank" rel="noreferrer">{props.value}</a>
            },
            {
                Header: "Fatturato",
                accessor: "revenue",
                sortType: (rowA, rowB, id, desc) => rowA.values[id][0] - rowB.values[id][0],
                Cell: props => <span>
                    € {props.value[0].toFixed(2)}
                    &nbsp;
                    {(props.value[1] ? (props.value[1] > 0 ? <span className="badge rounded-pill bg-success text-white">+{props.value[1].toFixed(2)}</span>
                            : <span className="badge rounded-pill bg-danger text-white">{props.value[1].toFixed(2)}</span>)
                        : <span className="badge rounded-pill bg-secondary text-white">=</span>)}
                </span>
            },
            {
                Header: "Ordini",
                accessor: "orders",
                sortType: (rowA, rowB, id, desc) => rowA.values[id][0] - rowB.values[id][0],
                Cell: props => <span>
                    {props.value[0]}
                    &nbsp;
                    {(props.value[1] ? (props.value[1] > 0 ? <span className="badge rounded-pill bg-success text-white">+{props.value[1]}</span>
                            : <span className="badge rounded-pill bg-danger text-white">{props.value[1]}</span>)
                        : <span className="badge rounded-pill bg-secondary text-white">=</span>)}
                </span>
            },
            {
                Header: "Media prezzo ordine",
                accessor: "mean",
                sortType: (rowA, rowB, id, desc) => rowA.values[id][0] - rowB.values[id][0],
                Cell: props => <span>
                    € {props.value[0].toFixed(2)}
                    &nbsp;
                    {(props.value[1] ? (props.value[1] > 0 ? <span className="badge rounded-pill bg-success text-white">+{props.value[1].toFixed(2)}</span>
                            : <span className="badge rounded-pill bg-danger text-white">{props.value[1].toFixed(2)}</span>)
                        : <span className="badge rounded-pill bg-secondary text-white">=</span>)}
                </span>
            }
        ],
        []
    )

    const memoizedZips = useMemo(() => zipsData, [zipsData])

    function ZipTable() {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            rows
        } = useTable(
            {
                columns: zipColumns,
                data: memoizedZips
            },
            useSortBy
        )

        return (
            <table className="table text-dark" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        &nbsp;
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <i className="fas fa-sort-down"></i>
                                                : <i className="fas fa-sort-up"></i>
                                            : <i className="fas fa-sort"></i>}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className={row.values.zip === props.settings?.general?.cap ? "table-active" : ""}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }
    const renderZipTable = useCallback(() => <ZipTable />, [zipColumns, memoizedZips])

    // TOOLTIPS
    useEffect(() => {
        window.jQuery('[data-toggle="tooltip"]').tooltip()
    }, [])

    return (
        <div className="container-fluid">

            <div className="form-group row">
                <div className="col-6 d-flex justify-content-left">
                    <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
                </div>

                <div className="col-6 d-flex justify-content-end">
                    <div className="btn-group dropdown d-none d-xl-inline">
                        <button
                            type="button"
                            className="btn btn-primary dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i className="fas fa-calendar-week mr-2"></i>
                            <span className="d-none d-xl-inline">Seleziona intervallo di tempo</span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" onClick={(e) => e.stopPropagation()}>
                            <DateRangePicker
                                onChange={onChangeDateSelection}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                ranges={dateSelection}
                                direction="horizontal"
                            />
                        </div>
                    </div>
                    <div className="btn-group dropdown d-xl-none">
                        <button
                            type="button"
                            className="btn btn-primary dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i className="fas fa-calendar-week mr-2"></i>
                            <span className="d-none d-md-inline">Seleziona intervallo di tempo</span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" onClick={(e) => e.stopPropagation()}>
                            <DateRange
                                onChange={onChangeDateSelection}
                                moveRangeOnFirstSelection={false}
                                ranges={dateSelection}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        Fatturato
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        € {ordersData?.revenue?.toFixed(2) ?? '0.00'}
                                        &nbsp;
                                        {diffs?.revenue ? (diffs.revenue > 0 ? <span className="badge rounded-pill bg-success text-white">+{diffs.revenue.toFixed(2)}</span>
                                                                             : <span className="badge rounded-pill bg-danger text-white">{diffs.revenue.toFixed(2)}</span>)
                                                        : <span className="badge rounded-pill bg-secondary text-white">=</span>}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-euro-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Piatti
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        {ordersData?.plates ?? '0'}
                                        &nbsp;
                                        {diffs?.plates ? (diffs.plates > 0 ? <span className="badge rounded-pill bg-success text-white">+{diffs.plates}</span>
                                                                           : <span className="badge rounded-pill bg-danger text-white">{diffs.plates}</span>)
                                                       : <span className="badge rounded-pill bg-secondary text-white">=</span>}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-utensils fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-info shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                                        Ordini
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        {ordersData?.count ?? '0'}
                                        &nbsp;
                                        {diffs?.count ? (diffs.count > 0 ? <span className="badge rounded-pill bg-success text-white">+{diffs.count}</span>
                                                                         : <span className="badge rounded-pill bg-danger text-white">{diffs.count}</span>)
                                                      : <span className="badge rounded-pill bg-secondary text-white">=</span>}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                        Clienti nuovi
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        {ordersData?.clients ?? '0'}
                                        &nbsp;
                                        {diffs?.clients ? (diffs.clients > 0 ? <span className="badge rounded-pill bg-success text-white">+{diffs.clients}</span>
                                                                             : <span className="badge rounded-pill bg-danger text-white">{diffs.clients}</span>)
                                                        : <span className="badge rounded-pill bg-secondary text-white">=</span>}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-user-plus fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-secondary shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                        Media punteggio consegne
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        {meanScore.toFixed(0) ?? '0'} %
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-percent fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                        Coupon utilizzati
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        {appData?.coupons ?? '0'}
                                        &nbsp;
                                        {appData?.couponsDiff ? (appData.couponsDiff > 0 ? <span className="badge rounded-pill bg-success text-white">+{appData.couponsDiff}</span>
                                                                : <span className="badge rounded-pill bg-danger text-white">{appData.couponsDiff}</span>)
                                                                : <span className="badge rounded-pill bg-secondary text-white">=</span>}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-tags fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        Fatturato App
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        € {appData?.revenue?.toFixed(2) ?? '0.00'}
                                        &nbsp;
                                        {appData?.revenueDiff ? (appData.revenueDiff > 0 ? <span className="badge rounded-pill bg-success text-white">+{appData.revenueDiff.toFixed(2)}</span>
                                                                : <span className="badge rounded-pill bg-danger text-white">{appData.revenueDiff.toFixed(2)}</span>)
                                                                : <span className="badge rounded-pill bg-secondary text-white">=</span>}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-credit-card fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-danger shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                                        Clienti App
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        {appData?.clients ?? '0'}
                                        &nbsp;
                                        {appData?.clientsDiff ? (appData.clientsDiff > 0 ? <span className="badge rounded-pill bg-success text-white">+{appData.clientsDiff}</span>
                                                                : <span className="badge rounded-pill bg-danger text-white">{appData.clientsDiff}</span>)
                                                                : <span className="badge rounded-pill bg-secondary text-white">=</span>}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <i className="fas fa-mobile-alt fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card shadow mb-4">
                        <div className="d-block card-header py-3">
                            <div className="row">
                                <div className="col-6 d-flex justify-content-left">
                                    <h6 className="m-0 font-weight-bold text-primary">Andamento fatturato del periodo</h6>
                                </div>
                                <div className="col-6 d-flex justify-content-end">
                                    <button className="btn btn-primary btn-user btn-sm" type="button" onClick={() => setRevenuesLevel('d')}>
                                        G<span className="d-none d-lg-inline">iornaliero</span>
                                    </button>
                                    &nbsp;
                                    <button className="btn btn-primary btn-user btn-sm" type="button" onClick={() => setRevenuesLevel('w')}>
                                        S<span className="d-none d-lg-inline">ettimanale</span>
                                    </button>
                                    &nbsp;
                                    <button className="btn btn-primary btn-user btn-sm" type="button" onClick={() => setRevenuesLevel('m')}>
                                        M<span className="d-none d-lg-inline">ensile</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body collapse show" id="collapseCardRevenues">
                            <div id="dailyRevenues"></div>
                            <div id="weeklyRevenues"></div>
                            <div id="monthlyRevenues"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-6 col-md-12">
                    <div className="card shadow mb-4">
                        <a
                            href="#collapseCardScores"
                            className="d-block card-header py-3"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            aria-controls="collapseCardExample"
                        >
                            <h6 className="m-0 font-weight-bold text-primary">Andamento consegne del periodo</h6>
                        </a>
                        <div className="card-body collapse show" id="collapseCardScores">
                            <div id="scores"></div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-6 col-md-12">
                    <div className="card shadow mb-4">
                        <a
                            href="#collapseCardOrderSlots"
                            className="d-block card-header py-3"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            aria-controls="collapseCardExample"
                        >
                            <h6 className="m-0 font-weight-bold text-primary">Consegne per fascia oraria</h6>
                        </a>
                        <div className="card-body collapse show" id="collapseCardOrderSlots">
                            <div id="orderSlots"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-6 col-md-12">
                    <div className="card shadow mb-4">
                        <a
                            href="#collapseCardMap"
                            className="d-block card-header py-3"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            aria-controls="collapseCardExample"
                        >
                            <h6 className="m-0 font-weight-bold text-primary">Mappa consegne del periodo</h6>
                        </a>
                        <div className="card-body collapse" id="collapseCardMap">
                            {props.settings?.general?.coordinates ? <MapContainer array={orders} current={null} settings={props.settings} />
                            : <div className="alert alert-danger" role="alert">
                                <strong>Attenzione!</strong> Inserisci l'indirizzo della pizzeria nelle Impostazioni generali per visualizzare la mappa.
                            </div>}
                        </div>
                    </div>
                </div>

                <div className="col-xl-6 col-md-12">
                    <div className="card shadow mb-4">
                        <a
                            href="#collapseCardZips"
                            className="d-block card-header py-3"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            aria-controls="collapseCardExample"
                        >
                            <h6 className="m-0 font-weight-bold text-primary">Specifiche ordini per CAP</h6>
                        </a>
                        <div className="card-body collapse show" id="collapseCardZips">
                            <div className="table-responsive">
                                {renderZipTable()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-6 col-md-12">
                    <div className="card shadow mb-4">
                        <a
                            href="#collapseCardPlatforms"
                            className="d-block card-header py-3"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            aria-controls="collapseCardExample"
                        >
                            <h6 className="m-0 font-weight-bold text-primary">Ordini per piattaforma</h6>
                        </a>
                        <div className="card-body collapse show" id="collapseCardPlatforms">
                            <div id="platforms"></div>
                        </div>
                    </div>
                </div>

                {/* <div className="col-xl-6 col-md-12">
                    <div className="card shadow mb-4">
                        <a
                            href="#collapseCardModalities"
                            className="d-block card-header py-3"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            aria-controls="collapseCardExample"
                        >
                            <h6 className="m-0 font-weight-bold text-primary">Consegne e ritiri</h6>
                        </a>
                        <div className="card-body collapse" id="collapseCardModalities">
                            <Doughnut options={optionsDoughnut} data={modalitiesData} />
                        </div>
                    </div>
                </div> */}

                <div className="col-xl-6 col-md-12">
                    <div className="card shadow mb-4">
                        <a
                            href="#collapseCardAverages"
                            className="d-block card-header py-3"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            aria-controls="collapseCardExample"
                        >
                            <h6 className="m-0 font-weight-bold text-primary">Specifiche ordini per modalità</h6>
                        </a>
                        <div className="card-body collapse show" id="collapseCardAverages">
                            {averagesData.map((data, index) => {
                                return (
                                    <div className="table-responsive" key={`tabella-specifiche-${index}`}>
                                        <table className="table text-dark" width="100%">
                                            <thead>
                                                <tr>
                                                    <th>{data.mode}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{labelsMap.avg_plates}</td>
                                                    <td>{data.avg_plates}</td>
                                                </tr>
                                                <tr>
                                                    <td>{labelsMap.avg_price}</td>
                                                    <td>€ {data.avg_price.toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>{labelsMap.avg_total}</td>
                                                    <td>€ {data.avg_total.toFixed(2)}</td>
                                                </tr>
                                                {data.avg_time && <tr>
                                                    <td>{labelsMap.avg_time}</td>
                                                    <td>{data.avg_time}</td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-6 col-md-12">
                    <div className="card shadow mb-4">
                        <a
                            href="#collapseCardDrivers"
                            className="d-block card-header py-3"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            aria-controls="collapseCardExample"
                        >
                            <h6 className="m-0 font-weight-bold text-primary">Specifiche consegne per fattorino</h6>
                        </a>
                        <div className="card-body collapse show" id="collapseCardDrivers">
                            <div className="table-responsive">
                                <table className="table text-dark" width="100%">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Clicca su un nome per espandere le fasce orarie"
                                                    style={{ textDecoration: "underline 1px dotted" }}
                                                >
                                                    Fasce orarie
                                                </span>
                                            </th>
                                            <th>Ripartizione consegne</th>
                                            <th>Tempo medio di consegna</th>
                                            <th>Ritardo medio</th>
                                        </tr>
                                    </thead>
                                    {renderDriversData()}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-6 col-md-12">
                    <div className="card shadow mb-4">
                        <a
                            href="#collapseCardFavorites"
                            className="d-block card-header py-3"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            aria-controls="collapseCardExample"
                        >
                            <h6 className="m-0 font-weight-bold text-primary">Pizze più vendute del periodo</h6>
                        </a>
                        <div className="card-body collapse show" id="collapseCardFavorites">
                            <div className="table-responsive text-dark">
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                                &nbsp;
                                <table className="table text-dark" {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row, i) => {
                                            prepareRow(row)
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {/* Pagination */}
                                <ul className="pagination">
                                    <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                        <button className="page-link">Inizio</button>
                                    </li>
                                    <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        <button className="page-link">{'<'}</button>
                                    </li>
                                    <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
                                        <button className="page-link">{'>'}</button>
                                    </li>
                                    <li className="page-item" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                        <button className="page-link">Fine</button>
                                    </li>
                                    <li>
                                        <span className="page-link">
                                            Pag.{' '}
                                            <strong>
                                                {pageIndex + 1} di {pageOptions.length}
                                            </strong>{' '}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="page-link">
                                            <input
                                                className="form-control"
                                                type="number"
                                                value={pageIndex ? pageIndex + 1 : 1}
                                                min={1}
                                                max={pageOptions.length}
                                                onChange={e => {
                                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                                    gotoPage(page)
                                                }}
                                                style={{ width: '100px', height: '20px' }}
                                            />
                                        </span>
                                    </li>
                                    &nbsp;
                                    <select
                                        className="form-control"
                                        value={pageSize}
                                        onChange={e => {
                                            setPageSize(Number(e.target.value))
                                        }}
                                        style={{ width: '125px', height: '38px' }}
                                    >
                                        {[5, 10, 20, 30, 40, 50].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                Mostra: {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="form-group row">
                <div className="col-12 d-flex justify-content-left">
                    <h1 className="h3 mb-0 text-gray-800">Altre analisi</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-6 col-md-12">
                    <div className="card shadow mb-4">
                        <a
                            href="#collapseCardReorder"
                            className="d-block card-header py-3"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            aria-controls="collapseCardExample"
                        >
                            <h6 className="m-0 font-weight-bold text-primary">Valori periodici</h6>
                        </a>
                        <div className="card-body collapse show" id="collapseCardReorder">
                            <div className="table-responsive">
                                <table className="table text-dark" width="100%">
                                    <thead>
                                        <tr>
                                            <th>Periodo</th>
                                            <th>
                                                <span
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Percentuale di clienti che hanno ordinato almeno 2 volte nel periodo indicato"
                                                    style={{ textDecoration: "underline 1px dotted" }}
                                                >
                                                    Tasso di riordino
                                                </span>
                                            </th>
                                            <th>
                                                <span
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Giorno della settimana con fatturato medio minore nel periodo indicato"
                                                    style={{ textDecoration: "underline 1px dotted" }}
                                                >
                                                    Fatturato minore
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reorderData.map((data, index) => {
                                            return (
                                                <tr key={`tabella-riordino-${index}`}>
                                                    <td>Ultimi {data[0]} giorni</td>
                                                    <td>{data[1].toFixed(2)} %</td>
                                                    <td>{days[data[2]-1]}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-6 col-md-12">
                    <div className="card shadow mb-4">
                        <a
                            href="#collapseCardCost"
                            className="d-block card-header py-3"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            aria-controls="collapseCardExample"
                        >
                            <h6 className="m-0 font-weight-bold text-primary">Costo medio consegna</h6>
                        </a>
                        <div className="card-body collapse show" id="collapseCardCost">
                            <div className="table-responsive">
                                <table className="table table-bordered text-dark text-center" width="100%">
                                    <thead>
                                        <tr className="table-active">
                                            <th>Mese</th>
                                            <th>Valore</th>
                                            <th>
                                                <span
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Differenza tra il valore del mese corrente e il valore medio dei 6 mesi precedenti"
                                                    style={{ textDecoration: "underline 1px dotted" }}
                                                >
                                                    Trend
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {costData.map((data, index) => {
                                            if (index === 0) {
                                                const diff = data[1] - costDataMean

                                                return (
                                                    <tr key={data[0]}>
                                                        <td>{mesi[String(moment(data[0]).month()+1)]}</td>
                                                        <td>€ {data[1].toFixed(2)}</td>
                                                        <td>
                                                            € {diff.toFixed(2)}
                                                            &nbsp;
                                                            {diff ? (diff > 0 ? <span className="badge rounded-pill bg-danger text-white"><i className="fas fa-angle-up"></i></span>
                                                                              : <span className="badge rounded-pill bg-success text-white"><i className="fas fa-angle-down"></i></span>)
                                                                  : <span className="badge rounded-pill bg-secondary text-white"><i className="fas fa-equals"></i></span>}
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                            return (
                                                <tr key={data[0]}>
                                                    <td>{mesi[String(moment(data[0]).month()+1)]}</td>
                                                    <td>€ {data[1].toFixed(2)}</td>
                                                    {index === 1 && <td rowSpan={6} className="align-middle">€ {costDataMean.toFixed(2)}</td>}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Dashboard
