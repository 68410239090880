import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import Services from '../services.js'


const Ricette = (props) => {

    const [nome, setNome] = useState("")
    const [value, setValue] = useState("")
    const modules = {  //https://quilljs.com/docs/modules/toolbar/
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote', 'code-block'],
          
            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          
            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            [{ 'font': [] }],
            [{ 'align': [] }],
          
            ['clean']                                         // remove formatting button
        ]
    }

    const onChangeNome = (e) => {
        const nome = e.target.value
        setNome(nome)
    }

    const [recipes, setRecipes] = useState([])
    const [currentRecipe, setCurrentRecipe] = useState(null)
    const [show, setShow] = useState(false)
    const [body, setBody] = useState("")

    async function retrieveRecipes() {
        if (props.user?.username) {
            return Services.getSettings(props.user.username)
                .then((response) => {
                    setRecipes(response.data.settings?.recipes ?? [])
                })
                .catch((error) => {
                    console.log(error)
                    setRecipes([])
                })
        } else {
            setRecipes([])
        }
    }

    useEffect(() => {
        retrieveRecipes().then(() => {
            console.log("ricette recuperate")
        }, (err) => console.log(err))
    }, [])

    useEffect(() => {
        if (currentRecipe) {
            setNome(currentRecipe?.name ?? "")
            setValue(currentRecipe?.content ?? "")
        } else {
            setNome("")
            setValue("")
        }
    }, [currentRecipe])

    const insertRecipes = () => {
        if (props.user?.username && nome) {
            const recipe = {
                name: nome,
                content: String(value)
            }

            Services.insertRecipes(props.user.username, recipe)
                    .then((response) => {
                        setNome("")
                        setValue("")
                        retrieveRecipes().then(() => {}, (err) => console.log(err))
                        setShow(false)
                    })
                    .catch((error) => {
                        setBody(error.response ? error.response.data.error : "Non è stato possibile creare il record.")
                        setShow(true)
                    })
        } else {
            setBody("Riempire i campi obbligatori.")
            setShow(true)
        }
        setCurrentRecipe(null)
    }
    const updateRecipes = () => {
        if(props.user?.username && currentRecipe.name && nome) {
            const recipe = {
                name: nome,
                content: String(value)
            }

            Services.updateRecipes(props.user.username, currentRecipe.name, recipe)
                    .then((response) => {
                        setNome("")
                        setValue("")
                        retrieveRecipes().then(() => {}, (err) => console.log(err))
                        setShow(false)
                    })
                    .catch((error) => {
                        setBody(error.response ? error.response.data.error : "Non è stato possibile aggiornare il record.")
                        setShow(true)
                    })
        } else {
            setBody("Riempire i campi obbligatori.")
            setShow(true)
        }
        setCurrentRecipe(null)
    }
    const deleteRecipes = () => {
        if (props.user?.username && currentRecipe.name) {
            Services.deleteRecipes(props.user.username, currentRecipe.name)
                    .then((response) => {
                        retrieveRecipes().then(() => {}, (err) => console.log(err))
                        setShow(false)
                    })
                    .catch((error) => {
                        setBody(error.response ? error.response.data.error : "Non è stato possibile eliminare il record.")
                        setShow(true)
                    })
        }
        setCurrentRecipe(null)
    }

    // TOOLTIPS
    useEffect(() => {
        window.jQuery('[data-toggle="tooltip"]').tooltip()
    }, [])

    return (
        <div className="container-fluid">

            <div className="card shadow mb-4">

                {/* <!-- Card Header --> */}
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Elenco Ricette</h6>
                </div>

                {/* <!-- Card Body --> */}
                <div className="card-body text-dark">
                    {show ? (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>Attenzione!</strong> {body}
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShow(false)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    ) : (
                        <div></div>
                    )}

                    <div className="form-group row">
                        <div className="col-12">
                            <button
                                type="button"
                                className="btn btn-success btn-font-sm"
                                data-toggle="modal"
                                data-target="#modal-gestione"
                                data-backdrop="static"  //non si può uscire cliccando fuori
                                data-keyboard="false"   //non si può uscire premendo Esc
                            >
                                <i className="fa fa-plus"></i>
                                <span className="ml-2">Aggiungi</span>
                            </button>
                        </div>
                    </div>

                    <div className="table-responsive text-dark">
                        <table className="table text-dark" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>
                                        <span
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Clicca su un nome per visualizzare la ricetta"
                                            style={{ textDecoration: "underline 1px dotted" }}
                                        >
                                            Ricetta
                                        </span>
                                    </th>
                                    <th style={{ width: "10%" }}>Azioni</th>
                                </tr>
                            </thead>
                            {recipes.map((d, index) => {
                                return (
                                    <React.Fragment key={`recipe${index}`}>
                                        <tbody>
                                            <tr>
                                                <td><a href={`#ricetta-${index}`} data-toggle="collapse">{d.name}</a></td>
                                                <td>
                                                    <button
                                                        className="btn btn-warning btn-icon"
                                                        type="button"
                                                        data-toggle="modal"
                                                        data-target="#modal-gestione"
                                                        data-backdrop="static"  //non si può uscire cliccando fuori
                                                        data-keyboard="false"   //non si può uscire premendo Esc
                                                        recipeindex={index}
                                                        onClick={(e) => setCurrentRecipe(recipes[e.currentTarget.getAttribute('recipeindex')])}
                                                        title="Modifica"
                                                    >
                                                        <i className="fas fa-edit"></i>
                                                    </button>
                                                    &nbsp;
                                                    <button
                                                        className="btn btn-danger btn-icon"
                                                        type="button"
                                                        data-toggle="modal"
                                                        data-target="#modal-cancella"
                                                        data-backdrop="static"  //non si può uscire cliccando fuori
                                                        data-keyboard="false"   //non si può uscire premendo Esc
                                                        recipeindex={index}
                                                        onClick={(e) => setCurrentRecipe(recipes[e.currentTarget.getAttribute('recipeindex')])}
                                                        title="Cancella"
                                                    >
                                                        <i className="fas fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody className="collapse" id={`ricetta-${index}`}>
                                            <tr>
                                                <td className="table-active" colSpan={2} dangerouslySetInnerHTML={{__html: d.content}}></td>
                                            </tr>
                                        </tbody>
                                    </React.Fragment>
                                )
                            })}
                        </table>
                    </div>
                </div>
            </div>

            {/* <!-- INIZIO MODAL CANCELLA --> */}
            <div className="modal fade" id="modal-cancella" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Cancella Ricetta</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setCurrentRecipe(null)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <p>Sei sicuro di voler cancellare la Ricetta selezionata?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => setCurrentRecipe(null)}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => deleteRecipes()}
                            >
                                Cancella
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- FINE MODAL CANCELLA --> */}

            {/* <!-- INIZIO MODAL GESTIONE --> */}
            <div className="modal fade" id="modal-gestione" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Inserisci / aggiorna Ricetta</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setCurrentRecipe(null)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div className="form-group row">
                                <div className="col-12">
                                    <input
                                        type="text"
                                        id="nome"
                                        placeholder="Inserire un nome"
                                        className={(show && !nome) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                        value={nome}
                                        onChange={onChangeNome}
                                    ></input>
                                </div>
                            </div>
                            <ReactQuill theme="snow" value={value} onChange={setValue} modules={modules} />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => setCurrentRecipe(null)}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => {
                                    if (currentRecipe) {
                                        updateRecipes()
                                    } else {
                                        insertRecipes()
                                    }
                                }}
                            >
                                Conferma
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- FINE MODAL GESTIONE --> */}

        </div>
    )
}

export default Ricette
