class Storage {

    getDashboardData(key) {
        return JSON.parse(localStorage.getItem(key))
    }

    saveDashboardData(dashboardData) {
        for (let key in dashboardData) {
            if (dashboardData[key]) {
                localStorage.setItem(key, JSON.stringify(dashboardData[key]))
            }
        }
    }

    removeDashboardData(key) {
        localStorage.removeItem(key)
    }

}

export default new Storage()
