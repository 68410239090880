import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'

import ItemCategories from '../enums/ItemCategories'
import OrderConstants from '../enums/OrderConstants'

const moment = require('moment')
const prettyDate = (hour) => {
    return hour.split('T')[0]
}


const MapContainer = ({ array, current, settings, drivers=[] }) => {

    const [ currentPosition, setCurrentPosition ] = useState(settings.general.coordinates)
    const [ defaultCenter, setDefaultCenter ] = useState(settings.general.coordinates)

    const map = useRef(null)
    const infoWindow = useRef(null)
    const markerElement = useRef(null)
    const pinElement = useRef(null)
    const [loaded, setLoaded] = useState(false)

    const currentMarker = useRef(null)

    const markerLists = useRef([[], []])

    // initMap + homeMarker
    useLayoutEffect(() => {
        let mounted = true
        async function initMap() {
            const { AdvancedMarkerElement, PinElement } = await window.google.maps.importLibrary("marker");
            markerElement.current = AdvancedMarkerElement
            pinElement.current = PinElement

            const { Map, InfoWindow } = await window.google.maps.importLibrary("maps");
            infoWindow.current = new InfoWindow()

            if (mounted) {
                map.current = new Map(document.getElementById("map"), {
                    mapId: "DEMO_MAP_ID",
                    draggable: true,
                    zoom: 13,
                    center: currentPosition,
                    clickableIcons: false
                });

                const pin = new PinElement({
                    scale: 1.1,
                    background: "yellow",
                    borderColor: "black",
                    glyphColor: "black"
                });
                const homeMarker = new AdvancedMarkerElement({
                    map: map.current,
                    position: defaultCenter,
                    title: "Pizzeria",
                    content: pin.element
                });

                setLoaded(true)
            }
        }

        initMap();
        return () => { mounted = false }
    }, [])

    // currentMarker
    useEffect(() => {
        if (loaded) {
            if (current) {
                if (currentMarker.current) {
                    currentMarker.current.position = current
                } else {
                    const pin = new pinElement.current({
                        scale: 1.1,
                        background: "green",
                        borderColor: "black",
                        glyphColor: "black"
                    });
                    currentMarker.current = new markerElement.current({
                        map: map.current,
                        position: current,
                        title: "Ordine corrente",
                        content: pin.element
                    });
                }
            }
        }
    }, [loaded, current])

    // consegne (lista in posizione 0 di markerLists)
    useEffect(() => {
        if (loaded) {
            deleteMarkers(0)
            for (let item of array) {
                if (item.reg?.coordinates?.lat && item.reg?.coordinates?.lng &&
                    item.mode === OrderConstants.MODE_CONSEGNA &&
                    !(moment().format('YYYY-MM-DD') === prettyDate(item.hour) && item.driver)) {  // mappa di oggi: consegne non ancora partite, altrimenti: tutte le consegne
                    
                    addMarker(item, 0)
                }
            }
            showMarkers(0)
        }
    }, [loaded, array])

    // fattorini (lista in posizione 1 di markerLists)
    useEffect(() => {
        if (loaded) {
            if (drivers?.length > 0) {
                deleteMarkers(1)
                for (let item of settings.employees) {
                    if (item.position?.coordinates?.lat && item.position?.coordinates?.lng && item.position?.update &&
                        moment().format('YYYY-MM-DD') === prettyDate(item.position.update) &&
                        drivers.includes(item.name)) {

                        addMarker(item, 1)
                    }
                }
                showMarkers(1)
            }
        }
    }, [loaded, settings?.employees])

    // Adds a marker to the map and push to the array.
    function addMarker(item, array) {
        if (array === 0) {
            const marker = new markerElement.current({
                position: item.reg?.coordinates
            });

            // Add a click listener for each marker, and set up the info window.
            marker.addListener("click", ({ domEvent, latLng }) => {
                const { target } = domEvent;

                infoWindow.current.close();
                infoWindow.current.setContent(
                    `<div className="infowindow">
                        <span><b>ORARIO:</b> ${item.hour.split('T')[1].substr(0, 5)}</span><br /><br />
                        <span><b>Ordine:</b> #${item.seq_value} del ${prettyDate(item.hour)}</span><br />
                        <span><b>Indirizzo:</b> ${item.reg?.address}</span><br />
                        <span><b>Pizze:</b> ${item.plates ?? item.items?.reduce((total, currentValue) => {
                                if (currentValue.category === ItemCategories.PIZZE) {
                                    return total + currentValue.quantity
                                } else {
                                    return total
                                }
                            }, 0)}
                        </span><br />
                        <span><b>Andata:</b> ${item.reg?.distance.space} - ${item.reg?.distance.time}</span><br />
                        <span><b>Ritorno:</b> ${item.reg?.reverseDistance.space} - ${item.reg?.reverseDistance.time}</span>
                    </div>`
                );
                infoWindow.current.open(marker.map, marker);
            });

            markerLists.current[array].push(marker);
        } else {
            const pin = new pinElement.current({
                scale: 1.1,
                background: "blue",
                borderColor: "black",
                glyphColor: "black"
            });
            const marker = new markerElement.current({
                position: item.position.coordinates,
                title: item.name,
                content: pin.element
            });

            markerLists.current[array].push(marker);
        }
    }

    // Sets the map on all markers in the array.
    function setMapOnAll(map, array) {
        for (let i = 0; i < markerLists.current[array]?.length; i++) {
            markerLists.current[array][i].setMap(map);
        }
    }

    // Removes the markers from the map, but keeps them in the array.
    function hideMarkers(array) {
        setMapOnAll(null, array);
    }

    // Shows any markers currently in the array.
    function showMarkers(array) {
        setMapOnAll(map.current, array);
    }

    // Deletes all markers in the array by removing references to them.
    function deleteMarkers(array) {
        hideMarkers(array);
        markerLists.current[array] = [];
    }

    return (
        <div id="map" style={{ height: "80vh", width: "100%" }}></div>
    )

}

export default MapContainer
