import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce } from 'react-table'
import { QRCodeSVG } from 'qrcode.react'

import Services from '../services.js'
import SpesaConstants from '../enums/SpesaConstants'

const moment = require('moment')
const days = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']


const Report = (props) => {
    const [date, setDate] = useState(moment().format('YYYY-MM-01'))
    const [ordersData, setOrdersData] = useState({})
    const [spese, setSpese] = useState([])
    const [show, setShow] = useState(false)
    const [target, setTarget] = useState(null)

    const onChangeDate = (e) => {
        const date = e.target.value
        if (date) {
            const newDate = moment(date).format('YYYY-MM-01')
            setDate(newDate)
        }
    }
    const handleIncrease = () => {
        if (date) {
            const newDate = moment(date).add(1, 'M').format('YYYY-MM-01')
            setDate(newDate)
        }
    }
    const handleDecrease = () => {
        if (date) {
            const newDate = moment(date).subtract(1, 'M').format('YYYY-MM-01')
            setDate(newDate)
        }
    }
    const dateToday = () => {
        if (date !== moment().format('YYYY-MM-01')) {
            setDate(moment().format('YYYY-MM-01'))
        } else {
            retrieveOrdersData().then(() => {
                console.log("dati recuperati")
            }, (err) => console.log(err))
        }
    }

    async function retrieveOrdersData() {
        setShow(false)
        const start = moment(date)
        const end = moment(date).add(start.daysInMonth(), 'd')

        if (props.user?.username && start && end) {
            return Services.getDataForReport(props.user.username, start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
                .then((response) => {
                    // console.log(response.data)
                    setOrdersData(response.data)
                    setSpese(response.data?.spese ?? [])
                    setShow(true)
                })
                .catch((error) => {
                    console.log(error)
                    setOrdersData({})
                    setSpese([])
                })
        }
    }

    useEffect(() => {
        retrieveOrdersData().then(() => {
            console.log("dati recuperati")
        }, (err) => console.log(err))
    }, [date])

    // AGGIORNAMENTO AUTOMATICO SPESA STIPENDI
    useEffect(() => {
        if (ordersData && ordersData?.spese && ordersData?.employeesData) {
            const stip = ordersData.spese.filter((value) => {
                return value.article === `***STIPENDI ${moment(date).format('YYYY-MM')}***`
            })[0]
            const spesaStipendi = stip?.amount

            let spesaStipendiTmp = 0
            const employeesData = ordersData.employeesData
            for (let d in employeesData) {
                for (let e in employeesData[d]) {
                    if (employeesData[d][e]?.total) {
                        spesaStipendiTmp += parseFloat(employeesData[d][e].total.toFixed(2))
                    }
                }
            }

            // se spesaStipendi esiste ed è uguale a spesaStipendiTmp, non faccio nulla
            if (spesaStipendi === undefined || !(spesaStipendi >= 0 && spesaStipendi.toFixed(2) === spesaStipendiTmp.toFixed(2))) {
                console.log(spesaStipendi, spesaStipendiTmp.toFixed(2))

                Services.updateSpesaStipendi(props.user.username, Number(spesaStipendiTmp.toFixed(2).replace(',', '.')), date)
                        .then((response) => {
                            console.log("aggiornata spesa stipendi")
                            retrieveOrdersData().then(() => console.log("dati recuperati"), (err) => console.log(err))
                        })
                        .catch((error) => {
                            console.log(error.response ? error.response.data.error : "Server error.")
                        })
            }
        }
    }, [ordersData])

    // gestione evento onSubmit
    useEffect(() => {
        if (target) {
            const userData = {}
            const formData = new FormData(target)
            for (let [key, value] of formData.entries()) {
                const intKey = key.split('#')[0]  // chiusura/imposta
                const extKey = key.split('#')[1]  // data
                const val = Number(value)
                if (!userData[extKey]) {
                    userData[extKey] = {}
                }
                userData[extKey][intKey] = val
            }
            console.log(userData)

            setTarget(null)
            updateReport(userData)
        }
    }, [target])

    const updateReport = (userData) => {
        if (props.user.username) {
            Services.updateReport(props.user.username, date, userData)
                .then((response) => {
                    console.log("informazioni salvate/aggiornate")
                })
                .catch((error) => {
                    console.log("impossibile aggiornare")
                })
        } else {
            console.log("impossibile aggiornare")
        }
    }

    const renderTables = () => {
        let render = []
        let i = 0
        while (i < moment(date).daysInMonth()) {
            const newWeek = moment(date).add(i, 'd')

            let weekDays = [newWeek]
            for (let d = 1; d < 7; d++) {
                const newDay = moment(newWeek).add(d, 'd')
                if (newDay.month() !== moment(date).month() || newDay.day() === 1) {  //esco quando è finito il mese oppure quando trovo un lunedì
                    break
                }
                weekDays.push(newDay)
            }

            // totale
            let totRev = 0
            for (let d in weekDays) {
                const key = weekDays[d].format('YYYY-MM-DD')
                if (ordersData.revenues[key]) {
                    totRev += ordersData.revenues[key]
                }
            }

            // chiusura
            let totChiusura = 0
            for (let d in weekDays) {
                const key = weekDays[d].format('YYYY-MM-DD')
                if (ordersData.userData[key]?.chiusura) {
                    totChiusura += ordersData.userData[key].chiusura
                }
            }

            // imposta
            let totImposta = 0
            for (let d in weekDays) {
                const key = weekDays[d].format('YYYY-MM-DD')
                if (ordersData.userData[key]?.imposta) {
                    totImposta += ordersData.userData[key].imposta
                }
            }

            // bancomat
            let totBancomat = 0
            for (let d in weekDays) {
                const key = weekDays[d].format('YYYY-MM-DD')
                if (ordersData.bancomat[key]) {
                    totBancomat += ordersData.bancomat[key]
                }
            }

            // piattaforme già pagati
            let totPlat = 0
            for (let d in weekDays) {
                const key = weekDays[d].format('YYYY-MM-DD')
                if (ordersData.platforms[key]) {
                    totPlat += ordersData.platforms[key]
                }
            }

            // piatti
            let totDishes = 0
            for (let d in weekDays) {
                const key = weekDays[d].format('YYYY-MM-DD')
                if (ordersData.plates[key]) {
                    totDishes += ordersData.plates[key]
                }
            }

            render.push(
                <div className="table-responsive" key={`settimana${i}`}>
                    <table className="table table-bordered text-dark table-sm" style={{ width: "100%" }}>
                        <thead>
                            <tr className="table-active">
                                <th style={{ width: "16%" }}>Data</th>
                                <th style={{ width: "14%" }}>Fatturato</th>
                                <th style={{ width: "14%" }}>Chiusura</th>
                                <th style={{ width: "14%" }}>Imposta</th>
                                <th style={{ width: "14%" }}>Bancomat</th>
                                <th style={{ width: "14%" }}>Piattaforme già pagati</th>
                                <th style={{ width: "14%" }}>Piatti</th>
                            </tr>
                        </thead>
                        <tbody>
                            {weekDays.map((d, index) => {
                                const rev = ordersData.revenues[d.format('YYYY-MM-DD')]
                                const chiusura = ordersData.userData[d.format('YYYY-MM-DD')]?.chiusura
                                const imposta = ordersData.userData[d.format('YYYY-MM-DD')]?.imposta
                                const bancomat = ordersData.bancomat[d.format('YYYY-MM-DD')]
                                const plat = ordersData.platforms[d.format('YYYY-MM-DD')]
                                const dishes = ordersData.plates[d.format('YYYY-MM-DD')]

                                return (
                                    <tr key={`settimana${i}-giorno${index}`}>
                                        <th scope="row">{days[d.day()]} - {d.format('DD/MM/YYYY')}</th>
                                        <td>€ {rev?.toFixed(2) ?? '0.00'}</td>
                                        <td>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">€</span>
                                                </div>
                                                <input
                                                    className="form-control form-control-user"
                                                    type="number"
                                                    min={0}
                                                    step={0.01}
                                                    defaultValue={chiusura ?? 0}
                                                    name={`chiusura#${d.format('YYYY-MM-DD')}`}
                                                ></input>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">€</span>
                                                </div>
                                                <input
                                                    className="form-control form-control-user"
                                                    type="number"
                                                    min={0}
                                                    step={0.01}
                                                    defaultValue={imposta ?? 0}
                                                    name={`imposta#${d.format('YYYY-MM-DD')}`}
                                                ></input>
                                            </div>
                                        </td>
                                        <td>€ {bancomat?.toFixed(2) ?? '0.00'}</td>
                                        <td>€ {plat?.toFixed(2) ?? '0.00'}</td>
                                        <td>{dishes ?? '0'}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr className="table-active">
                                <th style={{ width: "16%" }}>TOTALE</th>
                                <th style={{ width: "14%" }}>€ {totRev.toFixed(2)}</th>
                                <th style={{ width: "14%" }}>€ {totChiusura.toFixed(2)}</th>
                                <th style={{ width: "14%" }}>€ {totImposta.toFixed(2)}</th>
                                <th style={{ width: "14%" }}>€ {totBancomat.toFixed(2)}</th>
                                <th style={{ width: "14%" }}>€ {totPlat.toFixed(2)}</th>
                                <th style={{ width: "14%" }}>{totDishes}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            )

            i += weekDays.length
        }
        return render
    }

    // GESTIONE SPESE
    const [currArticle, setCurrArticle] = useState("")
    const [currAmount, setCurrAmount] = useState("")
    const [currCategory, setCurrCategory] = useState(SpesaConstants.CAT_MATERIAPRIMA)
    const [currVAT, setCurrVAT] = useState("")
    const [currTypology, setCurrTypology] = useState(SpesaConstants.TYP_FATTURA)
    const [currPayment, setCurrPayment] = useState(SpesaConstants.PAY_CONTANTI)
    const [showSpese, setShowSpese] = useState(false)
    const [bodySpese, setBodySpese] = useState("")
    const [currentSpesa, setCurrentSpesa] = useState(null)
    const articleRef = useRef(null)

    const onChangeCurrArticle = (e) => {
        const article = e.target.value
        setCurrArticle(article)
    }
    const onChangeCurrAmount = (e) => {
        const amount = e.target.value
        setCurrAmount(amount)
    }
    const onChangeCurrVAT = (e) => {
        const VAT = e.target.value
        setCurrVAT(VAT)
    }

    useEffect(() => {
        if (currentSpesa) {
            setCurrArticle(currentSpesa.article)
            setCurrAmount(String(currentSpesa.amount))
            setCurrCategory(currentSpesa.category)
            setCurrVAT(String(currentSpesa.vat))
            setCurrTypology(currentSpesa.typology)
            setCurrPayment(currentSpesa.payment)
        } else {
            setCurrArticle("")
            setCurrAmount("")
            setCurrVAT("")
            setShowSpese(false)
        }
    }, [currentSpesa])

    const insertSpesa = () => {
        if (props.user.username && currArticle && currAmount && currVAT && currArticle !== `***STIPENDI ${moment(date).format('YYYY-MM')}***`) {
            const spesa = {
                article: currArticle,
                amount: Number(currAmount.replace(',', '.')),
                category: currCategory,
                vat: Number(currVAT.replace(',', '.')),
                typology: currTypology,
                payment: currPayment
            }

            Services.insertSpesa(props.user.username, spesa, date)
                .then((response) => {
                    setCurrArticle("")
                    setCurrAmount("")
                    setCurrVAT("")
                    retrieveOrdersData().then(() => console.log("dati recuperati"), (err) => console.log(err))
                    setShowSpese(false)
                })
                .catch((error) => {
                    setBodySpese(error.response ? error.response.data.error : "Server error.")
                    setShowSpese(true)
                })
        } else {
            setBodySpese("Non hai riempito i campi obbligatori oppure hai scelto un nome non valido.")
            setShowSpese(true)
        }
    }
    const updateSpesa = () => {
        if(props.user.username && currArticle && currAmount && currVAT) {
            const spesa = {
                insertDate: currentSpesa?.insertDate,
                article: currArticle,
                amount: Number(currAmount.replace(',', '.')),
                category: currCategory,
                vat: Number(currVAT.replace(',', '.')),
                typology: currTypology,
                payment: currPayment
            }

            Services.updateSpesa(props.user.username, spesa, date)
                    .then((response) => {
                        setCurrentSpesa(null)
                        retrieveOrdersData().then(() => console.log("dati recuperati"), (err) => console.log(err))
                        setShowSpese(false)
                    })
                    .catch((error) => {
                        setBodySpese(error.response ? error.response.data.error : "Server error.")
                        setShowSpese(true)
                    })
        } else {
            setBodySpese("Non hai riempito i campi obbligatori.")
            setShowSpese(true)
        }
    }
    const deleteSpesa = () => {
        if (props.user.username && currentSpesa?.insertDate) {
            Services.deleteSpesa(props.user.username, date, currentSpesa?.insertDate)
                .then((response) => {
                    setCurrentSpesa(null)
                    retrieveOrdersData().then(() => console.log("dati recuperati"), (err) => console.log(err))
                    setShowSpese(false)
                })
                .catch((error) => {
                    setBodySpese(error.response ? error.response.data.error : "Server error.")
                    setShowSpese(true)
                })
        } else {
            setBodySpese("Non puoi eliminare questa spesa.")
            setShowSpese(true)
        }
    }

    // TABELLA CON PAGINAZIONE E RICERCA
    const columns = useMemo(
        () => [
            {
                Header: 'Articolo',
                accessor: 'article'
            },
            {
                Header: 'Tipologia',
                accessor: 'typology'
            },
            {
                Header: 'Categoria',
                accessor: 'category'
            },
            {
                Header: 'Importo',
                accessor: 'amount',
                Cell: props => <span>€ {props.value.toFixed(2)}</span>
            },
            {
                Header: 'IVA',
                accessor: 'vat',
                Cell: props => <span>€ {props.value.toFixed(2)}</span>
            },
            {
                Header: 'Pagamento',
                accessor: 'payment'
            }
        ],
        []
    )

    const memoizedSpese = useMemo(() => spese, [spese])
    const searchRef = useRef(null)

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
            searchRef.current.focus()
        }, 1000)  // millisecondi dopo cui parte la ricerca
    
        return (
            <span>
                Cerca:{' '}
                <input
                    className="form-control"
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} risultati...`}
                    ref={searchRef}
                />
            </span>
        )
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, globalFilter },
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
            columns: columns,
            data: memoizedSpese,
            initialState: { pageIndex: 0, pageSize: 5 }
        },
        //useFilters,
        useGlobalFilter,
        usePagination
    )

    // TOOLTIPS
    useEffect(() => {
        window.jQuery('[data-toggle="tooltip"]').tooltip()
    }, [])

    return (
        <div className="container-fluid">

            <div className="form-group row justify-content-center">
                <div className="col-4 d-none d-xl-inline d-lg-inline"></div>

                <div className="input-group col-xl-4 col-md-4 col-sm-8 col-12 d-flex justify-content-center">
                    <div className="input-group-prepend">
                        <button type="button" className="btn btn-primary btn-circle" onClick={handleDecrease}>
                            <i className="fas fa-arrow-left"></i>
                        </button>
                    </div>
                    <input
                        id="data"
                        type="date"
                        className="form-control form-control-user"
                        placeholder="Inserisci la data"
                        value={date}
                        onChange={onChangeDate}
                    ></input>
                    <div className="input-group-append">
                        <button type="button" className="btn btn-primary btn-circle" onClick={handleIncrease}>
                            <i className="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>

                <div className="col-4 d-none d-xl-inline d-lg-inline"></div>
            </div>
            <div className="form-group row justify-content-center">
                <div className="col-3 d-flex justify-content-center">
                    <button
                        className="btn btn-danger"
                        type="button"
                        data-toggle="modal"
                        data-target="#viewSpese"
                        data-backdrop="static"  //non si può uscire cliccando fuori
                        data-keyboard="false"   //non si può uscire premendo Esc
                        onClick={() => {
                            //mostro sempre le spese più recenti (quelle nell'ultima pagina)
                            if (pageOptions) {
                                const page = pageOptions.length ? Number(pageOptions.length) - 1 : 0
                                gotoPage(page)
                            }
                        }}
                        title="Lista spese mensili"
                    >
                        <i className="fas fa-file-invoice"></i>
                        <span className="ml-2 d-none d-lg-inline">Lista spese mensili</span>
                    </button>
                </div>

                <div className="col-3 d-flex justify-content-center">
                    <button type="button" className="btn btn-primary" onClick={dateToday} title="Mese corrente">
                        <i className="fas fa-calendar-day"></i>
                        <span className="ml-2 d-none d-lg-inline">Mese corrente</span>
                    </button>
                </div>

                <div className="col-3 d-flex justify-content-center">
                    <div className="dropdown">
                        <button
                            className="btn btn-warning dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            title="Inserimento spesa"
                        >
                            <i className="fas fa-external-link-alt"></i>
                            <span className="ml-2 d-none d-lg-inline">Inserimento spesa</span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right animated--fade-in" aria-labelledby="dropdownMenuButton">
                            <span className="dropdown-item disabled">
                                <QRCodeSVG value={`${process.env.REACT_APP_BACKEND_URL}/report/spese/${props.user?.username}/`} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-3 col-sm-6 mb-2">
                    <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        <span
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Differenza tra Fatturato mensile, Spese mensili e Versamento IVA"
                                            style={{ textDecoration: "underline 1px dotted" }}
                                        >
                                            Utile mensile
                                        </span>
                                    </div>
                                    {/* <!-- Utile mensile = fatturato mensile - spese mensili - versamento iva --> */}
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        € {(ordersData?.totalRevenue >= 0 && ordersData?.totalAmount >= 0 && ordersData?.totalImposta >= 0 && ordersData?.totalVAT >= 0)
                                            ? (ordersData.totalRevenue - ordersData.totalAmount - (ordersData.totalImposta - ordersData.totalVAT)).toFixed(2)
                                            : '0.00'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-sm-6 mb-2">
                    <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                        <span
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Differenza tra Chiusura mensile, Bancomat mensile, Spese fatturate pagate in contanti e Ordini già pagati provenienti dalle piattaforme"
                                            style={{ textDecoration: "underline 1px dotted" }}
                                        >
                                            Da depositare
                                        </span>
                                    </div>
                                    {/* <!-- Da depositare = chiusura mensile - bancomat mensile - spese fatturate pagate in contanti - piattaforme già pagati --> */}
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        € {(ordersData?.totalChiusura >= 0 && ordersData?.totalBancomat >= 0 && ordersData?.totalFiscalitaContanti >= 0 && ordersData?.platformsPagati >= 0)
                                            ? (ordersData.totalChiusura - ordersData.totalBancomat - ordersData.totalFiscalitaContanti - ordersData.platformsPagati).toFixed(2)
                                            : '0.00'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-sm-6 mb-2">
                    <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                        <span
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Totale delle spese fatturate di materie prime diviso per la Chiusura mensile (percentuale)"
                                            style={{ textDecoration: "underline 1px dotted" }}
                                        >
                                            Incidenza
                                        </span>
                                    </div>
                                    {/* <!-- Incidenza = totale spese fatturate di materie prime / totale chiusura mensile * 100 --> */}
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        {(ordersData?.totalMateriePrimeFatturate > 0 && ordersData?.totalChiusura > 0)
                                            ? (ordersData.totalMateriePrimeFatturate / ordersData.totalChiusura * 100).toFixed(2)
                                            : '0'} %
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-sm-6 mb-2">
                    <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                        <span
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Differenza tra l'Imposta mensile e l'IVA totale delle spese"
                                            style={{ textDecoration: "underline 1px dotted" }}
                                        >
                                            Versamento IVA
                                        </span>
                                    </div>
                                    {/* <!-- Versamento IVA = totale imposte mensili - totale iva in acquisto --> */}
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        € {(ordersData?.totalImposta >= 0 && ordersData?.totalVAT >= 0)
                                            ? (ordersData.totalImposta - ordersData.totalVAT).toFixed(2)
                                            : '0.00'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 mb-2">
                    <div className="card border-left-info shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center text-dark">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                                        Totali mensili
                                    </div>
                                    <span><b>Fatturato:</b> € {ordersData?.totalRevenue?.toFixed(2) ?? '0.00'}</span><br />
                                    <span><b>Chiusura:</b> € {ordersData?.totalChiusura?.toFixed(2) ?? '0.00'}</span><br />
                                    <span><b>Imposta:</b> € {ordersData?.totalImposta?.toFixed(2) ?? '0.00'}</span><br />
                                    <span><b>Bancomat:</b> € {ordersData?.totalBancomat?.toFixed(2) ?? '0.00'}</span><br />
                                    <span><b>Piattaforme già pagati:</b> € {ordersData?.platformsPagati?.toFixed(2) ?? '0.00'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 mb-2">
                    <div className="card border-left-danger shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center text-dark">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                                        Spese mensili
                                    </div>
                                    <span><b>Totale:</b> € {ordersData?.totalAmount?.toFixed(2) ?? '0.00'}</span><br />
                                    <span><b>IVA totale:</b> € {ordersData?.totalVAT?.toFixed(2) ?? '0.00'}</span><br />
                                    <span><b>Materia prima:</b> € {ordersData?.totalMateriePrime?.toFixed(2) ?? '0.00'} (di cui € {ordersData?.totalMateriePrimeFatturate?.toFixed(2) ?? '0.00'} fatturati)</span><br />
                                    <span><b>Fatturate:</b> € {ordersData?.totalFiscalita?.toFixed(2) ?? '0.00'} (di cui € {ordersData?.totalFiscalitaContanti?.toFixed(2) ?? '0.00'} in contanti)</span><br />
                                    <span><b>Locazione:</b> € {ordersData?.totalLocazione?.toFixed(2) ?? '0.00'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card shadow mb-4">
                <div className="card-body">
                    <form onSubmit={(event) => { event.preventDefault(); setTarget(event.currentTarget) }}>
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary">
                                <i className="fas fa-save mr-2"></i>
                                Salva
                            </button>
                        </div>
                        <hr />
                        {show && renderTables()}
                        <hr />
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary">
                                <i className="fas fa-save mr-2"></i>
                                Salva
                            </button>
                        </div>
                    </form>
                </div>
            </div>


            {/* <!-- Modale per inserimento spesa --> */}
            <div className="modal fade" id="viewSpese" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Lista spese mensili</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setCurrentSpesa(null)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            {showSpese ? (
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    <strong>Attenzione!</strong> {bodySpese}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setShowSpese(false)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            ) : (
                                <div></div>
                            )}

                            <h5><b><i>Inserisci / aggiorna Spesa</i></b></h5>

                            <div className="form-group row">
                                {/* NOME SPESA */}
                                <label htmlFor="article" className="d-flex justify-content-start justify-content-lg-end col-form-label col-lg-2"><b>Articolo</b></label>
                                <div className="col-lg-10">
                                    <input
                                        id="article"
                                        type="text"
                                        className={(showSpese && !currArticle) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                        placeholder="Inserire un nome per l'articolo"
                                        value={currArticle}
                                        onChange={onChangeCurrArticle}
                                        ref={articleRef}
                                    ></input>
                                </div>
                            </div>

                            <div className="form-group row">
                                {/* IMPORTO SPESA */}
                                <label htmlFor="amount" className="d-flex justify-content-start justify-content-lg-end col-form-label col-lg-2"><b>Importo</b></label>
                                <div className="col-lg-4">
                                    <input
                                        id="amount"
                                        type="text"
                                        className={(showSpese && !currAmount) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                        placeholder="Inserire l'importo della spesa"
                                        value={currAmount}
                                        onChange={onChangeCurrAmount}
                                    ></input>
                                </div>

                                {/* IVA */}
                                <label htmlFor="vat" className="d-flex justify-content-start justify-content-lg-end col-form-label col-lg-2"><b>IVA</b></label>
                                <div className="col-lg-4">
                                    <input
                                        id="vat"
                                        type="text"
                                        className={(showSpese && !currVAT) ? "form-control form-control-user highlight" : "form-control form-control-user"}
                                        placeholder="Inserire l'IVA della spesa"
                                        value={currVAT}
                                        onChange={onChangeCurrVAT}
                                    ></input>
                                </div>
                            </div>

                            <div className="form-group row">
                                {/* CATEGORIA SPESA */}
                                <label htmlFor="category" className="d-flex justify-content-start justify-content-lg-end col-form-label col-lg-2"><b>Categoria</b></label>
                                <div className="col-lg-4">
                                    {Object.entries(SpesaConstants).filter(([k, v]) => k.includes("CAT_")).map(([k, v], i) => {
                                        return (
                                            <React.Fragment key={k}>
                                                <input
                                                    type="radio"
                                                    id={k}
                                                    name="category"
                                                    defaultChecked={i === 0}
                                                    onClick={() => setCurrCategory(v)}
                                                ></input>
                                                <label htmlFor={k}>&nbsp;{v}</label><br />
                                            </React.Fragment>
                                        )
                                    })}
                                </div>

                                {/* TIPOLOGIA */}
                                <label htmlFor="typology" className="d-flex justify-content-start justify-content-lg-end col-form-label col-lg-2"><b>Tipologia</b></label>
                                <div className="col-lg-4">
                                    {Object.entries(SpesaConstants).filter(([k, v]) => k.includes("TYP_")).map(([k, v], i) => {
                                        return (
                                            <React.Fragment key={k}>
                                                <input
                                                    type="radio"
                                                    id={k}
                                                    name="typology"
                                                    defaultChecked={i === 0}
                                                    onClick={() => setCurrTypology(v)}
                                                ></input>
                                                <label htmlFor={k}>&nbsp;{v}</label><br />
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="form-group row">
                                {/* PAGAMENTO */}
                                <label htmlFor="payment" className="d-flex justify-content-start justify-content-lg-end col-form-label col-lg-2"><b>Pagamento</b></label>
                                <div className="col-lg-4">
                                    {Object.entries(SpesaConstants).filter(([k, v]) => k.includes("PAY_")).map(([k, v], i) => {
                                        return (
                                            <React.Fragment key={k}>
                                                <input
                                                    type="radio"
                                                    id={k}
                                                    name="payment"
                                                    defaultChecked={i === 0}
                                                    onClick={() => setCurrPayment(v)}
                                                ></input>
                                                <label htmlFor={k}>&nbsp;{v}</label><br />
                                            </React.Fragment>
                                        )
                                    })}
                                </div>

                                {/* BOTTONE AGGIORNA/INSERISCI */}
                                <span className="d-flex justify-content-start justify-content-lg-end col-form-label col-lg-2"></span>
                                <div className="col-lg-4">
                                    {currentSpesa ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={updateSpesa}
                                        >
                                            Aggiorna
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={insertSpesa}
                                        >
                                            Inserisci
                                        </button>
                                    )}
                                </div>
                            </div>

                            <hr />

                            <div className="table-responsive text-dark">
                                <GlobalFilter
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                                &nbsp;
                                <table className="table text-dark" {...getTableProps()} style={{ width: "100%" }}>
                                    <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th {...column.getHeaderProps()} style={{ width: "14%" }}>{column.render('Header')}</th>
                                                ))}

                                                {/* AZIONI */}
                                                <th style={{ width: "16%" }}>Azioni</th>
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row, i) => {
                                            prepareRow(row)
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    })}

                                                    {/* AZIONI */}
                                                    <td>
                                                        <button
                                                            className="btn btn-warning btn-icon btn-sm"
                                                            type="button"
                                                            onClick={() => { articleRef.current.focus(); setCurrentSpesa(memoizedSpese[row.index]) }}
                                                            title="Modifica"
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                            className="btn btn-danger btn-icon btn-sm"
                                                            type="button"
                                                            data-dismiss="modal"  //chiudo la modale delle spese...
                                                            data-toggle="modal"   //... e apro quella per la cancellazione
                                                            data-target="#modalCancellaRecord"
                                                            data-backdrop="static"  //non si può uscire cliccando fuori
                                                            data-keyboard="false"   //non si può uscire premendo Esc
                                                            onClick={() => setCurrentSpesa(memoizedSpese[row.index])}
                                                            title="Cancella"
                                                        >
                                                            <i className="fas fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {/* Pagination */}
                                <ul className="pagination">
                                    <li className="page-item" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                        <button className="page-link">Inizio</button>
                                    </li>
                                    <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        <button className="page-link">{'<'}</button>
                                    </li>
                                    <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
                                        <button className="page-link">{'>'}</button>
                                    </li>
                                    <li className="page-item" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                        <button className="page-link">Fine</button>
                                    </li>
                                    <li>
                                        <span className="page-link">
                                            Pagina{' '}
                                            <strong>
                                                {pageIndex + 1} di {pageOptions.length}
                                            </strong>{' '}
                                        </span>
                                    </li>
                                    <li>
                                        <span className="page-link">
                                            <input
                                                className="form-control"
                                                type="number"
                                                value={pageIndex ? pageIndex + 1 : 1}
                                                min={1}
                                                max={pageOptions.length}
                                                onChange={e => {
                                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                                    gotoPage(page)
                                                }}
                                                style={{ width: '100px', height: '20px' }}
                                            />
                                        </span>
                                    </li>
                                    &nbsp;
                                    <select
                                        className="form-control"
                                        value={pageSize}
                                        onChange={e => {
                                            setPageSize(Number(e.target.value))
                                        }}
                                        style={{ width: '120px', height: '38px' }}
                                    >
                                        {[5, 10, 20, 30, 40, 50].map(pageSize => (
                                            <option key={pageSize} value={pageSize}>
                                                Mostra: {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </ul>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => setCurrentSpesa(null)}
                            >
                                Chiudi
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- INIZIO MODAL CANCELLA RECORD --> */}
            <div className="modal fade" id="modalCancellaRecord" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Cancella Spesa</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setCurrentSpesa(null)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <p>Sei sicuro di voler cancellare la seguente Spesa?</p>
                            <p>
                                <span><b>Articolo:</b> {currArticle}</span><br />
                                <span><b>Tipologia:</b> {currTypology}</span><br />
                                <span><b>Categoria:</b> {currCategory}</span><br />
                                <span><b>Importo:</b> € {Number(currAmount).toFixed(2)}</span><br />
                                <span><b>IVA:</b> € {Number(currVAT).toFixed(2)}</span><br />
                                <span><b>Pagamento:</b> {currPayment}</span>
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => setCurrentSpesa(null)}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => deleteSpesa(currentSpesa.article)}
                            >
                                Cancella
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- FINE MODAL CANCELLA RECORD --> */}
        </div>
    )
}

export default Report
