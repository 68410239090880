const SpesaConstants = Object.freeze({
    CAT_MATERIAPRIMA: "MATERIA PRIMA",
    CAT_LOCAZIONE: "SPESE LOCAZIONE",
    CAT_FISCALITÀ: "FISCALITÀ",
    TYP_FATTURA: "FATTURA",
    TYP_SCONTRINO: "SCONTRINO",
    TYP_NULLO: "NULLO",
    PAY_CONTANTI: "CONTANTI",
    PAY_CARTA: "CARTA DI CREDITO",
    PAY_BONIFICO: "BONIFICO"
})

export default SpesaConstants
