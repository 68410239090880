import React from 'react'
import { QRCodeSVG } from 'qrcode.react'

import OrderConstants from '../enums/OrderConstants'

const prettyHour = (hour) => {
    return hour.includes("T") ? hour.split('T')[1].substr(0, 5) : hour
}
const prettyDistance = (distance) => {
    return (distance?.space && distance?.time) ? distance.space + ' - ' + distance.time : "Non disponibile"
}


const SummaryContainer = ({ currentOrder, user=null, includeTotal=true }) => {

    return (
        <>
            {currentOrder ? (
                <div>
                    <span><b>Numero di telefono:</b> {currentOrder.reg.phone}</span><br />
                    <span><b>Nome:</b> {currentOrder.reg.name}</span><br />
                    {currentOrder.mode === OrderConstants.MODE_RITIRO ?
                        <span><b>Ritiro</b></span>
                        :
                        <>
                            <span><b>Indirizzo:</b> {currentOrder.reg.address}</span><br />
                            <span><b>Distanza:</b> {prettyDistance(currentOrder.reg.distance)}</span>
                        </>
                    }
                    <br />
                    <span><b>Note:</b> {currentOrder.reg.notes}</span><br />
                    <span><b>Orario:</b> {prettyHour(currentOrder.hour)}</span><br /><br />

                    <span><b>Piattaforma:</b> {currentOrder.platform}</span><br />
                    <span><b>Pagamento:</b> {currentOrder.payment}</span><br /><br />

                    <span><b>Ordine:</b></span><br />
                    {currentOrder.items.map((o, index) => {
                        if (o.category !== OrderConstants.DELIVERY_COSTS) {
                            return (
                                <p key={`riepilogoordine${index}`}>
                                    {o.quantity}x {o.name} - € {o.finalPrice.toFixed(2)}
                                    <br />
                                    {o.add.map((a, ai) => {
                                        return (
                                            <span key={`aggiunta${ai}-ordine${index}`}>
                                                <span>&emsp; - Aggiunta {a.name}</span><br />
                                            </span>
                                        )
                                    })}
                                    {o.rem.map((r, ri) => {
                                        return (
                                            <span key={`rimozione${ri}-ordine${index}`}>
                                                <span>&emsp; - Senza {r.name}</span><br />
                                            </span>
                                        )
                                    })}
                                    {o.notes &&
                                        <><span><b><i>&emsp; NOTE: </i></b>{o.notes}</span><br /></>
                                    }
                                </p>
                            )
                        } else {
                            return null
                        }
                    })}
                    {(currentOrder.total > 0 && currentOrder.mode !== OrderConstants.MODE_RITIRO && currentOrder.items.find((i) => i.category === OrderConstants.DELIVERY_COSTS && i.finalPrice > 0)) &&
                        <>Spese di consegna: € {currentOrder.items.find((i) => i.category === OrderConstants.DELIVERY_COSTS)?.finalPrice?.toFixed(2)}<br /></>
                    }

                    {includeTotal && <>
                        {Number((currentOrder.total - currentOrder.items.reduce((total, value) => total + (value.finalPrice * value.quantity), 0)).toFixed(2)) !== 0 &&
                            <>Supplemento: € {(currentOrder.total - currentOrder.items.reduce((total, value) => total + (value.finalPrice * value.quantity), 0)).toFixed(2)}<br /></>
                        }
                        <br />

                        <h2>Totale: € {currentOrder.total.toFixed(2)}</h2>

                        {currentOrder.mode !== OrderConstants.MODE_RITIRO && (
                            currentOrder.driver ? <>
                                <span>
                                    <br />
                                    Partito: {currentOrder.driver.name} - {currentOrder.driver.registration.slice(11, 16)}
                                </span>
                                {currentOrder.driver.completed &&
                                    <span>
                                        <br />
                                        Consegnata: {currentOrder.driver.name} - {currentOrder.driver.completed.slice(11, 16)}
                                    </span>
                                }
                            </> : <div className="text-center">
                                <br />
                                <QRCodeSVG value={`${process.env.REACT_APP_BACKEND_URL}/driver/orders/${user?.username}/?id=${currentOrder.seq_value}`} />
                            </div>
                        )}
                    </>}
                </div>
            ) : (
                <div></div>
            )}
        </>
    )

}

export default SummaryContainer
