const axios = require('axios')

class Services {
    calculateDistance(origin, destination) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/distance/`,
            {origin: origin, destination: destination},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    calculateCoordinates(address) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/coordinates/`,
            {address: address},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }

    // METODI COLLEZIONE USERS
    signup(username, password, adminPassword) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/signup/`, {username: username, password: password, adminPassword: adminPassword})
    }
    login(username, password) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/login/`, {username: username, password: password})
    }
    refresh() {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/refresh/`,
            {},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).refreshToken}`}}
        )
    }
    updateRole(username, password) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/role/username/${username}`,
            {password: password},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    updateProfile(username, mail, password, adminPassword) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/profile/username/${username}`,
            {mail: mail, password: password, adminPassword: adminPassword},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }

    // METODI COLLEZIONE SETTINGS (impostazioni generali, delivery, dipendenti, stampanti, task, ricette)
    getSettings(username, version = "", map = false) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/settings/username/${username}?map=${map}&version=${version}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    updateGeneral(username, general) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings/general/`,
            {username: username, general: general},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    updateDelivery(username, delivery) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings/delivery/`,
            {username: username, delivery: delivery},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    updateDrivers(username, slots) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings/slots/`,
            {username: username, slots: slots},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    insertEmployees(username, employee) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings/employees/`,
            {username: username, employee: employee},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    updateEmployees(username, nome, employee) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/settings/employees/username/${username}/${nome}`,
            {employee: employee},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    deleteEmployees(username, nome) {
        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/settings/employees/username/${username}/${nome}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    insertPrinters(username, printer) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings/printers/`,
            {username: username, printer: printer},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    updatePrinters(username, nome, printer) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/settings/printers/username/${username}/${nome}`,
            {printer: printer},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    deletePrinters(username, nome) {
        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/settings/printers/username/${username}/${nome}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    insertTasks(username, task) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings/tasks/`,
            {username: username, task: task},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    updateTasks(username, task, newTask) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/settings/tasks/`,
            {username: username, task: task, newTask: newTask},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    updateTaskInEmployee(username, nome, task, operation) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/settings/employees/username/${username}/${nome}/task`,
            {task: task, operation: operation},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    insertRecipes(username, recipe) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings/recipes/`,
            {username: username, recipe: recipe},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    updateRecipes(username, nome, recipe) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/settings/recipes/username/${username}/${nome}`,
            {recipe: recipe},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    deleteRecipes(username, nome) {
        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/settings/recipes/username/${username}/${nome}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    insertCoupons(username, coupon) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/settings/coupons/`,
            {username: username, coupon: coupon},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    updateCoupons(username, nome, coupon) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/settings/coupons/username/${username}/${nome}`,
            {coupon: coupon},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    deleteCoupons(username, nome) {
        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/settings/coupons/username/${username}/${nome}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }

    // METODI COLLEZIONE MENU
    getAllMenu(username) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/menu/${username}`)
    }
    menuInsert(username, menuTitle, menuDescription, menuPrice, menuCategory, menuSubcategory, menuImage) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/menu/`,
            {username: username, title: menuTitle, description: menuDescription, price: menuPrice, category: menuCategory, subcategory: menuSubcategory, image: menuImage},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    menuUpdate(id, username, menuTitle, menuDescription, menuPrice, menuCategory, menuSubcategory, menuImage) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/menu/${id}`,
            {username: username, title: menuTitle, description: menuDescription, price: menuPrice, category: menuCategory, subcategory: menuSubcategory, image: menuImage},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    hideItemInApp(id, username, hide) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/menu/${id}/hide`,
            {username: username, hide: hide},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    menuDelete(id, username) {
        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/menu/${username}/${id}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    menuExport(username) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/menu/${username}/export`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    menuImport(username, content) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/menu/${username}/import`,
            {content: content},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }

    // METODI COLLEZIONE REG (anagrafiche clienti)
    getRegistry(username) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/registry/${username}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    getRegistryByDateRange(username, start, end) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/registry/${username}/range/${start}/${end}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    registryInsert(username, phone, nome, address, cap, notes) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/registry/`,
            {username: username, phone: phone, name: nome, address: address, cap: cap, notes: notes},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    getPromoInfo(username, phone) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/app/clients/${username}/${phone}/promo`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    savePromoInfo(username, phone, nome, address, promo) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/app/clients/${username}/reg`,
            {phone: phone, name: nome, address: address, promo: promo},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }

    // METODI COLLEZIONE ORDERS
    getOrders(username, date) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/${username}/${date}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    getClientOrders(username, phone, address) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/registry/${username}/orders/`,
            {phone: phone, address: address},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    insertOrder(username, reg, date, hour, mode, items, total, payment, platform) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/orders/`,
            {username: username, reg: reg, date: date, hour: hour, mode: mode, items: items, total: total, payment: payment, platform: platform},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    orderUpdate(id, username, reg, date, hour, mode, items, total, payment, platform) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/orders/${id}`,
            {username: username, reg: reg, date: date, hour: hour, mode: mode, items: items, total: total, payment: payment, platform: platform},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    orderRetired(id, username) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/orders/${id}/retired`,
            {username: username},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    orderDelete(id, username) {
        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/orders/${username}/${id}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    confirmAppOrder(username, order, phone, name, address) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/app/orders/confirm`,
            {username: username, order: order, phone: phone, name: name, address: address},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    getFreeDriversSlots(username, date) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/app/orders/${username}/${date}`)
    }

    // METODI PER LA DASHBOARD
    getOrdersDataCompare(username, start, end) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/${username}/range/${start}/${end}/compare`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    getOrdersData(username, start, end) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/${username}/range/${start}/${end}/dashboard`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    getRevenuesData(username, start, end, forecast = false) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/${username}/range/${start}/${end}/revenues?forecast=${forecast}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    getDriversData(username, start, end) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/${username}/range/${start}/${end}/drivers`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    getOrdersByDateRange(username, start, end) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/${username}/range/${start}/${end}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    getOtherOrdersData(username) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/${username}/dashboard`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    getOtherDriversData(username) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/orders/${username}/drivers`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }

    // METODI COLLEZIONE REPORT (recupero dati e gestione spese)
    getDataForReport(username, start, end) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/report/${username}/range/${start}/${end}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    updateReport(username, month, data) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/report/`,
            {username: username, month: month, data: data},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    insertSpesa(username, spesa, month) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/report/spese/`,
            {username: username, spesa: spesa, month: month},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    updateSpesa(username, spesa, month) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/report/spese/`,
            {username: username, spesa: spesa, month: month},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    deleteSpesa(username, month, key) {
        return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/report/spese/${username}/${month}/${key}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    updateSpesaStipendi(username, amount, month) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/report/spese/stipendi/`,
            {username: username, amount: amount, month: month},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }

    // METODI GESTIONE DIPENDENTI E CHIAMATE
    resign(username, data) {
        return axios.put(`${process.env.REACT_APP_BACKEND_URL}/employees/resign/${username}/`,
            data,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    getEmployeesData(username, month) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/employees/${username}/data/${month}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    generateXML(username, annullamento, employees) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/employees/call/${username}/`,
            {annullamento: annullamento, employees: employees},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    sendMail(username, month, attachment, annullamento, call) {
        return axios.post(`${process.env.REACT_APP_BACKEND_URL}/employees/send/${username}/`,
            {month: month, attachment: attachment, annullamento: annullamento, call: call},
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }

    // ALTRE COLLEZIONI (ringings, messages)
    getRingingPhone(username) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/ringings/${username}/`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
    getMessages(receiver, date) {
        return axios.get(`${process.env.REACT_APP_BACKEND_URL}/app/messages/${receiver}/${date}`,
            {headers: {Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).accessToken}`}}
        )
    }
}

export default new Services()
