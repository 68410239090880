import React, { useEffect, useState, useRef } from 'react'
import { QRCodeSVG } from 'qrcode.react'

import Services from '../services.js'

const moment = require('moment')
const days = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']
/* const calculateStartDay = () => {
    const week = Math.floor(moment().date()/7)
    if (week === moment().date()/7) {
        return week * 7 - 6
    } else {
        return week * 7 + 1
    }
} */


const Employees = (props) => {
    const [date, setDate] = useState(moment().format('YYYY-MM-01'))
    // const [startDay, setStartDay] = useState(moment().date(calculateStartDay()).format('YYYY-MM-DD'))
    // const [weekDays, setWeekDays] = useState([])
    const [employeesData, setEmployeesData] = useState({})
    const [callData, setCallData] = useState({})
    const [totals, setTotals] = useState({})
    const [totalPay, setTotalPay] = useState(0.0)
    const [employees, setEmployees] = useState([])
    // const [actualEmployees, setActualEmployees] = useState([])
    const [show, setShow] = useState(false)
    const [disabled, setDisabled] = useState(true)

    const onChangeDate = (e) => {
        const date = e.target.value
        if (date) {
            const newDate = moment(date).format('YYYY-MM-01')
            setDate(newDate)
            // setStartDay(newDate)
        }
    }
    const handleIncrease = () => {
        if (date) {
            const newDate = moment(date).add(1, 'M').format('YYYY-MM-01')
            setDate(newDate)
            // setStartDay(newDate)
        }
    }
    const handleDecrease = () => {
        if (date) {
            const newDate = moment(date).subtract(1, 'M').format('YYYY-MM-01')
            setDate(newDate)
            // setStartDay(newDate)
        }
    }
    /* const handleIncreaseStartDay = () => {
        if (startDay) {
            const newDate = moment(startDay).add(7, 'd')
            if (newDate.month() !== moment(date).month()) {
                setDate(newDate.format('YYYY-MM-01'))
                setStartDay(newDate.format('YYYY-MM-01'))
            } else {
                setStartDay(newDate.format('YYYY-MM-DD'))
            }
        }
    }
    const handleDecreaseStartDay = () => {
        if (startDay) {
            const newDate = moment(startDay).subtract(7, 'd')
            if (newDate.month() === moment(date).month()) {
                setStartDay(newDate.format('YYYY-MM-DD'))
            }
        }
    } */
    const dateToday = () => {
        /* if (date !== moment().format('YYYY-MM-01') || (date === moment().format('YYYY-MM-01') && startDay !== moment().date(calculateStartDay()).format('YYYY-MM-DD'))) {
            setDate(moment().format('YYYY-MM-01'))
            setStartDay(moment().date(calculateStartDay()).format('YYYY-MM-DD'))  //settimana corrente
        } */
        if (date !== moment().format('YYYY-MM-01')) {
            setDate(moment().format('YYYY-MM-01'))
        } else {
            retrieveEmployeesData().then(() => {
                console.log("dati recuperati")
            }, (err) => console.log(err))
            retrieveEmployees().then(() => {
                console.log("dipendenti recuperati")
            }, (err) => console.log(err))
        }
    }

    async function retrieveEmployeesData() {
        setShow(false)
        if (props.user?.username && date) {
            return Services.getEmployeesData(props.user.username, date)
                .then((response) => {
                    // console.log(response.data)
                    const employeesData = response.data.employeesData
                    const callData = response.data.callData

                    let allEmployeesData = {}
                    let totals = {}
                    for (let d in employeesData) {
                        allEmployeesData[d] = {}
                        for (let e in employeesData[d]) {
                            allEmployeesData[d][e] = employeesData[d][e]

                            if (totals[e] && employeesData[d][e]?.total) {
                                totals[e] += parseFloat(employeesData[d][e].total.toFixed(2))
                            } else if (!totals[e] && employeesData[d][e]?.total) {
                                totals[e] = parseFloat(employeesData[d][e].total.toFixed(2))
                            } /* else {
                                // RETROCOMPATIBILITA'
                                const start = moment(employeesData[d][e].start, "HH:mm")
                                const end = moment(employeesData[d][e].end, "HH:mm")
                                const diff = end.diff(start, "hours", true)
    
                                if (totals[e]) {
                                    totals[e] += parseFloat((employeesData[d][e].pay * diff).toFixed(2))
                                } else {
                                    totals[e] = parseFloat((employeesData[d][e].pay * diff).toFixed(2))
                                }
                                if (employeesData[d][e].repayment) {
                                    totals[e] += parseFloat((employeesData[d][e].deliveries * employeesData[d][e].repayment).toFixed(2))
                                }
                            } */
                        }
                    }
                    setTotals(totals)

                    let sum = 0.0
                    for (let t in totals) {
                        sum += totals[t]
                    }
                    setTotalPay(sum)

                    let callDataByEmpl = {}
                    for (let d in callData) {
                        if (!(d in allEmployeesData)) {
                            allEmployeesData[d] = {}
                        }

                        // array date chiamate (per ogni cliente chiamato)
                        for (let e in callData[d].chiamati) {
                            if (!(callData[d].chiamati[e] in allEmployeesData[d])) {
                                allEmployeesData[d][callData[d].chiamati[e]] = {}
                            }

                            const prev = callDataByEmpl[callData[d].chiamati[e]]?.chiamati ?? []

                            if (callDataByEmpl[callData[d].chiamati[e]])
                                callDataByEmpl[callData[d].chiamati[e]].chiamati = [...prev, d]
                            else
                                callDataByEmpl[callData[d].chiamati[e]] = {chiamati: [...prev, d]}
                        }

                        // array date annullate (per ogni cliente annullato)
                        for (let e in callData[d].annullati) {
                            if (!(callData[d].annullati[e] in allEmployeesData[d])) {
                                allEmployeesData[d][callData[d].annullati[e]] = {}
                            }

                            const prev = callDataByEmpl[callData[d].annullati[e]]?.annullati ?? []

                            if (callDataByEmpl[callData[d].annullati[e]])
                                callDataByEmpl[callData[d].annullati[e]].annullati = [...prev, d]
                            else
                                callDataByEmpl[callData[d].annullati[e]] = {annullati: [...prev, d]}
                        }
                    }
                    // console.log(callDataByEmpl, allEmployeesData)
                    setCallData(callDataByEmpl)
                    setEmployeesData(allEmployeesData)

                    setShow(true)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    useEffect(() => {
        retrieveEmployeesData().then(() => {
            console.log("dati recuperati")
        }, (err) => console.log(err))
    }, [date])

    async function retrieveEmployees() {
        if (props.user.username) {
            return Services.getSettings(props.user.username)
                .then((response) => {
                    setEmployees(response.data.settings?.employees ?? [])
                    if (response.data.settings?.general?.fiscalCode && response.data.settings?.general?.dest
                        && response.data.settings?.general?.mail && response.data.settings?.general?.password) {
                        setDisabled(false)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setEmployees([])
                    setDisabled(true)
                })
        } else {
            setEmployees([])
            setDisabled(true)
        }
    }

    useEffect(() => {
        retrieveEmployees().then(() => {
            console.log("dipendenti recuperati")
        }, (err) => console.log(err))
    }, [])

    /* // GIORNI DELLA SETTIMANA CORRENTE
    useEffect(() => {
        let weekDays = []
        for (let d = 0; d < 7; d++) {
            const newDay = moment(startDay).add(d, 'd')
            if (newDay.month() !== moment(date).month()) {  //esco se è finito il mese
                break
            }
            weekDays.push(newDay)
        }
        setWeekDays(weekDays)
    }, [startDay]) */

    // SCHEDE CON I TOTALI DEL MESE PER OGNI DIPENDENTE
    const renderTotals = () => {
        let render = []
        for (let t in totals) {
            const cardId = t.replaceAll(" ", "_")
            render.push(
                <div key={`${t}`} className="col-xl-2 col-md-4 col-sm-6 mb-2">
                    <div className="card border-left-warning shadow driverCard">
                        <a
                            href={`#${cardId}`}
                            className="d-block card-header py-3"
                            data-toggle="collapse"
                            role="button"
                            aria-expanded="true"
                            aria-controls="collapseCardExample"
                        >
                            <h6 className="m-0 font-weight-bold text-warning">{t}</h6>
                        </a>
                        <div className="card-body collapse" id={cardId}>
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        € {totals[t].toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return render
    }

    // MODIFICA ORARI DIPENDENTE
    const [currentStart, setCurrentStart] = useState("00:00")
    const [currentEnd, setCurrentEnd] = useState("00:00")
    const [currentDeliveries, setCurrentDeliveries] = useState(0)
    const [currentDate, setCurrentDate] = useState("")
    const [currentNome, setCurrentNome] = useState("")
    const [currentEntry, setCurrentEntry] = useState(null)

    const onChangeCurrentStart = (e) => {
        const start = e.target.value
        setCurrentStart(start)
    }
    const onChangeCurrentEnd = (e) => {
        const end = e.target.value
        setCurrentEnd(end)
    }
    const onChangeCurrentDeliveries = (e) => {
        const deliveries = e.target.value
        setCurrentDeliveries(deliveries)
    }

    useEffect(() => {
        if (currentEntry && currentDate && currentNome) {
            setCurrentStart(currentEntry?.start ?? "00:00")
            setCurrentEnd(currentEntry?.end ?? "00:00")
            setCurrentDeliveries(currentEntry?.deliveries ?? 0)
        } else {
            setCurrentStart("")
            setCurrentEnd("")
            setCurrentDeliveries(0)
        }
    }, [currentEntry])

    const resign = () => {
        if (props.user.username && currentStart && currentEnd) {
            const data = {
                employee: currentNome,
                date: currentDate,
                start: currentStart,
                end: currentEnd,
                deliveries: currentDeliveries
            }
            Services.resign(props.user.username, data)
                .then((response) => {
                    retrieveEmployeesData().then(() => {
                        console.log("dati recuperati")
                    }, (err) => console.log(err))
                })
                .catch((error) => {
                    console.log(error.response?.data?.error)
                })
        } else {
            console.log("Non hai riempito i campi obbligatori.")
        }
        setCurrentDate("")
        setCurrentNome("")
        setCurrentEntry(null)
    }

    // CHIAMATA INTERMITTENTI
    const [annullamento, setAnnullamento] = useState(0)
    const [call, setCall] = useState({})
    const [hiddenDateInt, setHiddenDateInt] = useState(moment().format('YYYY-MM-DD'))
    const [dateInt, setDateInt] = useState(null)
    const [target, setTarget] = useState(null)
    const [xmlData, setXmlData] = useState(null)
    const [xmlDataEncoded, setXmlDataEncoded] = useState(null)
    const [xmlDataParsed, setXmlDataParsed] = useState(null)
    const generaRef = useRef(null)
    const inviaRef = useRef(null)
    const saveFileRef = useRef(null)

    const onChangeAnnullamento = () => {
        setAnnullamento(1-annullamento)
    }
    const handleIncreaseHiddenDateInt = () => {
        if (hiddenDateInt) {
            const newDate = moment(hiddenDateInt).add(1, 'd').format('YYYY-MM-DD')
            setHiddenDateInt(newDate)
        }
    }
    const handleDecreaseHiddenDateInt = () => {
        if (hiddenDateInt) {
            const newDate = moment(hiddenDateInt).subtract(1, 'd').format('YYYY-MM-DD')
            setHiddenDateInt(newDate)
        }
    }

    const renderEmployeesList = () => {
        let render = []
        for(let e in employees) {
            if (employees[e].fiscalCode && employees[e].codComunicazione) {
                render.push(
                    <div key={`${dateInt}#${employees[e].name}`} className="row form-group justify-content-start">
                        <div className="col-4"></div>
                        <div className="col-8">
                            <div className="custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name={`${dateInt}#${employees[e].name}`}
                                    id={`${dateInt}#${employees[e].name}`}
                                    defaultChecked={call[dateInt] && call[dateInt].includes(employees[e].name)}
                                ></input>
                                <label className="custom-control-label" htmlFor={`${dateInt}#${employees[e].name}`}>{employees[e].name}</label>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        return render
    }

    const generateXML = (call) => {
        if (props.user.username) {
            let callData = []
            for (let d in call) {
                for (let i in call[d]) {
                    const empl = employees.filter((value) => {
                        return value.name === call[d][i]
                    })
                    if (empl.length === 1 && empl[0].fiscalCode && empl[0].codComunicazione) {
                        callData.push({
                            cf: empl[0].fiscalCode,
                            cc: empl[0].codComunicazione,
                            dt_start: moment(d).format('DD/MM/YYYY'),
                            dt_end: moment(d).format('DD/MM/YYYY')
                        })
                    }
                }
            }

            Services.generateXML(props.user.username, String(annullamento), callData)
                .then((response) => {
                    console.log(response.data.call)

                    // PARSING
                    const parser = new DOMParser()
                    const xmlDoc = parser.parseFromString(response.data.call, "text/xml")
                    setXmlData(xmlDoc)
                    setXmlDataEncoded(response.data.base64)
                })
                .catch((error) => {
                    console.log(error.response?.data?.error)
                })
        }
    }

    const renderXML = () => {
        let render = []
        if (xmlDataParsed) {
            let i = 5  // parto dal sesto figlio di <Campi>
            while (i < xmlDataParsed.length) {
                render.push(
                    <tr key={`lavoratore-${i}`}>
                        <td>{xmlDataParsed[i]?.textContent}</td>
                        <td>{xmlDataParsed[i+1]?.textContent}</td>
                        <td>{xmlDataParsed[i+2]?.textContent}</td>
                        <td>{xmlDataParsed[i+3]?.textContent}</td>
                    </tr>
                )
                i += 4
            }
        }
        return render
    }

    useEffect(() => {
        if (xmlData && xmlData.getElementsByTagName("Campi")[0]) {
            const childs = []
            for (let c in [...xmlData.getElementsByTagName("Campi")[0].childNodes]) {
                if (xmlData.getElementsByTagName("Campi")[0].childNodes[c].nodeName !== "#text") {
                    childs.push(xmlData.getElementsByTagName("Campi")[0].childNodes[c])  // creo un array con i soli tag XML
                }
            }
            setXmlDataParsed(childs)
        }
    }, [xmlData])

    // gestione evento onSubmit
    useEffect(() => {
        if (target) {
            const formData = new FormData(target)
            for (let [key, value] of formData.entries()) {
                if (value === "on") {
                    const i = key.split('#')[0]  // data
                    const j = key.split('#')[1]  // nome

                    // la chiave è la data
                    if (call[i] && !call[i].includes(j)) {
                        call[i] = [...call[i], j]
                    } else {
                        call[i] = [j]
                    }
                }
            }
            console.log(call)

            setTarget(null)

            // triggero renderEmployeesList cambiando la data
            setDateInt(hiddenDateInt)
            
            // se hiddenDateInt è uguale a dateInt invio i dati al server e chiudo la modale (tramite un ref sul pulsante chiudi)
            /* if (hiddenDateInt === dateInt) {
                generateXML(call)
                saveRef.current.click()

                setDateInt(null)
            } */
            generateXML(call)
        }
    }, [target])

    const sendMail = () => {
        if (props.user?.username && date) {
            Services.sendMail(props.user.username, date, xmlDataEncoded, String(annullamento), call)
                    .then((response) => {
                        retrieveEmployeesData().then(() => {
                            console.log("dati recuperati")
                        }, (err) => console.log(err))
                        
                        setAnnullamento(0)
                        setCall({})
                        setDateInt(null)
                        setXmlData(null)
                        setXmlDataEncoded(null)
                        setXmlDataParsed(null)
                        generaRef.current.click()

                        alert(response.data.message)
                    })
                    .catch((error) => {
                        if (error.response?.data?.error) {
                            alert(error.response?.data?.error)
                        } else {
                            alert("ATTENZIONE! Non è stato possibile inviare la mail.")
                        }
                    })
        } else {
            alert("ATTENZIONE! Informazioni errate.")
        }
    }

    const renderCalls = () => {
        let render = []
        for (let e in callData) {
            const empl = employees.filter((value) => {
                return value.name === e
            })[0]

            if (callData[e].chiamati) {
                render.push(
                    <tr key={`${e}`}>
                        <td>{empl ? empl.fiscalCode : "Dipendente eliminato ("+e+")"}</td>
                        <td>Chiamate effettuate</td>
                        <td style={{ whiteSpace: "pre-line" }}>
                            {callData[e].chiamati.reduce((res, value) => {
                                return res + "\n" + value
                            })}
                        </td>
                    </tr>
                )
            }
            if (callData[e].annullati) {
                render.push(
                    <tr key={`${e}`}>
                        <td>{empl ? empl.fiscalCode : "Dipendente eliminato ("+e+")"}</td>
                        <td>Chiamate annullate</td>
                        <td style={{ whiteSpace: "pre-line" }}>
                            {callData[e].annullati.reduce((res, value) => {
                                return res + "\n" + value
                            })}
                        </td>
                    </tr>
                )
            }
        }
        return render
    }

    const callsToFile = () => {
        let content = "CF lavoratore;Tipo chiamata;Giorni chiamati\n;;\n"
        for (let e in callData) {
            const empl = employees.filter((value) => {
                return value.name === e
            })[0]

            if (callData[e].chiamati) {
                const dates = callData[e].chiamati.reduce((res, value) => {
                    return res + ";" + value
                })
                content += `${empl ? empl.fiscalCode : "Dipendente eliminato ("+e+")"};Chiamate effettuate;${dates}\n`
            }
            if (callData[e].annullati) {
                const dates = callData[e].annullati.reduce((res, value) => {
                    return res + ";" + value
                })
                content += `${empl ? empl.fiscalCode : "Dipendente eliminato ("+e+")"};Chiamate annullate;${dates}\n`
            }
        }
        return content
    }

    const renderTables = () => {
        let render = []

        let i = moment(date).daysInMonth() - 1
        while (i >= 0) {
            const d = moment(date).add(i, 'd')
            const ed = employeesData[d.format('YYYY-MM-DD')]
            if (ed) {
                const keys = Object.keys(ed)
                const values = Object.values(ed)

                render.push(
                    <div className="table-responsive" key={d.format('YYYY-MM-DD')}>
                        <table className="table table-bordered text-dark text-center table-sm">
                            <tbody>
                                <tr>
                                    <th style={{ border: "none" }}></th>
                                    {values.map((e, i) => {
                                        return (
                                            <th className="table-active" key={`${keys[i]}-${d.format('DD/MM/YYYY')}-name`}>
                                                {/* {keys[i]} */}
                                                <input
                                                    className="form-control form-control-user text-center font-weight-bold text-truncate"
                                                    type="text"
                                                    style={{ minWidth: "150px" }}
                                                    defaultValue={keys[i]}
                                                    title={keys[i]}
                                                    disabled
                                                ></input>
                                            </th>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <th className="table-secondary text-right" style={{ width: "100px" }}>{days[d.day()]} - {d.format('DD/MM/YYYY')}</th>
                                    {values.map((e, i) => {
                                        return (
                                            <td key={`${keys[i]}-${d.format('DD/MM/YYYY')}-update`}>
                                                <button
                                                    className="btn btn-link btn-icon btn-sm"
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#resignModal"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    onClick={() => {
                                                        setCurrentDate(d.format('YYYY-MM-DD'))
                                                        setCurrentNome(keys[i])
                                                        setCurrentEntry(e)
                                                    }}
                                                    title="Modifica"
                                                >
                                                    <i className="fa fa-edit"></i>
                                                </button>
                                                {callData[keys[i]]?.chiamati?.includes(d.format('YYYY-MM-DD')) ? (
                                                    <>
                                                        &nbsp;
                                                        <button
                                                            className="btn btn-success btn-icon btn-sm btn-circle"
                                                            disabled
                                                            title="Chiamata effettuata"
                                                        >
                                                            <i className="fas fa-id-badge"></i>
                                                        </button>
                                                    </>
                                                ) : (
                                                    callData[keys[i]]?.annullati?.includes(d.format('YYYY-MM-DD')) ? (
                                                        <>
                                                            &nbsp;
                                                            <button
                                                                className="btn btn-danger btn-icon btn-sm btn-circle"
                                                                disabled
                                                                title="Chiamata annullata"
                                                            >
                                                                <i className="fas fa-id-badge"></i>
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <th className="table-active text-right">Ora inizio</th>
                                    {values.map((e, i) => {
                                        return (
                                            <td key={`${keys[i]}-${d.format('DD/MM/YYYY')}-start`}>{e?.start}</td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <th className="table-active text-right">Ora fine</th>
                                    {values.map((e, i) => {
                                        return (
                                            <td key={`${keys[i]}-${d.format('DD/MM/YYYY')}-end`}>{e?.end}</td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <th className="table-active text-right">N° consegne</th>
                                    {values.map((e, i) => {
                                        return (
                                            <td key={`${keys[i]}-${d.format('DD/MM/YYYY')}-deliveries`}>{e?.deliveries}</td>
                                        )
                                    })}
                                </tr>
                                <tr>
                                    <th className="table-active text-right">Totale</th>
                                    {values.map((e, i) => {
                                        return (
                                            <td key={`${keys[i]}-${d.format('DD/MM/YYYY')}-total`}>€ {e?.total?.toFixed(2)}</td>
                                        )
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )
            }
            i--
        }

        return render
    }

    return (
        <div className="container-fluid">

            <div className="form-group row justify-content-center">
                <div className="col-4 d-none d-xl-inline d-lg-inline"></div>

                <div className="input-group col-xl-4 col-md-4 col-sm-8 col-12 d-flex justify-content-center">
                    <div className="input-group-prepend">
                        <button type="button" className="btn btn-primary btn-circle" onClick={handleDecrease}>
                            <i className="fas fa-arrow-left"></i>
                        </button>
                    </div>
                    <input
                        id="data"
                        type="date"
                        className="form-control form-control-user"
                        placeholder="Inserisci la data"
                        value={date}
                        onChange={onChangeDate}
                    ></input>
                    <div className="input-group-append">
                        <button type="button" className="btn btn-primary btn-circle" onClick={handleIncrease}>
                            <i className="fas fa-arrow-right"></i>
                        </button>
                    </div>
                </div>

                <div className="col-4 d-none d-xl-inline d-lg-inline"></div>
            </div>
            <div className="form-group row justify-content-center">
                <div className="col-3 d-flex justify-content-center">
                    <button
                        className="btn btn-success"
                        type="button"
                        data-toggle="modal"
                        data-target="#viewCall"
                        data-backdrop="static"  //non si può uscire cliccando fuori
                        data-keyboard="false"   //non si può uscire premendo Esc
                        onClick={() => { setHiddenDateInt(moment().format('YYYY-MM-DD')); setDateInt(moment().format('YYYY-MM-DD')) }}
                        title="Nuova chiamata"
                        disabled={disabled}
                    >
                        <i className="fas fa-id-badge"></i>
                        <span className="ml-2 d-none d-lg-inline">Nuova chiamata</span>
                    </button>
                </div>

                <div className="col-3 d-flex justify-content-center">
                    <button type="button" className="btn btn-primary" onClick={dateToday} title="Mese corrente">
                        <i className="fas fa-calendar-day"></i>
                        <span className="ml-2 d-none d-lg-inline">Mese corrente</span>
                    </button>
                </div>

                <div className="col-3 d-flex justify-content-center">
                    <div className="dropdown">
                        <button
                            className="btn btn-warning dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            title="Gestione dipendenti"
                        >
                            <i className="fas fa-external-link-alt"></i>
                            <span className="ml-2 d-none d-lg-inline">Gestione dipendenti</span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right animated--fade-in" aria-labelledby="dropdownMenuButton">
                            <span className="dropdown-item disabled">
                                <QRCodeSVG value={`${process.env.REACT_APP_BACKEND_URL}/employees/sign/${props.user?.username}/`} />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-2 col-md-4 col-sm-6 mb-2">
                    <div className="card border-left-danger shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                                        Stipendi
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">
                                        € {totalPay?.toFixed(2) ?? '0.00'}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <button
                                        className="btn btn-link rounded-circle btn-sm"
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#employeesModal"
                                        title="Riepilogo chiamate"
                                    >
                                        <i className="fa fa-list-ul" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {show && renderTotals()}
            </div>

            <div className="card shadow mb-4">
                <div className="card-body">
                    {/* <div className="row">
                        <div className="col-6 d-flex justify-content-start">
                            <button type="button" className="btn btn-primary btn-circle" onClick={handleDecreaseStartDay}>
                                <i className="fas fa-arrow-left"></i>
                            </button>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            <button type="button" className="btn btn-primary btn-circle" onClick={handleIncreaseStartDay}>
                                <i className="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                    <hr /> */}

                    {show && renderTables()}

                    {/* <hr />
                    <div className="row">
                        <div className="col-6 d-flex justify-content-start">
                            <button type="button" className="btn btn-primary btn-circle" onClick={handleDecreaseStartDay}>
                                <i className="fas fa-arrow-left"></i>
                            </button>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            <button type="button" className="btn btn-primary btn-circle" onClick={handleIncreaseStartDay}>
                                <i className="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>

            {/* <!-- Modale per modifica orari dipendente --> */}
            <div className="modal fade" id="resignModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Modifica Orari di {currentNome} ({currentDate})</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setCurrentDate("")
                                    setCurrentNome("")
                                    setCurrentEntry(null)
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div className="form-group row">
                                <label className="col-4 d-flex justify-content-start justify-content-md-end col-form-label" htmlFor="start"><b>Ora inizio</b></label>
                                <div className="col-8">
                                    <input
                                        type="time"
                                        id="start"
                                        name="start"
                                        className="form-control form-control-user"
                                        value={currentStart}
                                        onChange={onChangeCurrentStart}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-4 d-flex justify-content-start justify-content-md-end col-form-label" htmlFor="end"><b>Ora fine</b></label>
                                <div className="col-8">
                                    <input
                                        type="time"
                                        id="end"
                                        name="end"
                                        className="form-control form-control-user"
                                        value={currentEnd}
                                        onChange={onChangeCurrentEnd}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-4 d-flex justify-content-start justify-content-md-end col-form-label" htmlFor="deliveries"><b>Numero consegne</b></label>
                                <div className="col-8">
                                    <input
                                        type="number"
                                        id="deliveries"
                                        name="deliveries"
                                        className="form-control form-control-user"
                                        min={0}
                                        value={currentDeliveries}
                                        onChange={onChangeCurrentDeliveries}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {
                                    setCurrentDate("")
                                    setCurrentNome("")
                                    setCurrentEntry(null)
                                }}
                            >
                                Chiudi
                            </button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={resign}>Salva</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modale per inserimento chiamata dipendenti --> */}
            <div className="modal fade" id="viewCall" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Effettua una nuova chiamata dipendenti intermittenti</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    setAnnullamento(0)
                                    setCall({})
                                    setDateInt(null)
                                    setXmlData(null)
                                    setXmlDataEncoded(null)
                                    setXmlDataParsed(null)
                                    generaRef.current.click()
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    id="genera-tab"
                                    data-toggle="tab"
                                    href="#genera"
                                    role="tab"
                                    aria-controls="genera"
                                    aria-selected="true"
                                    ref={generaRef}
                                >
                                    Scelta dipendenti
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    id="invia-tab"
                                    data-toggle="tab"
                                    href="#invia"
                                    role="tab"
                                    aria-controls="invia"
                                    aria-selected="false"
                                    ref={inviaRef}
                                    style={{pointerEvents: xmlDataParsed ? "auto" : "none"}}
                                >
                                    Comunicazione generata
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="genera" role="tabpanel" aria-labelledby="genera-tab">
                                {/* Form HTML per interpretare i dati come chiave-valore */}
                                {/* https://stackoverflow.com/questions/23427384/get-form-data-in-reactjs */}
                                <form onSubmit={(event) => { event.preventDefault(); setTarget(event.currentTarget) }}>
                                    <div className="modal-body container-fluid text-dark">
                                        <div className="row form-group justify-content-center">
                                            <div className="col-sm-6 input-group">
                                                <div className="input-group-prepend">
                                                    <button type="submit" className="btn btn-primary btn-circle" onClick={handleDecreaseHiddenDateInt}>
                                                        <i className="fas fa-arrow-left"></i>
                                                    </button>
                                                </div>
                                                <input
                                                    type="date"
                                                    className="form-control form-control-user"
                                                    value={dateInt ?? moment().format('YYYY-MM-DD')}
                                                    disabled
                                                ></input>
                                                <div className="input-group-append">
                                                    <button type="submit" className="btn btn-primary btn-circle" onClick={handleIncreaseHiddenDateInt}>
                                                        <i className="fas fa-arrow-right"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* ANNULLAMENTO */}
                                        <div className="row form-group justify-content-center">
                                            <div className="col-4"></div>
                                            <div className="col-8">
                                                <input
                                                    type="checkbox"
                                                    className="ml-2"
                                                    id="annullamento"
                                                    checked={annullamento}
                                                    onChange={onChangeAnnullamento}
                                                ></input>
                                                <label className="ml-3" htmlFor="annullamento">Annullamento</label>
                                            </div>
                                        </div>
                                        <br/>
                                        {renderEmployeesList()}
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={() => inviaRef.current.click()}
                                        >
                                            Genera comunicazione
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div className="tab-pane fade" id="invia" role="tabpanel" aria-labelledby="invia-tab">
                                <div className="modal-body container-fluid text-dark">
                                    <div>
                                        {/* primo, quarto e quinto figlio di <Campi> */}
                                        <span><b>CF datore di lavoro: </b>{xmlDataParsed ? xmlDataParsed[0]?.textContent : ""}</span><br/>
                                        <span><b>Email datore di lavoro: </b>{xmlDataParsed ? xmlDataParsed[3]?.textContent : ""}</span><br/>
                                        <span><b>Annullamento: </b>{xmlDataParsed ? xmlDataParsed[4]?.textContent : ""}</span>
                                    </div>
                                    &nbsp;
                                    <div className="table-responsive">
                                        <table className="table text-dark" id="dataTable" width="100%">
                                            <thead>
                                                <tr>
                                                    <th>CF lavoratore</th>
                                                    <th>Codice comunicazione</th>
                                                    <th>Data inizio</th>
                                                    <th>Data fine</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderXML()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                        onClick={() => {
                                            setAnnullamento(0)
                                            setCall({})
                                            setDateInt(null)
                                            setXmlData(null)
                                            setXmlDataEncoded(null)
                                            setXmlDataParsed(null)
                                            generaRef.current.click()
                                        }}
                                    >
                                        Annulla
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-dismiss="modal"
                                        onClick={sendMail}
                                    >
                                        Invia comunicazione
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="modal fade" id="modalRiepilogo" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Invia chiamata dipendenti intermittenti</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { setAnnullamento(0); setCall({}); setXmlData(null) }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div>
                                <span><b>CF datore di lavoro: </b>{xmlDataParsed ? xmlDataParsed[0]?.textContent : ""}</span><br/>
                                <span><b>Email datore di lavoro: </b>{xmlDataParsed ? xmlDataParsed[3]?.textContent : ""}</span><br/>
                                <span><b>Annullamento: </b>{xmlDataParsed ? xmlDataParsed[4]?.textContent : ""}</span>
                            </div>
                            &nbsp;
                            <div className="table-responsive">
                                <table className="table text-dark" id="dataTable" width="100%">
                                    <thead>
                                        <tr>
                                            <th>CF lavoratore</th>
                                            <th>Codice comunicazione</th>
                                            <th>Data inizio</th>
                                            <th>Data fine</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderXML()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => { setAnnullamento(0); setCall({}); setXmlData(null) }}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={sendMail}
                            >
                                Invia comunicazione
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <!-- Modal Riepilogo chiamate del mese --> */}
            <div className="modal fade" id="employeesModal" tabIndex="-1" role="dialog" aria-labelledby="ordersModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="ordersModalLabel">Riepilogo chiamate del mese</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div className="table-responsive">
                                <table className="table text-dark">
                                    <thead>
                                        <tr>
                                            <th>CF lavoratore</th>
                                            <th>Tipo chiamata</th>
                                            <th>Giorni chiamati</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderCalls()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a
                                href="data:text/plain;charset=utf-8,"
                                id="link"
                                className="btn btn-warning"
                                download={`Riepilogo chiamate ${moment(date).format('YYYY-MM')}.csv`}
                                onClick={() => {
                                    document.getElementById('link').href = "data:text/plain;charset=utf-8," + encodeURIComponent(callsToFile())
                                    saveFileRef.current.click()
                                }}
                            >
                                <i className="fas fa-file-download mr-2"></i>
                                Scarica
                            </a>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                ref={saveFileRef}
                            >
                                Chiudi
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Employees
