import React, { useEffect, useState } from 'react'

import SummaryContainer from './SummaryContainer'

import Services from '../services.js'
import ItemCategories from '../enums/ItemCategories'
import OrderConstants from '../enums/OrderConstants'

const moment = require('moment')
const prettyHour = (hour) => {
    return hour.split('T')[1].substr(0, 5)
}


const Orders = (props) => {
    const [orders, setOrders] = useState([])
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const [fatturato, setFatturato] = useState(0.0)
    const [daPagare, setDaPagare] = useState(0.0)
    const [pagati, setPagati] = useState(0.0)
    const [bancomat, setBancomat] = useState(0.0)
    const [platforms, setPlatforms] = useState({})
    const [piatti, setPiatti] = useState(0)
    const [newClients, setNewClients] = useState(0)
    const [returnedClients, setReturnedClients] = useState(0)
    const [driversPayments, setDriversPayments] = useState({})
    const [toDeliver, setToDeliver] = useState([])
    const [score, setScore] = useState(0)
    const [modality, setModality] = useState({cons: 0, rit: 0})
    const [triggerOrdersUpdate, setTriggerOrdersUpdate] = useState(0)

    const calculateScore = (hour, completed) => {
        const d1 = new Date(completed)
        const d2 = new Date(hour)
        var diff = d1 - d2
        var minutes = Math.round(diff / 60000)
        if (minutes <= 5) {
            return 10
        } else if (minutes > 5 && minutes < 15) {
            return 6
        } else if (minutes >= 15) {
            return 1
        }
    }

    async function retrieveOrders(todayOrders = null) {
        console.log("ordini recuperati")
        let orders = []
        if (todayOrders) {
            orders = todayOrders
        } else if (props.user.username && date) {
            orders = await Services.getOrders(props.user.username, date)
                .then((response) => {
                    console.log(response.data.orders)
                    return response.data.orders
                })
                .catch((error) => {
                    console.log(error)
                    return []
                })
        }
        if (orders.length > 0) {
                    if (props.user?.admin === null) {
                        orders = orders.filter((value) => {
                            return !(value.mode === OrderConstants.MODE_CONSEGNA && value.platform === OrderConstants.NO_PLATFORM && value.payment === 'Contanti')
                        })
                    }

                    // ordino per orario
                    orders.sort((a, b) => {
                        if (prettyHour(a.hour) < prettyHour(b.hour)) {
                            return -1
                        }
                        if (prettyHour(a.hour) > prettyHour(b.hour)) {
                            return 1
                        }
                        return 0
                    })
                    setOrders(orders)
                    //console.log(orders)

                    let fatt = 0.0
                    let items = 0
                    let newC = 0
                    let returnedC = 0
                    let daPagare = 0.0
                    let pagati = 0.0
                    let bancomat = 0.0
                    let platforms = {}
                    let dict = {}
                    let toDeliver = []
                    let sc = 0
                    let cons = 0
                    let mod_cons = 0
                    let mod_rit = 0
                    for (let i in orders) {
                        // calcolo il fatturato giornaliero
                        fatt += orders[i].total

                        // calcolo il numero piatti (contando solo le pizze)
                        for (let j in orders[i].items) {
                            if (orders[i].items[j]?.category === ItemCategories.PIZZE) {
                                items += orders[i].items[j].quantity
                            }
                        }

                        // calcolo clienti nuovi e di ritorno
                        if (orders[i].reg?.insertDate) {
                            const insD = moment(orders[i].reg.insertDate).format('YYYY-MM-DD')
                            if (insD === date) {
                                newC += 1
                            } else {
                                returnedC += 1
                            }
                        } else {
                            returnedC += 1  // se non c'è il campo insertDate assumo che il cliente non sia nuovo
                        }

                        // dettaglio pagamenti
                        if (orders[i].payment === "Contanti") {
                            daPagare += orders[i].total
                        }
                        if (orders[i].payment === "Pagato" && orders[i].platform !== OrderConstants.NO_PLATFORM) {
                            pagati += orders[i].total
                        }
                        if (orders[i].payment === "Bancomat") {
                            bancomat += orders[i].total
                        }

                        // dettaglio piattaforme
                        if (platforms[orders[i].platform]) {
                            platforms[orders[i].platform].total += orders[i].total
                            if (orders[i].payment === "Pagato") {
                                platforms[orders[i].platform].pagati += orders[i].total
                            }
                        } else {
                            platforms[orders[i].platform] = { total: orders[i].total, pagati: 0.0 }
                            if (orders[i].payment === "Pagato") {
                                platforms[orders[i].platform].pagati = orders[i].total
                            }
                        }

                        // dettaglio pagamenti gestiti dai vari driver
                        if (orders[i].driver && orders[i].mode === OrderConstants.MODE_CONSEGNA && orders[i].payment !== "Pagato" && orders[i].payment !== "Bancomat") {
                            const driverName = orders[i].driver.name
                            if (dict[driverName]) {
                                dict[driverName] += orders[i].total
                            } else {
                                dict[driverName] = orders[i].total
                            }
                        }
                        if (orders[i].mode === OrderConstants.MODE_RITIRO && orders[i].payment !== "Pagato" && orders[i].payment !== "Bancomat") {
                            if (dict["Dai ritiri"]) {
                                dict["Dai ritiri"] += orders[i].total
                            } else {
                                dict["Dai ritiri"] = orders[i].total
                            }
                        }

                        // salvo gli ordini da consegnare
                        if (orders[i].mode === OrderConstants.MODE_CONSEGNA && !orders[i].driver) {
                            toDeliver.push(orders[i])
                        }

                        // calcolo il "punteggio" della giornata
                        if (orders[i].mode === OrderConstants.MODE_CONSEGNA) {
                            if (orders[i].driver?.completed) {
                                cons += 10  // 10 punti raggiungibili per ogni ordine consegnato
                                sc += calculateScore(orders[i].hour, orders[i].driver.completed)  // 10, 6 o 1 punti assegnati alla consegna in base al ritardo
                            }

                            mod_cons++
                        } else {
                            mod_rit++
                        }
                    }
                    setFatturato(fatt)
                    setPiatti(items)
                    setNewClients(newC)
                    setReturnedClients(returnedC)
                    setDaPagare(daPagare)
                    setPagati(pagati)
                    setBancomat(bancomat)
                    setPlatforms(platforms)
                    setDriversPayments(dict)
                    setToDeliver(toDeliver)
                    setScore(sc / cons * 100)
                    setModality({cons: mod_cons, rit: mod_rit})
        } else {
            setOrders([])
            setFatturato(0.0)
            setPiatti(0)
            setNewClients(0)
            setReturnedClients(0)
            setDaPagare(0.0)
            setPagati(0.0)
            setBancomat(0.0)
            setPlatforms({})
            setDriversPayments({})
            setToDeliver([])
            setScore(0)
            setModality({cons: 0, rit: 0})
        }
    }
    const renderPlatformsData = () => {
        let render = []
        for(let key in platforms) {
            if (key !== OrderConstants.NO_PLATFORM) {
                render.push(
                    <span key={key}>
                        <span>
                            <b>{key}:</b> € {platforms[key].total.toFixed(2)} {platforms[key].pagati ? 
                            <>(di cui € {platforms[key].pagati.toFixed(2)} già pagati)</> : <></>}
                        </span><br />
                    </span>
                )
            }
        }
        return render
    }
    const renderDriversPayments = () => {
        let render = []
        for(let key in driversPayments) {
            render.push(
                <span key={key}>
                    <span><b>{key}:</b> € {driversPayments[key].toFixed(2)}</span><br />
                </span>
            )
        }
        return render
    }

    useEffect(() => {
        retrieveOrders().then(() => {}, (err) => console.log(err))

        if (date === moment().format('YYYY-MM-DD')) {
            const interval = setInterval(() => {
                retrieveOrders(props.orders.current)
            }, 15 * 1000)

            return () => clearInterval(interval)
        }
    }, [date, triggerOrdersUpdate])

    useEffect(() => {
        if (props.appOrdersConfirmed) {
            // retrieveOrders().then(() => {}, (err) => console.log(err))
            setTriggerOrdersUpdate(Date.now())
        }
    }, [props.appOrdersConfirmed])

    const onChangeDate = (e) => {
        const date = e.target.value
        if (date) {
            const newDate = moment(date).format('YYYY-MM-DD')
            setDate(newDate)
        }
    }
    const handleIncrease = () => {
        if (date) {
            const newDate = moment(date).add(1, 'd').format('YYYY-MM-DD')
            setDate(newDate)
        }
    }
    const handleDecrease = () => {
        if (date) {
            const newDate = moment(date).subtract(1, 'd').format('YYYY-MM-DD')
            setDate(newDate)
        }
    }
    const dateToday = () => {
        if (date !== moment().format('YYYY-MM-DD')) {
            setDate(moment().format('YYYY-MM-DD'))
        } else {
            // retrieveOrders().then(() => {}, (err) => console.log(err))
            setTriggerOrdersUpdate(Date.now())
        }
    }

    const advance = (hour, driver) => {
        if (driver && driver.completed) {
            const d1 = new Date(driver.completed)
            const d2 = new Date(hour)
            var diff = d1 - d2
            var minutes = Math.round(diff / 60000)
            if (minutes <= 5) {
                return true
            }
        }
        return false
    }
    const inTime = (hour, driver) => {
        if (driver && driver.completed) {
            const d1 = new Date(driver.completed)
            const d2 = new Date(hour)
            var diff = d1 - d2
            var minutes = Math.round(diff / 60000)
            if (minutes > 5 && minutes < 15) {
                return true
            }
        }
        return false
    }
    const delay = (hour, driver) => {
        if (driver && driver.completed) {
            const d1 = new Date(driver.completed)
            const d2 = new Date(hour)
            var diff = d1 - d2
            var minutes = Math.round(diff / 60000)
            if (minutes >= 15) {
                return true
            }
        }
        return false
    }

    // MODIFICA, CANCELLA O RISTAMPA ORDINE
    const [currentOrder, setCurrentOrder] = useState(null)

    const orderDelete = (id) => {
        if (props.user.username) {
            Services.orderDelete(id, props.user.username)
                .then((response) => {
                    // retrieveOrders().then(() => console.log("ordine cancellato"), (err) => console.log(err))
                    setTriggerOrdersUpdate(Date.now())
                })
                .catch((error) => {
                    console.log(error.response?.data?.error)
                })
        }
    }

    const orderRetired = (id) => {
        if (props.user.username) {
            Services.orderRetired(id, props.user.username)
                .then((response) => {
                    // retrieveOrders().then(() => console.log("ordine ritirato"), (err) => console.log(err))
                    setTriggerOrdersUpdate(Date.now())
                })
                .catch((error) => {
                    console.log(error.response?.data?.error)
                })
        }
    }

    async function printOrder(order) {
        await props.print(order).then(() => {
            console.log("Stampato")
        }, (err) => {
            console.log(err)
        })
    }

    // TOOLTIPS E POPOVER
    useEffect(() => {
        window.jQuery('[data-toggle="tooltip"]').tooltip()
        window.jQuery('[data-toggle="popover"]').popover()
        /* window.jQuery('.popover-dismiss').popover({
            trigger: 'focus'
        }) */
    }, [])

    return (
        <div className="container-fluid">

            {/* <!-- Page Heading --> */}
            <div className="form-group row justify-content-center">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-2 d-none d-xl-inline d-lg-inline d-md-inline d-sm-inline"></div>

                {props.user?.admin ? (
                    <div className="input-group col-xl-4 col-lg-4 col-md-4 col-sm-8 col-12 mb-lg-0 mb-3 d-flex justify-content-center">
                        <div className="input-group-prepend">
                            <button type="button" className="btn btn-primary btn-circle" onClick={handleDecrease}>
                                <i className="fas fa-arrow-left"></i>
                            </button>
                        </div>
                        <input
                            id="data"
                            type="date"
                            className="form-control form-control-user"
                            placeholder="Inserisci la data"
                            value={date}
                            onChange={onChangeDate}
                        ></input>
                        <div className="input-group-append">
                            <button type="button" className="btn btn-primary btn-circle" onClick={handleIncrease}>
                                <i className="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="input-group col-xl-4 col-lg-4 col-md-4 col-sm-8 col-12 mb-lg-0 mb-3 d-flex justify-content-center">
                        <input
                            id="data"
                            type="date"
                            className="form-control form-control-user"
                            placeholder="Data di oggi"
                            value={date}
                            disabled
                        ></input>
                    </div>
                )}

                <div className="col-md-4 col-sm-2 d-xl-none d-lg-none d-md-inline d-sm-inline"></div>

                {props.user?.admin ? (
                    <div className="col-xl-2 col-lg-2 col-6 d-flex justify-content-center">
                        {/* <!-- BOTTONE SET DATA OGGI --> */}
                        <button type="button" className="btn btn-primary" onClick={dateToday}>
                            <i className="fas fa-calendar-day"></i>
                            <span className="ml-2 d-none d-sm-inline">Oggi</span>
                        </button>
                    </div>
                ) : (
                    <div className="col-xl-2 col-lg-2 col-6 d-flex justify-content-center"></div>
                )}

                <div className="col-xl-2 col-lg-2 col-6 d-flex justify-content-center">
                    {/* <!-- BOTTONE NUOVO ORDINE --> */}
                    <button type="button" className="btn btn-success" onClick={() => props.setOrderData(null)}>
                        <i className="fa fa-plus"></i>
                        <span className="ml-2 d-none d-sm-inline">Ordine</span>
                    </button>
                </div>
            </div>

            {/* <!-- RIEPILOGO GIORNALIERO --> */}
            {props.user?.admin ? (
                <div className="row">
                    <div className="col-xl-2 col-md-4 col-sm-6 mb-2">
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Fatturato
                                        </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">€ {fatturato.toFixed(2)}</div>
                                    </div>
                                    <div className="col-auto">
                                        <button
                                            className="btn btn-link rounded-circle btn-sm"
                                            type="button"
                                            data-toggle="modal"
                                            data-target="#viewSpecificheFatturato"
                                            title="Specifiche"
                                        >
                                            <i className="fa fa-list-ul" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-2 col-md-4 col-sm-6 mb-2">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Consegne
                                        </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{modality.cons}</div>
                                        <div className="progress mb-2">
                                            <div
                                                className={"progress-bar bg" + ((score <= 10) ? "-danger" : ((score <= 60) ? "-warning" : "-success"))}
                                                role="progressbar"
                                                style={{ width: score ? `${score.toFixed(0)}%` : "0%" }}
                                            >
                                                {score ? `${score.toFixed(0)}%` : "0%"}
                                            </div>
                                        </div>
                                        <span
                                            className="form-text text-muted text-xs"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="La barra mostra l'andamento (percentuale) delle consegne: 10/10 punti (in orario), 6/10 punti (5-15 minuti di ritardo), 1/10 punti (>15 minuti di ritardo)"
                                            style={{ textDecoration: "underline 1px dotted", textAlign: "center" }}
                                        >
                                            Punteggio
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-2 col-md-4 col-sm-6 mb-2">
                        <div className="card border-left-danger shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                                            Ritiri
                                        </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{modality.rit}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-2 col-md-4 col-sm-6 mb-2">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Piatti
                                        </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{piatti}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-2 col-md-4 col-sm-6 mb-2">
                        <div className="card border-left-info shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                                            Clienti di ritorno
                                        </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{returnedClients}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-2 col-md-4 col-sm-6 mb-2">
                        <div className="card border-left-warning shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                            Clienti nuovi
                                        </div>
                                        <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{newClients}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <div className="col-xl-3 col-md-6 mb-2">
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Fatturato
                                        </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">€ {fatturato.toFixed(2)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-2">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Consegne
                                        </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{modality.cons}</div>
                                        <div className="progress mb-2">
                                            <div
                                                className={"progress-bar bg" + ((score <= 10) ? "-danger" : ((score <= 60) ? "-warning" : "-success"))}
                                                role="progressbar"
                                                style={{ width: score ? `${score.toFixed(0)}%` : "0%" }}
                                            >
                                                {score ? `${score.toFixed(0)}%` : "0%"}
                                            </div>
                                        </div>
                                        <span
                                            className="form-text text-muted text-xs"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                            title="La barra mostra l'andamento (percentuale) delle consegne: 10/10 punti (in orario), 6/10 punti (5-15 minuti di ritardo), 1/10 punti (>15 minuti di ritardo)"
                                            style={{ textDecoration: "underline 1px dotted", textAlign: "center" }}
                                        >
                                            Punteggio
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-2">
                        <div className="card border-left-danger shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                                            Ritiri
                                        </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{modality.rit}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-2">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Piatti
                                        </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{piatti}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* <!-- TABELLA ORDINI --> */}
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Lista Ordini</h6>
                </div>
                <div className="card-body text-dark">
                    {toDeliver.length > 0 ? (
                        <div className="alert alert-danger" role="alert">
                            <strong>Attenzione!</strong> Ci sono ancora {toDeliver.length} ordini da consegnare.
                            <button
                                className="close"
                                type="button"
                                data-toggle="modal"
                                data-target="#toDeliverModal"
                                title="Dettagli"
                            >
                                <i className="fas fa-list-ul"></i>
                            </button>
                        </div>
                    ) : (
                        <div></div>
                    )}

                    <div className="table-responsive">
                        <table className="table text-dark" id="dataTable" width="100%">
                            <thead>
                                <tr>
                                    <th>
                                        <button
                                            tabIndex="0"
                                            className="btn btn-sm btn-link rounded-circle"
                                            type="button"
                                            data-toggle="popover"
                                            data-trigger="focus"
                                            data-html="true"
                                            data-content="
                                                <b>Pallino <span class='text-success'>verde</span>:</b> consegnato in anticipo o in ritardo di massimo 5 minuti.<br/>
                                                <b>Pallino <span class='text-warning'>giallo</span>:</b> consegnato in ritardo di più di 5 ma meno di 15 minuti.<br/>
                                                <b>Pallino <span class='text-danger'>rosso</span>:</b> consegnato in ritardo di 15 minuti o più.<br/>
                                                <b>Pallino <span class='text-primary'>blu</span>:</b> consegna partita.<br/>
                                                <b>Pallino <span class='text-dark'>nero</span>:</b> ordine da consegnare.<br/>
                                                <b>Pallino <span class='text-success'>spuntato</span>:</b> ordine segnato come ritirato.<br/>
                                                <b>Pallino <span class='text-dark'>vuoto</span>:</b> ordine da ritirare.
                                            "
                                        >
                                            <i className="fas fa-question-circle" title="Legenda"></i>
                                        </button>
                                    </th>
                                    <th>ID</th>
                                    <th>Tipologia</th>
                                    <th>Orario</th>
                                    <th>Nome</th>
                                    <th>Importo</th>
                                    <th>Pagamento</th>
                                    <th style={{ width: "20%" }}>Azioni</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((o, index) => {
                                    return (
                                        <tr key={`ordine${index}_${date}`}>
                                            <td>
                                                {o.mode === OrderConstants.MODE_CONSEGNA ? <span className={o.driver ? "text-primary" : ""}>
                                                    <span className={advance(o.hour, o.driver) ? "text-success" : ""}>
                                                        <span className={inTime(o.hour, o.driver) ? "text-warning" : ""}>
                                                            <span className={delay(o.hour, o.driver) ? "text-danger" : ""}>
                                                                <i className="fa fa-circle" aria-hidden="true"></i>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span> : <span
                                                    className={o.status === "Ritirato" ? "text-success" : ""}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => orderRetired(o['_id'])}
                                                >
                                                    <i className={o.status === "Ritirato" ? "far fa-circle-check" : "far fa-circle"} aria-hidden="true"></i>
                                                </span>}
                                            </td>
                                            <td>#{o.seq_value}</td>
                                            <td>{o.mode}</td>
                                            <td>{prettyHour(o.hour)}</td>
                                            <td>{o.reg.name} {o.platform !== OrderConstants.NO_PLATFORM ? <span className="badge rounded-pill bg-info text-white">{o.platform}</span> : ""}</td>
                                            <td>€ {o.total.toFixed(2)}</td>
                                            <td>{o.payment}</td>
                                            <td>
                                                <button
                                                    className="btn btn-success btn-icon"
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#viewOrderModal"
                                                    orderindex={index}
                                                    onClick={(e) => setCurrentOrder(orders[e.currentTarget.getAttribute('orderindex')])}
                                                    title="Visualizza"
                                                >
                                                    <i className="fas fa-list-ul"></i>
                                                </button>
                                                &nbsp;
                                                <button
                                                    className="btn btn-warning btn-icon"
                                                    type="button"
                                                    orderindex={index}
                                                    onClick={(e) => props.setOrderData(orders[e.currentTarget.getAttribute('orderindex')])}
                                                    title="Modifica"
                                                >
                                                    <i className="fas fa-edit"></i>
                                                </button>
                                                &nbsp;
                                                <button
                                                    className="btn btn-primary btn-icon"
                                                    type="button"
                                                    orderindex={index}
                                                    onClick={(e) => printOrder(orders[e.currentTarget.getAttribute('orderindex')])}
                                                    title="Stampa comanda"
                                                >
                                                    <i className="fas fa-print"></i>
                                                </button>
                                                &nbsp;
                                                <button
                                                    className="btn btn-danger btn-icon"
                                                    type="button"
                                                    data-toggle="modal"
                                                    data-target="#modalCancellaRecord"
                                                    data-backdrop="static"  //non si può uscire cliccando fuori
                                                    data-keyboard="false"   //non si può uscire premendo Esc
                                                    orderindex={index}
                                                    onClick={(e) => setCurrentOrder(orders[e.currentTarget.getAttribute('orderindex')])}
                                                    title="Cancella"
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* <!-- Modale per visualizzazione ordine --> */}
            <div className="modal fade" id="viewOrderModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ordine #{currentOrder?.seq_value}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setCurrentOrder(null)}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <SummaryContainer currentOrder={currentOrder} user={props.user}></SummaryContainer>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setCurrentOrder(null)}>Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modale per info fatturato giornaliero --> */}
            <div className="modal fade" id="viewSpecificheFatturato" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Specifiche pagamenti giornata</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <p>
                                <span><b>Cassa:</b> € {daPagare.toFixed(2)}</span><br />
                                <span><b>Già pagati (dalle piattaforme):</b> € {pagati.toFixed(2)}</span><br />
                                <span><b>Bancomat:</b> € {bancomat.toFixed(2)}</span>
                            </p>
                            <hr />
                            <h5><b><i>Dettaglio piattaforme</i></b></h5>
                            <p>{renderPlatformsData()}</p>
                            <hr />
                            <h5><b><i>Pagamenti incassati</i></b></h5>
                            <p>{renderDriversPayments()}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Chiudi</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Modal dettaglio ordini da consegnare --> */}
            <div className="modal fade" id="toDeliverModal" tabIndex="-1" role="dialog" aria-labelledby="toDeliverModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="toDeliverModalLabel">Ordini da consegnare</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <div className="table-responsive">
                                <table className="table text-dark" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "10%" }}>ID</th>
                                            <th style={{ width: "10%" }}>Orario</th>
                                            <th style={{ width: "45%" }}>Indirizzo</th>
                                            <th style={{ width: "10%" }}>Piattaforma</th>
                                            <th style={{ width: "15%" }}>Importo</th>
                                            <th style={{ width: "10%" }}>Pagamento</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {toDeliver.map((o, index) => {
                                            return (
                                                <tr key={`tabella-ordini-${index}`}>
                                                    <td>#{o.seq_value}</td>
                                                    <td>{prettyHour(o.hour)}</td>
                                                    <td>{o.reg.address}</td>
                                                    <td>{o.platform}</td>
                                                    <td>€ {o.total.toFixed(2)}</td>
                                                    <td>{o.payment}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Chiudi
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- INIZIO MODAL CANCELLA RECORD --> */}
            <div className="modal fade" id="modalCancellaRecord" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Cancella record</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setCurrentOrder(null)}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body container-fluid text-dark">
                            <p>Sei sicuro di voler cancellare il record selezionato?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => setCurrentOrder(null)}
                            >
                                Annulla
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => { orderDelete(currentOrder['_id']); setCurrentOrder(null) }}
                            >
                                Cancella
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- FINE MODAL CANCELLA RECORD --> */}
        </div>
    )
}

export default Orders
