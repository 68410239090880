import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'

import Services from '../services'


const Login = (props) => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    //const [checked, setChecked] = useState(false)

    const [show, setShow] = useState(false)
    const [body, setBody] = useState("")
    const [hidePassword, setHidePassword] = useState(true)

    const editFieldRef = useRef(null)

    useEffect(() => {
        editFieldRef.current.focus()
    }, [])

    const onChangeUsername = (e) => {
        const username = e.target.value
        setUsername(username)
    }

    const onChangePassword = (e) => {
        const password = e.target.value
        setPassword(password)
    }

    /* const handleChange = () => {
        setChecked(!checked)
    } */

    const handleClose = () => {
        setUsername("")
        setPassword("")
        setShow(false)
        editFieldRef.current.focus()
    }

    const handleShow = (b) => {
        setBody(b)
        setShow(true)
        editFieldRef.current.focus()
    }

    const handleHidePassword = () => {
        setHidePassword(!hidePassword)
    }

    const login = () => {
        Services.login(username, password)
                .then((response) => {
                    props.login(response.data.user, response.data.access_token, response.data.refresh_token)
                    /* if (!checked) {
                        Services.updateRole(username).then((response) => {
                            props.login(response.data.user)
                        })
                        .catch((error) => {
                            console.log(error.response?.data?.error)
                        })
                    } */
                })
                .catch((error) => {
                    handleShow(error.response?.data?.error)
                })
    }

    return(
        <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
                <div className="row">
                    {/* <div className="col-lg-6 d-none d-lg-block bg-login-image"></div> */}
                    <div className="col-lg-6">
                        <div className="p-5">
                            <div className="text-center">
                                <h1 className="h4 text-gray-900 mb-4">Bentornato in Delivery Pro!</h1>
                            </div>
                            {show ? (
                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                    <strong>Attenzione!</strong> {body}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={handleClose}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            ) : (
                                <div></div>
                            )}
                            <form>
                                <div className="form-group">
                                    <label htmlFor="username">Inserisci il tuo username:</label>
                                    <input
                                        id="username"
                                        type="text"
                                        className="form-control form-control-user"
                                        placeholder="Username"
                                        value={username}
                                        onChange={onChangeUsername}
                                        ref={editFieldRef}
                                        aria-required="true"
                                    ></input>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Inserisci la tua password:</label>
                                    <div className="input-group">
                                        <input
                                            id="password"
                                            type={hidePassword ? "password" : "text"}
                                            className="form-control form-control-user border-right-0"
                                            placeholder="Password"
                                            value={password}
                                            onChange={onChangePassword}
                                            aria-required="true"
                                            onKeyDown={(event) => {
                                                if(event.key === "Enter") {
                                                    login()
                                                }
                                            }}
                                        ></input>
                                        <span className="input-group-append">
                                            <div className="input-group-text bg-transparent">
                                                <i
                                                    className={hidePassword ? "fas fa-eye" : "fas fa-eye-slash"}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={handleHidePassword}
                                                ></i>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                {/* <div className="form-group">
                                    <div className="custom-control custom-checkbox small">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="customCheck"
                                            checked={checked}
                                            onChange={handleChange}
                                        >
                                        </input>
                                        <label className="custom-control-label" htmlFor="customCheck">
                                            Resta connesso
                                        </label>
                                    </div>
                                </div> */}
                                <button className="btn btn-primary btn-user btn-block" type="button" onClick={login}>
                                    Login
                                </button>
                            </form>
                            <hr/>
                            <div className="text-center">
                                <a className="small" href="mailto:deliverypro.cloud@gmail.com?subject=Richiesta%20recupero%20password&body=inserire%20tutte%20le%20informazioni%20utili%20alla%20vostra%20identificazione">
                                    Password dimenticata?
                                </a>
                            </div>
                            <div className="text-center">
                                <Link to={"/register"} className="small">
                                    <span>Registrati!</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
